import { React, styled } from "~lib";
import { CTA } from "~lib/Clickable";
import Environment from "~lib/Environment";
import deadBrowser from "~lib/Images/dead-browser-white.svg";

import { CenterBox } from "..";

function interestingErrorName(error: Error): string | undefined {
  const candidates = [error.name, error.constructor.name];
  return candidates.find(
    name => name.length > 0 && name !== "Error" && name !== "TypeError"
  );
}

function title(error: Error): string | undefined {
  const name = interestingErrorName(error);
  const message = error.message;
  if (name && message) return `${name}: ${message}`;
  return message || name || undefined;
}

const Stack = styled("div")({
  overflow: "auto",
  textAlign: "left",
  whiteSpace: "pre"
});

type Props = {
  error: Error;
};

export default function InternalError(props: Props) {
  const { error } = props;
  return (
    <React.Fragment>
      <Environment show="development">
        <CenterBox
          css={{
            textAlign: "center"
          }}
          width="100%"
        >
          <img src={deadBrowser} alt="" width={135} />
          <h1>{title(error)}</h1>
          {error.stack && (
            <Stack>{error.stack.replace(/webpack:[\/.]+/g, "")}</Stack>
          )}
          <div style={{ marginTop: 44 }}>
            <CTA action={() => window.location.reload()}>Reload app</CTA>
          </div>
        </CenterBox>
      </Environment>
      <Environment hide="development">
        <CenterBox
          css={{
            textAlign: "center"
          }}
          width={700}
        >
          <img src={deadBrowser} alt="" width={135} />
          <h1>Whoops!</h1>
          <p>
            Sorry, but it seems something has gone wrong, reload the app and try
            again.
          </p>
          <div style={{ marginTop: 44 }}>
            <CTA action={() => window.location.reload()}>Reload app</CTA>
          </div>
        </CenterBox>
      </Environment>
    </React.Fragment>
  );
}
