import { stringify } from "querystringify";

export function url(
  path: string | string[],
  query?: { [name: string]: string } | null
): string {
  const paths = typeof path === "string" ? [path] : path;
  return [
    "/",
    paths
      .map(piece => piece.replace(/^\/|\/$/, ""))
      .map(encodeURIComponent)
      .join("/"),
    query ? "?" + stringify(query) : ""
  ].join("");
}
