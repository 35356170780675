import { React } from "~lib";
import AmazonConnectPopup from "~lib/AmazonConnectPopup";
import DB from "~lib/DB";
import { MainLayout } from "~lib/Templates";

import DoubleSearch, { overlap } from "./DoubleSearch";
import search, { Result } from "./search";
import SearchResults from "./SearchResults";

type Props = {
  db: DB;
  navigateTo: (path: string) => void;
  productId: string;
  startSearchWith: string;
};

type State = {
  fieldValue: string;
  results: "empty" | "loading" | Result | "failed";
};

export default class SellSearch extends React.Component<Props, State> {
  state: State = {
    fieldValue: "",
    results: "empty"
  };

  componentDidMount() {
    const searchValue = this.initialSearchValue();
    if (searchValue) this.search(searchValue);
  }

  private back = () => {
    this.props.navigateTo("/");
  };

  private initialSearchValue = () => {
    const { db, productId, startSearchWith } = this.props;
    if (startSearchWith) {
      return startSearchWith;
    }

    const products = db.products();
    if (products === "unsynced") return undefined;
    const product = products.find(p => p.id === productId);
    if (product) {
      return product.title;
    }

    return undefined;
  };

  private debounceTimer?: number;
  private onChangeSearchField = (text: string) => {
    this.setState({ fieldValue: text });
    if (this.debounceTimer) window.clearTimeout(this.debounceTimer);
    this.debounceTimer = window.setTimeout(() => this.search(text), 500);
  };

  private search = (text: string) => {
    if (!text.trim()) {
      this.setState({ results: "empty" });
      return;
    }

    const { db } = this.props;
    this.setState({ fieldValue: text });

    const auth = db.amazonAuth();
    if (auth === "unconnected") {
      // Silently return, the popup in render will handle it
      return;
    }
    this.setState({ results: "loading" });
    search(auth, text)
      .then(results => {
        if (text !== this.state.fieldValue) return;
        this.setState({
          results
        });
      })
      .catch(() => {
        if (text !== this.state.fieldValue) return;
        this.setState({
          results: "failed"
        });
      });
  };

  private searchAgain = () => {
    this.search(this.state.fieldValue);
  };

  render() {
    const { db, productId } = this.props;
    const { fieldValue, results } = this.state;
    const products = db.products();
    const volusionProduct =
      products !== "unsynced" && products.find(p => p.id === productId);
    return (
      <MainLayout
        back="/"
        guide={{
          autoshowKey: "SellSearch",
          children: (
            <React.Fragment>
              On Amazon, if the same product is sold by multiple merchants,
              everyone must share the same product listing. What this means is
              that you don’t have to create or manage the product listing –
              Amazon has already done this for you. All you have to do is set a
              price so shoppers buy that product from you instead of the
              competition!
            </React.Fragment>
          ),
          nudgeX: 15,
          title: "Helpful Tip"
        }}
        headerOverlap={overlap}
        hideLogo
        title="Do others already sell this product on Amazon?"
        subtitle={
          <React.Fragment>
            <p>
              Amazon only allows one listing per product which all vendors must
              share. Find your product listing below.
            </p>
          </React.Fragment>
        }
      >
        <AmazonConnectPopup
          db={db}
          needReal={results === "failed"}
          onSuccess={this.searchAgain}
          onCancel={this.back}
        />
        <div>
          {volusionProduct && (
            <DoubleSearch
              onChangeSearchField={this.onChangeSearchField}
              value={fieldValue}
              volusionProduct={volusionProduct}
            />
          )}
          <SearchResults
            onSearchAgain={this.searchAgain}
            productId={productId}
            results={results}
            searchFieldValue={fieldValue}
          />
        </div>
      </MainLayout>
    );
  }
}
