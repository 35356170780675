import { React, styled } from "~lib";
import magnifyingGlass from "~lib/Images/magnifying-glass.svg";

import Cross, { fieldPaddingRight } from "./Cross";
import Input from "./Input";

const Container = styled("div")({
  alignItems: "center",
  display: "flex",
  flex: 1,
  fontSize: 18,
  position: "relative"
});

const MagnifyingGlass = styled("img")({
  marginLeft: 24,
  marginTop: 1,
  position: "absolute",
  width: 24
});

type Props = {
  height?: 60 | 110;
  onChange: (newValue: string) => void;
  placeholder?: string;
  value: string;
};

export default function Search(props: Props) {
  const { onChange, placeholder, value } = props;
  const height = props.height || 60;
  return (
    <Container>
      <MagnifyingGlass src={magnifyingGlass} alt="Magnifying glass" />
      <Input
        height={height}
        onChange={onChange}
        padding={`0 ${fieldPaddingRight(height)}px 0 64px`}
        placeholder={placeholder}
        value={value}
      />
      {value.trim() && (
        <Cross
          action={() => {
            onChange("");
          }}
          fieldHeight={height}
        />
      )}
    </Container>
  );
}
