import { parse, stringify } from "querystringify";

type Params = {
  current: {
    pathname: string;
    search: string;
  };
  target: string;
};

export default function addGlobalUrlState({ current, target }: Params) {
  const query = parse(current.search);
  const { tenant, token } = query;
  if (tenant && token) {
    target += target.includes("?") ? "&" : "?";
    target += stringify({ tenant, token });
  }
  return target;
}
