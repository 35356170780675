import Money, { fromPointString } from "~root-lib/Money";

type Result = {
  asin: string;
  price: Money;
  quantity: number;
  sku: string | undefined;
  volusionId: string;
}[];

export const priceFieldAttribute = "data-price";
export const quantityFieldAttribute = "data-quantity";
export const skuFieldAttribute = "data-sku";

function getFieldByAttribute(
  productCard: HTMLElement,
  attr: string
): HTMLInputElement {
  const input = productCard.querySelector(`input[${attr}], [${attr}] input`);
  if (!input) throw new Error("Broken DOM, can not find the field");
  if (!(input instanceof HTMLInputElement))
    throw new Error("Broken DOM, the element is not an input");
  return input;
}

export const getPriceField = (productCard: HTMLElement) =>
  getFieldByAttribute(productCard, priceFieldAttribute);
export const getQuantityField = (productCard: HTMLElement) =>
  getFieldByAttribute(productCard, quantityFieldAttribute);
export const getSkuField = (productCard: HTMLElement) =>
  getFieldByAttribute(productCard, skuFieldAttribute);

export default function collectFields(form: HTMLFormElement): Result {
  const result: Result = [];
  form.querySelectorAll("[data-amazon-target]").forEach(amazonTarget => {
    if (!(amazonTarget instanceof HTMLElement)) return;
    const asin = amazonTarget.dataset.amazonTarget;
    if (!asin) throw new Error("Broken amazon variant asin");

    const volusionElement = amazonTarget.querySelector(
      "[data-volusion-variant]"
    );
    if (!(volusionElement instanceof HTMLElement)) return;

    const volusionId = volusionElement.dataset.volusionVariant;
    if (!volusionId) throw new Error("Broken volusion variant id");

    result.push({
      asin,
      price: fromPointString(getPriceField(volusionElement).value),
      quantity: Number(getQuantityField(volusionElement).value),
      sku: getSkuField(volusionElement).value,
      volusionId
    });
  });
  return result;
}
