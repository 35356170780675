import { React, styled, styles } from "~lib";

import Svg, { Props as SvgProps } from "~lib/Svg";

import Card from "~lib/Cards";
import BaseClickable from "~lib/Clickable";
import Select from "~lib/Form/Select";

import chevronLeft from "./chevron-left.svg?inline";
import chevronRight from "./chevron-right.svg?inline";

type Props = {
  begin: number;
  currentPage: number;
  end: number;
  onChange: (
    change: {
      page: number;
      pageSize: number;
    }
  ) => void;
  pageCount: number;
  pageSize: number;
  pageSizes?: number[];
  totalCount: number;
};

export default function PaginationCard(props: Props) {
  const pageSizes = props.pageSizes || [10, 25, 50];
  return (
    <Card css={cardStyle} width="100%">
      <Title>items per page</Title>{" "}
      <Selector
        onChange={n => {
          const pageSize = Number(n);
          const newPageCount = Math.ceil(props.totalCount / pageSize);
          props.onChange({
            page: Math.min(props.currentPage, newPageCount),
            pageSize
          });
        }}
        value={props.pageSize}
        pageSizes={pageSizes}
      />{" "}
      <span style={{ marginRight: 45, whiteSpace: "nowrap" }}>
        {props.begin + 1}
        {"\u200a\u2013\u200a"}
        {props.end} of {props.totalCount}
      </span>
      <GoToPage
        dangerousSvgContent={chevronLeft}
        enabled={props.currentPage > 1}
        onGoToPageClick={n => {
          props.onChange({
            page: n,
            pageSize: props.pageSize
          });
        }}
        page={props.currentPage - 1}
        width={8}
      />
      <span style={{ marginLeft: 30 }} />
      <GoToPage
        dangerousSvgContent={chevronRight}
        enabled={props.currentPage < props.pageCount}
        onGoToPageClick={n => {
          props.onChange({
            page: n,
            pageSize: props.pageSize
          });
        }}
        page={props.currentPage + 1}
        width={8}
      />
    </Card>
  );
}

const cardStyle = styles({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  fontSize: 12,
  fontWeight: 400,
  height: 60,
  padding: 20,
  justifyContent: "flex-end"
});

const selectStyle = styles({
  fontSize: 12,
  marginLeft: 10,
  marginRight: 45
});

type SelectorProps = {
  value: number;
  pageSizes: number[];
  onChange?: (newValue: string) => void;
};

function Selector(props: SelectorProps) {
  return (
    <Select
      className={selectStyle}
      value={String(props.value)}
      onChange={props.onChange}
    >
      {props.pageSizes.map(ps => (
        <option key={ps} value={ps}>
          {ps}
        </option>
      ))}
    </Select>
  );
}

type GoToPageProps = SvgProps & {
  page: number;
  enabled: boolean;
  onGoToPageClick?: (newPage: number) => void;
};

function GoToPage(props: GoToPageProps) {
  const extraProps: GoToPageProps = {
    ...props,
    opacity: 1
  };
  if (!props.enabled) {
    extraProps.opacity = 0.4;
  }

  delete extraProps.page;
  delete extraProps.enabled;
  delete extraProps.onGoToPageClick;

  const svg = <Svg {...extraProps} />;

  return props.enabled ? (
    <BaseClickable
      action={
        props.onGoToPageClick
          ? () => props.onGoToPageClick!(props.page)
          : `page=${props.page}`
      }
      className={styles({
        ...extraProps,
        lineHeight: 0,
        margin: -10,
        padding: 10
      })}
    >
      {svg}
    </BaseClickable>
  ) : (
    svg
  );
}

const Title = styled("span")({
  fontSize: 14,
  marginTop: -3
});
