import { React, styled } from "~lib";

type Props = {
  children: React.ReactNode;
  color: string;
  size?:
    | "small"
    | "normal"
    | {
        height: number;
        padding: number | string;
      };
};

const sizes = {
  small: {
    height: 22,
    padding: "0 11px"
  },
  normal: {
    height: 22,
    padding: "0 20px"
  }
};

export default class PillBadge extends React.PureComponent<Props> {
  render() {
    const { children, color, size } = this.props;
    const detailedSize =
      typeof size === "object" ? size : sizes[size || "normal"];
    return (
      <Badge
        style={{
          backgroundColor: color,
          height: detailedSize.height,
          padding: detailedSize.padding
        }}
      >
        {children}
      </Badge>
    );
  }
}

const Badge = styled("div")({
  alignItems: "center",
  borderRadius: 78,
  color: "white",
  display: "inline-flex",
  justifyContent: "center",
  letterSpacing: "0.08em",
  textTransform: "uppercase",
  verticalAlign: "middle"
});
