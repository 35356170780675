import { React, styled, styles } from "~lib";

import { Input, MoneyInput } from "~lib/Form";
import Money from "~root-lib/Money";

import { priceFieldAttribute, quantityFieldAttribute } from "./collectFields";

const Label = styled("div")({
  fontSize: 12,
  color: "#666666",
  display: "inline-block",
  marginRight: 3
});

const moneyInputStyles = styles({
  fontSize: 12,
  width: 55
});

import { compactView } from "./styles";

type Props = {
  price: Money;
  quantity?: number;
};

const Wrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  [compactView]: {
    flexDirection: "column",
    justifyContent: "space-evenly"
  }
});

export default function QuantityAndPrice(props: Props) {
  const { price, quantity } = props;
  return (
    <Wrapper>
      <div
        style={{ alignItems: "center", display: "flex", marginRight: 3 }}
        {...{ [priceFieldAttribute]: true }}
      >
        <MoneyInput className={moneyInputStyles} defaultValue={price} />
      </div>
      <label
        style={{ alignItems: "center", display: "flex" }}
        {...{ [quantityFieldAttribute]: true }}
      >
        <Label>Qty:</Label>
        <Input
          defaultValue={quantity}
          min={0}
          placeholder="0"
          type="number"
          width={35}
        />
      </label>
    </Wrapper>
  );
}
