import { React, styled } from "~lib";

type Props = {
  children: React.ReactElement<any>[];
  className?: string;
  name?: string;
  onChange?: (newValue: string) => void;
  value?: string;
};

export default class Select extends React.PureComponent<Props> {
  private change = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (this.props.onChange) this.props.onChange(e.target.value);
  };

  render() {
    const { children, className, name, value } = this.props;
    if (children.some(ch => !ch || (ch as any).type !== "option"))
      throw new Error("Only <option> elements should be children of Select");
    return (
      <Container className={className}>
        <SelectEl name={name} onChange={this.change} value={value}>
          {children}
        </SelectEl>
      </Container>
    );
  }
}

const arrowOverlap = 15;

const Container = styled("div")({
  border: 0,
  padding: 0,
  boxSizing: "border-box",
  display: "flex",
  "&::after": {
    content: "'▾'",
    marginLeft: -arrowOverlap,
    pointerEvents: "none"
  }
});

const SelectEl = styled("select")({
  appearance: "none",
  background: "none",
  border: "none",
  borderRadius: 0,
  boxSizing: "border-box",
  font: "inherit",
  margin: 0,
  minWidth: 0,
  paddingLeft: 5,
  paddingRight: 5 + arrowOverlap
});
