const prefix = `baseclickable`;

export const base = `${prefix}-base`;
export const clickable = `${base} ${prefix}-clickable`;
export const placeholder = `${prefix}-placeholder`;

const selector = (classes: string, pseudoClass?: string) =>
  classes
    .split(" ")
    .map(c => `.${c}${pseudoClass || ""}`)
    .join(",");

const css = `
  ${selector(base)} {
    align-items: normal;
    background-color: transparent;
    border: 0;
    box-sizing: content-box;
    color: inherit;
    display: inline-block;
    font: inherit;
    padding: 0;
    margin: 0;
    text-align: inherit;
    text-decoration: none;
    user-select: none;
  }

  ${selector(clickable)} {
    cursor: pointer;
  }

  ${selector(placeholder)} {
    cursor: not-allowed;
  }
  ${selector(placeholder, ":active")} {
    outline: 2px solid hsl(0, 100%, 40%);
    outline-offset: 8;
  }
`;

const s = document.createElement("style");
s.textContent = css;
s.setAttribute(`data-${prefix}`, "");
if (document.head) document.head.insertBefore(s, document.head.firstChild);
