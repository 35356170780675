import { React, styled } from "~lib";
import { ColorDot, CTA } from "~lib/Clickable";
import Tooltip from "~lib/Tooltip";

import { scrollIntoView } from "./dom";

export { colorDotOuterSize as baseOuterSize } from "~lib/Clickable";

export { zIndex } from "~lib/Tooltip";

const BottomButtons = styled("div")({
  marginTop: 20,
  textAlign: "right"
});

type ActionProps = {
  close: () => void;
  isOpen?: boolean;
  nextIsAvailable?: boolean;
  open: () => void;
  showNext: () => void;
};

export type UIProps = {
  children: React.ReactNode;
  nudgeX?: number;
  nudgeY?: number;
  title?: string;
};

type Props = ActionProps & UIProps;

export default class GuideUI extends React.Component<Props> {
  componentDidMount() {
    this.considerScrolling();
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isOpen) this.considerScrolling();
  }

  private el: HTMLElement | null = null;
  private setRef = (el: HTMLElement | null) => {
    this.el = el;
  };
  private considerScrolling() {
    if (this.props.isOpen && this.el) {
      scrollIntoView(this.el);

      // Workaround for iOS in production iframe, #568
      setTimeout(() => this.el && scrollIntoView(this.el), 10);
      setTimeout(() => this.el && scrollIntoView(this.el), 50);
      setTimeout(() => this.el && scrollIntoView(this.el), 100);
    }
  }

  render() {
    const {
      children,
      close,
      isOpen,
      nextIsAvailable,
      nudgeX,
      nudgeY,
      open,
      showNext,
      title
    } = this.props;

    return (
      <Tooltip
        base={ref => (
          <div ref={ref}>
            <ColorDot
              action={open}
              color="yellow"
              nudgeX={nudgeX}
              nudgeY={nudgeY}
            />
          </div>
        )}
        isOpen={Boolean(isOpen)}
        onClose={close}
        title={title}
      >
        <div ref={this.setRef}>{children}</div>
        <BottomButtons>
          {nextIsAvailable ? (
            <CTA action={showNext}>Next tip</CTA>
          ) : (
            <CTA action={close}>Got it</CTA>
          )}
        </BottomButtons>
      </Tooltip>
    );
  }
}
