import { post } from "~lib/ajax";
import backendUrl from "~lib/backendUrl";
import { ProductMatch } from "~lib/DB";
import {
  VolusionProductCreateRequest,
  VolusionProductCreateResponse
} from "~root-lib/AjaxTypes";

type IdMap = { [asin: string]: string };

export default async function createVolusionProducts(
  volusionAuth: { tenant: string; token: string },
  matches: ProductMatch[]
): Promise<IdMap> {
  return await post<
    VolusionProductCreateRequest,
    VolusionProductCreateResponse
  >(
    backendUrl("/volusion/products", volusionAuth),
    matches.map(m => m.amazonListing)
  );
}
