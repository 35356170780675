/**
 * While in staging, our application is heavily rate-limited by Amazon
 * because we use only one merchant’s auth.
 * Caching the results for the items on the home screen reduces our limit usage considerably.
 */

import { Product } from "~amazon-apis/src/search-products/types";

import belamor from "./belamor-long-sleeve-tshirt.json";
import mens from "./mens-daily-multivitamin.json";
import onewash from "./onewash-pressure-washer.json";
import safeman from "./safeman-key-home-kit.json";
import silipower from "./silipower-bluetooth-earbuds.json";
import tiosebon from "./tiosebon-women-athletic-shoes.json";

const localSearchResults = {
  "BELAMOR Women's Short & Long Sleeve Casual Boho Loose T-Shirt Dress": belamor,
  "OneWash Pressure Washer": onewash,
  "Men's Daily Multivitamin": mens,
  "SafeMan Key Home Kit": safeman,
  "Silipower Bluetooth Earbuds": silipower,
  "TIOSEBON Women's Athletic Shoes": tiosebon
} as { [k: string]: Product[] | undefined };

export default localSearchResults;
