import { React } from "~lib";
import { get } from "~lib/ajax";
import backendUrl from "~lib/backendUrl";
import DB from "~lib/DB";
import amazonLogo from "~lib/Images/amazon-logo.svg";
import ProgressBar from "~lib/ProgressBar";
import { CenterBox } from "~lib/Templates";
import Tutorial from "~lib/Tutorial";
import { DbCredentialsResponse } from "~root-lib/AjaxTypes";

import fetchInitialVolusionData from "./fetchInitialVolusionData";

type Props = {
  db: DB;
  onDone: () => void;
  tenant: string;
  token: string;
};

export default class ConnectVolusion extends React.Component<Props> {
  componentDidMount() {
    const { tenant, token } = this.props;
    this.connect({ tenant, token });
  }

  private connect = async (data: any) => {
    const { db, onDone } = this.props;

    try {
      const volusionAuth = {
        tenant: data.tenant,
        token: data.token.replace(/^\s*(Bearer\s*)?/, "Bearer ")
      };

      await db.update("volusionAuth", () => volusionAuth);

      const dbCreds = await get<DbCredentialsResponse>(
        backendUrl("/db/credentials", volusionAuth)
      );

      // Wait for initial sync to complete to avoid showing
      // inconsistent data at the start, such as a first-run
      // welcome screen for a return user.
      await db.initSync({
        url: backendUrl("/db"),
        headers: dbCreds.headers
      });

      // While we are fetching data to render the app, we need to wait
      // and show the connecting screen for the user.
      const products = await fetchInitialVolusionData(volusionAuth);
      await db.update("products", () => products);

      onDone();
    } catch (err) {
      this.setState(() => {
        throw err;
      });
    }
  };

  render() {
    return (
      <CenterBox
        css={{ padding: "25px 20px 28px", textAlign: "center" }}
        width={760}
      >
        <img src={amazonLogo} alt="Amazon logo" width={120} />
        <h1 style={{ fontSize: 28, margin: "31px 0 50px 0" }}>
          Start selling on Amazon instantly!
        </h1>
        <h2 style={{ fontSize: 24, margin: "0 0 12px 0" }}>Setting up</h2>
        <div style={{ margin: "0 auto 75px", maxWidth: 260 }}>
          <ProgressBar showProgressText seconds={15} />
        </div>
        <Tutorial />
      </CenterBox>
    );
  }
}
