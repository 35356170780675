import { Doc, getInitialData, PouchDBType, ValueKey, Values } from "./types";

/**
 * Wrap any document in this function
 * before saving the document to the database
 * to automatically resolve conflicts
 *
 * See handleConflict in ui
 */
export function conflictless<Document extends { _id: string }>(
  doc: Document
): Document & { time: number } {
  return {
    ...(doc as any),
    time: Date.now()
  };
}

export type UpdateTransform<Key extends ValueKey = ValueKey> = (
  oldValue: Values[Key]
) => Values[Key];

/**
 * Update the database value with your transformation function
 */
export function update<Key extends ValueKey>(
  db: PouchDBType,
  key: Key,
  transform: UpdateTransform<Key>
) {
  if (!db.upsert) {
    throw new Error(
      "The database instance you have given me does not have an upsert function. Please install the pouchdb-upsert plugin to your database yourself."
    );
  }
  return db.upsert(key, (prevDoc: Partial<Doc<Key>>) => {
    return conflictless({
      ...prevDoc,
      _id: key,
      value: transform(prevDoc.value || getInitialData()[key])
    });
  });
}
