import { isDevelopment } from "~lib/Environment";

const SentrySizeLimitInBytes = 200000;

export default function reportError(error: Error, info?: unknown) {
  if (!isDevelopment) {
    const Sentry = (window as any).Sentry;
    if (Sentry && Sentry.withScope) {
      Sentry.withScope((scope: any) => {
        if (info) {
          scope.setExtra(
            "info",
            JSON.stringify(info).slice(0, SentrySizeLimitInBytes / 2)
          );
        }
        Sentry.captureException(error);
      });
    }
  }
  console.error(error);
  if (info) {
    console.log("Additional info");
    console.log(info);
  }
}
