import { React, styled } from "~lib";
import Card from "~lib/Cards";
import Clickable, { Action } from "~lib/Clickable";
import ProductImage from "~lib/ProductImage";
import styles from "~lib/styles";
import Money from "~root-lib/Money";

import checkmark from "./checkmark.svg";
import PriceDisplay from "./PriceDisplay";

type Props = {
  action?: Action;
  activateHover?: boolean;
  customFooter?: React.ReactNode;
  height: number | string;
  horizontal?: boolean;
  minHeight?: number | string;
  minWidth?: number | string;
  product: {
    fallbackImage?: string;
    image?: string;
    price?: Money | [number, number] | "only-variants";
    title: string;
    variants?: {
      rows: { price: [number, number] }[];
    };
  };
  raisedShadow?: boolean;
  selected?: boolean;
  width: number | string;
};

const ImageContainer = styled("div")({
  alignItems: "center",
  display: "flex",
  flex: 1,
  justifyContent: "center",
  height: "100%",
  marginBottom: 2,
  marginTop: 15,
  minHeight: 0
});

const TextContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  maxHeight: "50%"
});

const Title = styled("div")({
  fontWeight: 400,
  hyphens: "auto",
  overflow: "hidden",
  overflowWrap: "break-word",
  wordBreak: "break-word"
});

const selectedStyle = styles({
  transform: "scale(0.92)"
});

const hoverStyle = styles({
  ":hover": {
    boxShadow: "0px 5px 19px rgba(0, 0, 0, 0.2)"
  }
});

export default function ProductCard(props: Props) {
  const {
    action,
    activateHover,
    customFooter,
    height,
    horizontal,
    minHeight,
    minWidth,
    raisedShadow,
    selected,
    width
  } = props;
  const { fallbackImage, image, price, title, variants } = props.product;

  return (
    <div style={{ position: "relative" }}>
      {selected && (
        <CheckmarkContainer>
          <img src={checkmark} alt="Selected product mark" />
        </CheckmarkContainer>
      )}
      <Card
        action={
          action && {
            nestedActionSelector: ".product-card-title"
          }
        }
        css={styles(
          { minHeight, minWidth },
          activateHover ? hoverStyle : {},
          selected ? selectedStyle : {}
        )}
        height={height}
        horizontal={horizontal}
        raisedShadow={raisedShadow}
        width={width}
      >
        <ImageContainer
          style={{
            flex: horizontal ? "0 0 35%" : undefined,
            marginRight: horizontal ? 20 : undefined
          }}
        >
          <ProductImage image={image} fallbackImage={fallbackImage} />
        </ImageContainer>
        <TextContainer
          style={{
            maxHeight: horizontal ? "100%" : undefined
          }}
        >
          <Title>
            {action ? (
              <Clickable action={action} className="product-card-title">
                {title}
              </Clickable>
            ) : (
              title
            )}
          </Title>
          <PriceDisplay
            price={price}
            variantPrices={variants && variants.rows.map(v => v.price)}
          />
          {customFooter}
        </TextContainer>
      </Card>
    </div>
  );
}

const CheckmarkContainer = styled("div")({
  position: "absolute",
  zIndex: 1
});
