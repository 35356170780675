import { React, styled } from "~lib";
import { ColorDot } from "~lib/Clickable";
import { ExtraOnChangeParams, MoneyInput, Select } from "~lib/Form";
import Money, { fromCents } from "~root-lib/Money";

import { nameFor } from "./fieldNames";
import Line from "./Line";
import { ProductInEdit } from "./types";

const CompetingOffers = styled("p")({
  color: "#666666",
  fontSize: 12,
  marginTop: 13
});

const Container = styled("div")({
  border: "1px solid #DDDDDD",
  borderRadius: 2,
  fontSize: 16,
  marginBottom: 50,
  padding: "40px 30px 30px",
  width: 150
});

const Label = styled("div")({
  fontSize: 12,
  color: "#666666"
});

const InputError = styled("div")({
  fontSize: 12,
  color: "#883333"
});

export type ValidatePrice = (input: Money) => "" | string;

type Props = {
  product: ProductInEdit;
  showPrice: boolean;
  validatePrice?: ValidatePrice;
};

type State = {
  chosenCondition: string;
  priceValidityMessage: "" | string;
};

export default class ConditionAndPrice extends React.Component<Props, State> {
  state: State = {
    chosenCondition: "New",
    priceValidityMessage: ""
  };

  componentDidMount() {
    const price = this.props.product.volusionPrice;
    if (price) this.priceChanged(price);
  }

  private conditionChanged = (condition: string) => {
    this.setState({ chosenCondition: condition });
  };

  private priceChanged = (price: Money, extras?: ExtraOnChangeParams) => {
    const priceValidityMessage = this.validatePrice(price);
    this.setState({ priceValidityMessage });
    if (extras) extras.setCustomValidity(priceValidityMessage);
  };

  private validatePrice: ValidatePrice = price => {
    const { product, validatePrice } = this.props;
    if (validatePrice) {
      const err = validatePrice(price);
      if (err) return err;
    }
    if (product.volusionPrice && product.volusionPrice.lessThan(price))
      return "Price must be no higher than on your Volusion store";
    if (fromCents(0).lessThan(price)) return "";
    return "Price must be above zero";
  };

  render() {
    const { product, showPrice } = this.props;
    const { chosenCondition, priceValidityMessage } = this.state;
    const offers = product.competingOffers.filter(
      offer => offer.condition === chosenCondition
    );
    const min = offers.reduce<Money | undefined>(
      (prev, curr) => (prev && prev.lessThan(curr.price) ? prev : curr.price),
      undefined
    );
    const max = offers.reduce<Money | undefined>(
      (prev, curr) => (prev && prev.lessThan(curr.price) ? curr.price : prev),
      undefined
    );
    return (
      <Container>
        <label>
          <ColorDot action="nested" color="green" nudgeX={-11} nudgeY="1.1em" />
          <Label>Condition:</Label>
          <Select name={nameFor("condition")} onChange={this.conditionChanged}>
            {/*
              There are also subconditions
              http://docs.developer.amazonservices.com/en_US/products/Products_GetCompetitivePricingForASIN.html
             */}
            <option value="New">New</option>
            <option value="UsedLikeNew">Used (like new)</option>
            <option value="UsedVeryGood">Used (very good)</option>
            <option value="UsedGood">Used (good)</option>
            <option value="UsedAcceptable">Used (acceptable)</option>
            <option value="CollectibleLikeNew">Collectible (like new)</option>
            <option value="CollectibleVeryGood">Collectible (very good)</option>
            <option value="CollectibleGood">Collectible (good)</option>
            <option value="CollectibleAcceptable">
              Collectible (acceptable)
            </option>
            <option value="Refurbished">Refurbished</option>
            <option value="Club">Club</option>
          </Select>
        </label>
        {showPrice && (
          <React.Fragment>
            <Line />
            <label>
              <ColorDot
                action="nested"
                color={priceValidityMessage ? "red" : "green"}
                nudgeX={-11}
                nudgeY="1.1em"
              />
              <Label>Price:</Label>
              <MoneyInput
                defaultValue={product.volusionPrice}
                name={nameFor("price")}
                onChange={this.priceChanged}
                required
              />
              {priceValidityMessage && (
                <InputError>{priceValidityMessage}</InputError>
              )}
            </label>
            {(min || max) && (
              <CompetingOffers>
                Competing Offers
                <br />
                {min &&
                  max && (
                    <>
                      {min.display()} to {max.display()}
                    </>
                  )}
                {min && !max && min.display()}
                {!min && max && max.display()}
              </CompetingOffers>
            )}
          </React.Fragment>
        )}
      </Container>
    );
  }
}
