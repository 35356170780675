import LRU from "lru-cache";

const cache = LRU({
  max: 20
});

export async function cached<Result>(
  key: string,
  compute: (key: string) => Promise<Result>
): Promise<Result> {
  if (cache.has(key)) {
    return Promise.resolve(cache.get(key) as any);
  }

  const result = await compute(key);
  cache.set(key, result);
  return result;
}
