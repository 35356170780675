import React from "react";
import { parse } from "querystringify";
import { HashRouter, Route, Switch } from "react-router-dom";

import DB from "~lib/DB";

import { ConnectVolusion } from "../Connect";

import addGlobalUrlState from "./addGlobalUrlState";
import componentFor from "./componentFor";
import { _Route, RouteFunction } from "./types";

type Props = {
  db: DB;
  routes: (route: RouteFunction) => _Route[];
};

export default class Routes extends React.Component<Props> {
  render() {
    const { db } = this.props;

    const { tenant, token } = parse(
      window.location.hash.replace(/^[^#]*#[^?]*/, "")
    );
    // db in this condition is a local PouchDB database, presumably,
    // freshly initialized for a new browser session. At this time
    // our browser database does not know that CouchDB might
    // have the volusionAuth already.
    if (tenant && token && db.volusionAuth() === "unconnected") {
      return (
        <ConnectVolusion
          db={db}
          tenant={tenant}
          token={token}
          onDone={() => {
            this.forceUpdate();
          }}
        />
      );
    }

    // This helper function is used over a simple map only for the typing
    // purposes. TypeScript can infer type parameter in function calls,
    // but not in multiple properties of a map.
    const routes = this.props.routes(function(
      path,
      component,
      options = {}
    ): _Route {
      return {
        component,
        params: options.params || {},
        path
      };
    });

    return (
      <HashRouter hashType="noslash">
        <Switch>
          <Route
            exact
            path="/connect/volusion"
            render={props => {
              // Looks complex, but is just a redirect
              setTimeout(() => {
                props.history.replace(
                  addGlobalUrlState({
                    current: props.location,
                    target: "/"
                  })
                );
              });
              return null;
            }}
          />
          {routes.map(route => {
            return (
              <Route
                exact
                key={route.path}
                path={route.path}
                component={componentFor(db, route)}
              />
            );
          })}
          <Route
            render={() => {
              // The application runs in the iframe,
              // the user is never in control of the URL.
              // Thus invalid route is always an internal programming error,
              // we can treat it as such for simplicity.
              throw new Error("404 Not Found");
            }}
          />
        </Switch>
      </HashRouter>
    );
  }
}
