const elements = ["a", "button", "select", '[role="dialog"]'];

export default function removePointerOutlines(document: HTMLDocument) {
  const style = document.createElement("style");
  style.innerHTML =
    elements.map(el => `${el}:focus`).join(",") + "{ outline: 0 }";

  const onKeyboard = () => {
    if (style.parentNode) style.remove();
  };

  const onPointer = () => {
    if (!style.parentNode && document.head) document.head.appendChild(style);
  };

  document.addEventListener("keydown", onKeyboard);
  document.addEventListener("mousedown", onPointer);
  document.addEventListener("touchstart", onPointer, { passive: true });

  // Start off hidden
  onPointer();
}
