import { parse } from "querystringify";

import addGlobalUrlState from "./addGlobalUrlState";
import { _Route, _RouterProps } from "./types";

function pathParams(route: _Route, props: _RouterProps) {
  const result = {} as any;
  const pathParams = Object.keys(route.params).filter(
    param => route.params[param] === "path"
  );
  pathParams.forEach(param => {
    const value = props.match.params[param];
    if (!value)
      throw new Error(
        `Route has declared an invalid param ${param}. See your route configuration, “params” should match the params that show in the URL as patterns with colons.`
      );
    result[param] = value;
  });
  return result;
}

function searchParams(route: _Route, props: _RouterProps) {
  const result = {} as any;
  const searchParams = Object.keys(route.params).filter(
    param => route.params[param] === "search"
  );
  searchParams.forEach(param => {
    result[param] = parse(props.location.search)[param] || "";
  });
  return result;
}

export default function transformProps(route: _Route, props: _RouterProps) {
  const innerProps = {
    ...pathParams(route, props),
    ...searchParams(route, props),
    myUrl: props.location.pathname + props.location.search,
    navigateTo: (path: string) => {
      props.history.push(
        addGlobalUrlState({
          current: props.location,
          target: path
        })
      );
    }
  };

  return innerProps;
}
