import { AmazonAuth } from "~root-lib/DB";

import DB, { AmazonListing } from "../types";

export const pretendAmazonAuth = {
  mwsToken: "pretend-token",
  sellerId: "pretend-seller-id"
};

export const pretendAmazonListings: AmazonListing[] = Array(20)
  .fill({})
  .map(
    (_, idx): AmazonListing => ({
      asin: `a${idx}`,
      isActive: true,
      price: [10, 99] as [number, number],
      quantity: 12,
      title:
        idx % 2
          ? "Wireless Charger iPhone X Case, FLOVEME Luxury Slim Fit"
          : "OneWash Pressure",
      images: {
        official:
          idx % 2
            ? {
                height: 176,
                url: "4bdeea225324c668b2c2f80a1527e9c4.png",
                width: 200
              }
            : {
                height: 176,
                url: "637d1f25ffbe3d31b315fb2352c6fab1.png",
                width: 176
              }
      }
    })
  );

export async function addPretendAmazon(db: DB, data: "with-data" | "no-data") {
  await db.update("amazonAuth", () => pretendAmazonAuth);

  if (data === "with-data")
    await db.update("amazonListings", () => pretendAmazonListings);
}

function isDB(obj: DB | AmazonAuth): obj is DB {
  return Boolean(typeof obj === "object" && obj && "update" in obj);
}

export function isPretendAmazon(db: DB | AmazonAuth) {
  const auth = isDB(db) ? db.amazonAuth() : db;
  return auth !== "unconnected" && auth.sellerId === pretendAmazonAuth.sellerId;
}
