import { React, styled } from "~lib";
import BrandedSearch from "~lib/BrandedSearch";

import clockIcon from "./clock.svg";

const Clock = styled("div")({
  alignItems: "center",
  border: "2px dashed #d2d2d2",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  height: 128,
  margin: "0 auto 36px",
  width: 128
});

const Container = styled("div")({
  marginBottom: 200,
  textAlign: "center"
});

const SearchContainer = styled("div")({
  marginBottom: 72
});

const Title = styled("div")({
  fontSize: 28,
  fontWeight: 400
});

export default class BulkAdd extends React.Component {
  render() {
    return (
      <Container>
        <SearchContainer>
          <BrandedSearch value="" disabled />
        </SearchContainer>
        <Clock>
          <img src={clockIcon} width={64} alt="" />
        </Clock>
        <Title>Coming Soon!</Title>
      </Container>
    );
  }
}
