import NanoEvents from "nanoevents";
import PouchDB, { PouchDBType } from "./pouchdb";
import { ConnectionInfo } from "./types";

export default async function initSync(params: {
  pouch: PouchDBType;
  connectionInfo: ConnectionInfo;
  events: NanoEvents;
  onError: (error: unknown) => void;
  updatesPendingReplicationExist: () => boolean;
}) {
  const {
    pouch,
    connectionInfo,
    events,
    onError,
    updatesPendingReplicationExist
  } = params;

  events.emit("init-sync");
  events.on("init-sync", () =>
    onError(new Error("initSync is called twice, this is not meaningful"))
  );

  // pouchdb-browser typings have not been updated yet
  const untypedOptions: any = {
    fetch: function(url: string, opts: any) {
      const { headers } = connectionInfo;
      Object.keys(headers).forEach(name => {
        opts.headers.set(name, headers[name]);
      });
      return (PouchDB as any).fetch(url, opts);
    }
  };

  const remoteDb = new PouchDB(connectionInfo.url, {
    skip_setup: true,
    ...untypedOptions
  });

  await pouch.replicate.from(remoteDb);
  pouch
    .sync(remoteDb, {
      live: true,
      retry: true,
      skip_setup: true
    } as any)
    .on("active" as any, () => {
      events.emit("network-happy");
    })
    .on("change", function(info) {
      if (info.direction === "push") {
        info.change.docs.forEach(doc =>
          events.emit("rev-pushed-to-remote", doc._rev)
        );
      }
    })
    .on("error", onError)
    .on("paused", () => {
      if (updatesPendingReplicationExist()) events.emit("network-problems");
    });
}
