import { ValueKey, Values } from "~root-lib/DB";

import { Update } from "./createUpdater";

export * from "~root-lib/DB";

// This internal storage stores the entire documents for convenience of updates
export type Storage = { [K in ValueKey]: Values[K] };

type ConnectionControls = {
  initSync: (connectionInfo: ConnectionInfo) => Promise<void>;
};
export type ConnectionInfo = {
  headers: { [name: string]: string };
  url: string;
};

// Key given to the listener opens the door for optimized listeners
// that only care about a subset of the events
type Register<Name extends string, Arg = void> = (
  name: Name,
  cb: (arg: Arg) => void
) => (() => void);
type EventControls = {
  on: Register<"change", string> &
    Register<"error", Error> &
    Register<"network-problems"> &
    Register<"network-happy">;
};

type WriteControls = {
  clearMyDatabase: () => Promise<void>;
  update: Update;
  updatesPendingReplicationExist: () => boolean;
};

// Explicit getters make it a bit clearer that the data is immutable
// Simplifies future functionality additions
export type DataGetters = { [K in ValueKey]: () => Values[K] };

type DB = ConnectionControls & DataGetters & EventControls & WriteControls;

export default DB;
