import { post } from "~lib/ajax";
import { parseFields } from "~lib/AmazonProductPageLookalike";
import backendUrl from "~lib/backendUrl";
import { isPretendAmazon, AmazonAuth, VolusionAuth } from "~lib/DB";
import {
  AmazonListProductRequest,
  AmazonListProductResponse
} from "~root-lib/AjaxTypes";
import { FormDetails } from "./types";

type Params = {
  amazonAuth: Exclude<AmazonAuth, "unconnected">;
  asin: string;
  formDetails: FormDetails;
  parsedFields: ReturnType<typeof parseFields>;
  productTitle: string;
  volusionAuth: Exclude<VolusionAuth, "unconnected">;
  volusionProductId: string;
  volusionSku: string;
};

export default async function submit(params: Params): Promise<void> {
  const {
    asin,
    amazonAuth,
    formDetails,
    productTitle,
    volusionAuth,
    volusionProductId,
    volusionSku
  } = params;
  const { condition, price, variants, quantity } = params.parsedFields;

  if (isPretendAmazon(amazonAuth))
    throw new Error("Cannot submit in pretend mode");

  const listing: AmazonListProductRequest = {
    asin,
    quantity,
    images: formDetails.images || {},
    price: price ? [price.dollars(), price.cents()] : "only-variants",
    title: productTitle,
    volusionProductId,
    condition: condition,
    sku: volusionSku
  };

  if (variants && variants.length > 0) {
    listing.variants = {
      groups: {},
      rows: variants.map(function(v) {
        if (!formDetails.variants)
          throw new Error("Empty formDetails.variants");
        const variant = formDetails.variants.amazon.rows.find(
          row => row.asin === v.asin
        );
        if (!variant) throw new Error(`No variant ${v.asin}`);
        return {
          ...variant,
          asin: v.asin,
          price: [v.price.dollars(), v.price.cents()] as [number, number],
          quantity: v.quantity,
          sku: v.sku,
          volusionVariantId: v.volusionId
        };
      })
    };
  }

  await post<AmazonListProductRequest, AmazonListProductResponse>(
    backendUrl("/amazon/list", {
      sellerAuthToken: amazonAuth.mwsToken,
      sellerId: amazonAuth.sellerId,
      tenant: volusionAuth.tenant,
      token: volusionAuth.token
    }),
    listing
  );
}
