import { React, styled } from "~lib";

import DragHandle from "~lib/DragHandle";
import { duringDragSelector } from "~lib/Dragula";
import Money from "~root-lib/Money";

import { skuFieldAttribute } from "./collectFields";
import ProductName from "./ProductName";
import QuantityAndPrice from "./QuantityAndPrice";
import { compactView } from "./styles";

const Content = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "0 9px",
  overflow: "hidden",
  [compactView]: {
    justifyContent: "flex-start"
  }
});

const Wrapper = styled("div")({
  backgroundColor: "white",
  border: "2px solid #aaaaaa",
  borderRadius: 6,
  boxSizing: "border-box",
  color: "black",
  cursor: "grab",
  [duringDragSelector]: {
    cursor: "grabbing"
  },
  display: "flex",
  fontSize: 12,
  justifyContent: "space-between",
  height: 80,
  padding: "8px 9px",
  width: 170,
  "[data-amazon-target] &": {
    borderColor: "#ff9900"
  },
  [compactView]: {
    justifyContent: "flex-start",
    minHeight: 94,
    width: "100%"
  }
});

type Props = {
  id: string;
  name: string;
  price: Money;
  quantity?: number;
  sku?: string;
};
export default class ProductVariant extends React.Component<Props> {
  render() {
    const { id, name, price, quantity, sku } = this.props;
    return (
      <Wrapper data-volusion-variant={id}>
        <input type="hidden" value={sku} {...{ [skuFieldAttribute]: true }} />
        <div style={{ flexShrink: 0 }}>
          <DragHandle size={5} />
        </div>
        <Content>
          <ProductName name={name} />
          <QuantityAndPrice price={price} quantity={quantity} />
        </Content>
      </Wrapper>
    );
  }
}
