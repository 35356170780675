import { parse, stringify } from "querystringify";
import React from "react";

export type LinkAction = { newWindow?: boolean; url: string };
export type Routing = {
  location: {
    pathname: string;
    search: string;
  };
  routerLink: (
    props: {
      children: React.ReactNode;
      className: string;
      replace: boolean;
      style: React.CSSProperties;
      to: string;
    }
  ) => React.ReactNode;
};

type Props = {
  action: LinkAction;
  children: React.ReactNode;
  childrenIfCurrent: React.ReactNode;
  classNameIfCurrent: string;
  classNameIfNonCurrent: string;
  routing?: Routing;
  style?: React.CSSProperties;
};

const needRouting = () =>
  new Error(
    "To use this action, you need to provide a `routing` option for your ActionArea."
  );

export default function Link(props: Props): React.ReactElement<any> {
  const {
    action,
    children,
    childrenIfCurrent,
    classNameIfNonCurrent,
    classNameIfCurrent,
    routing,
    style
  } = props;

  const { newWindow, url } = action;

  if (url.match(/^https?:\/\//)) {
    if (!newWindow)
      throw new Error(
        `In this application all external links should open in new window, use { newWindow: true, url: '${url}'}`
      );

    return (
      <a
        href={url}
        className={classNameIfNonCurrent}
        rel={newWindow ? "noopener noreferrer" : undefined}
        target={newWindow ? "_blank" : undefined}
        style={style}
      >
        {children}
      </a>
    );
  }

  if (newWindow)
    throw new Error(`newWindow supported for external links, not ${url}`);

  const isQueryForm = (s: string) => s.match(/^\w+=\w*(&\w+=\w*)*$/);
  if (isQueryForm(url)) {
    if (!routing) throw needRouting();
    const requested = parse(url);
    const current = parse(routing.location.search);
    const matched =
      Object.keys(requested).filter(
        key => (current[key] || "") !== (requested[key] || "")
      ).length === 0;
    if (matched)
      return (
        <div className={classNameIfCurrent} style={style}>
          {childrenIfCurrent}
        </div>
      );
    return (
      <React.Fragment>
        {routing.routerLink({
          children,
          className: classNameIfNonCurrent,
          replace: true,
          to:
            routing.location.pathname +
            "?" +
            stringify({ ...current, ...requested }),
          style: style || {}
        })}
      </React.Fragment>
    );
  }

  if (url.match(/^\/(?!\/)/)) {
    if (!routing) throw needRouting();
    if (routing.location.pathname === url.replace(/\?.+/, "")) {
      const query = url.replace(/.+\?/, "");
      if (isQueryForm(query))
        return <Link {...props} action={{ url: query }} />;
      return (
        <div className={classNameIfCurrent} style={style}>
          {childrenIfCurrent}
        </div>
      );
    }
    return (
      <React.Fragment>
        {routing.routerLink({
          children,
          className: classNameIfNonCurrent,
          replace: false,
          style: style || {},
          to: url
        })}
      </React.Fragment>
    );
  }

  throw new Error(`Unsupported action for an ActionArea: "${url}"`);
}
