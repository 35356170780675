import { React, styled } from "~lib";
import { ClickableText } from "~lib/Clickable";
import Money from "~root-lib/Money";
import { compactView } from "./styles";

type Props = {
  asin: string;
  competingOffers?: { price: Money }[];
  image?: string;
  name: string;
};

const Description = styled("div")({
  color: "#666666",
  fontSize: 10,
  [compactView]: {
    display: "none"
  }
});

const Icon = styled("div")({
  borderRadius: 6,
  flexShrink: 0,
  height: 36,
  width: 36
});

const Text = styled("div")({
  marginLeft: 10,
  width: 80
});
const Title = styled("div")({
  fontSize: 12,
  fontWeight: 400,
  maxHeight: 35,
  overflowY: "hidden"
});
const Wrapper = styled("div")({
  display: "flex",
  marginTop: 10,
  width: 130,
  [compactView]: {
    overflow: "hidden",
    width: "calc(90% - 10px)"
  }
});

export default function AmazonVariant(props: Props) {
  const { asin, competingOffers, image, name } = props;
  const minOffer =
    competingOffers &&
    competingOffers.reduce(
      (prev, curr) => (prev.price.lessThan(curr.price) ? prev : curr)
    );
  const maxOffer =
    competingOffers &&
    competingOffers.reduce(
      (prev, curr) => (prev.price.lessThan(curr.price) ? curr : prev)
    );
  return (
    <Wrapper title={name}>
      <Icon style={!image ? { border: "1px solid #d2d2d2" } : {}}>
        {image && <img src={image} alt="" />}
      </Icon>
      <Text>
        <Title>
          <ClickableText
            action={{
              newWindow: true,
              url: `https://www.amazon.com/dp/${encodeURIComponent(asin)}`
            }}
          >
            {name}
          </ClickableText>
        </Title>
        {minOffer && (
          <Description>
            Competing Offers {minOffer.price.display()}
            {maxOffer &&
              minOffer.price.lessThan(maxOffer.price) && (
                <React.Fragment> to {maxOffer.price.display()}</React.Fragment>
              )}
          </Description>
        )}
      </Text>
    </Wrapper>
  );
}
