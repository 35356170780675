import NanoEvents, { CancelFunction } from "nanoevents";
import { React } from "~lib";

export type State = Toast[];

export type Toast = {
  id: string;
} & (
  | ({ type: "regular"; text: string })
  | ({ type: "custom"; node: React.ReactNode }));

let state: State = [];

const events = new NanoEvents();

let idGenerationCounter = 0;

export function addToast(text: string) {
  state.push({
    id: String(idGenerationCounter++),
    text,
    type: "regular"
  });
  events.emit("change");
}

export function addCustomToast(node: React.ReactNode) {
  const id = String(idGenerationCounter++);
  state.push({
    id,
    node,
    type: "custom"
  });
  events.emit("change");
  return () => dismiss(id);
}

export function dismiss(id: string) {
  state = state.filter(t => t.id !== id);
  events.emit("change");
}

export function get(): State {
  return state.slice(0);
}

export function onChange(f: (state: State) => void): CancelFunction {
  return events.on("change", function() {
    f(get());
  });
}
