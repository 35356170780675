import { React, styled } from "~lib";
import AmazonProductPageLookalike, {
  parseFields
} from "~lib/AmazonProductPageLookalike";
import { CTA } from "~lib/Clickable";
import DB from "~lib/DB";
import Form from "~lib/Form";
import ProgressBar from "~lib/ProgressBar";
import { addToast } from "~lib/Toasts";
import { url } from "~lib/URL";

import fetchDetails from "./fetchDetails";
import Layout from "./Layout";
import { FormDetails } from "./types";
import submit from "./submit";

const SubmitButtons = styled("div")({
  marginTop: 30,
  textAlign: "right"
});

type Props = {
  asin: string;
  cameFromSearchFor: string;
  db: DB;
  navigateTo: (path: string) => void;
  productId: string;
};

type State = {
  step:
    | "retrieving-details"
    | { type: "form"; formDetails: FormDetails; volusionSku: string }
    | "submitting";
};

export default class SellExisting extends React.Component<Props, State> {
  state: State = {
    step: "retrieving-details"
  };

  componentDidMount() {
    const { asin, db, productId } = this.props;
    const products = db.products();

    const auth = db.amazonAuth();
    if (auth === "unconnected" || products === "unsynced")
      throw new Error(
        "Can not access this page without Amazon auth or Volusion products"
      );
    const volusionProduct = products.find(p => p.id === productId);
    if (!volusionProduct)
      throw new Error("Invalid or missing Volusion Product");
    const { sku } = volusionProduct;
    if (!sku) throw new Error("Invalid Volusion product, no sku");

    fetchDetails({
      asin,
      auth,
      volusionProduct
    })
      .then(formDetails => {
        this.setState({
          step: {
            type: "form",
            formDetails,
            volusionSku: sku
          }
        });
      })
      .catch(err => {
        this.setState(() => {
          throw err;
        });
      });
  }

  private searchUrl = () => {
    const { cameFromSearchFor, productId } = this.props;
    return url(
      ["sell", productId],
      cameFromSearchFor ? { startSearchWith: cameFromSearchFor } : null
    );
  };

  private submit = (
    fieldValues: { [fieldName: string]: unknown },
    extras: { form: HTMLFormElement }
  ) => {
    const { form } = extras;
    const { asin, db, navigateTo, productId } = this.props;
    const { step } = this.state;
    const volusionAuth = db.volusionAuth();
    const amazonAuth = db.amazonAuth();
    if (
      volusionAuth === "unconnected" ||
      amazonAuth === "unconnected" ||
      typeof step === "string" ||
      step.type !== "form"
    )
      throw new Error("Submitting from invalid state");

    const { formDetails, volusionSku } = step;
    this.setState({ step: "submitting" });
    submit({
      asin,
      amazonAuth,
      formDetails,
      parsedFields: parseFields(form, fieldValues),
      productTitle: formDetails.title,
      volusionAuth,
      volusionProductId: productId,
      volusionSku
    })
      .catch(error => {
        this.setState(() => {
          throw error;
        });
      })
      .then(() => {
        addToast("Sending product to Amazon");
        navigateTo("/");
      });
  };

  render() {
    const { step } = this.state;

    return (
      <Layout
        searchUrl={this.searchUrl()}
        showVariantGuide={Boolean(
          typeof step !== "string" &&
            step.type === "form" &&
            step.formDetails.variants
        )}
      >
        <Form onSubmit={this.submit}>
          {typeof step !== "string" &&
            step.type === "form" && (
              <>
                <AmazonProductPageLookalike product={step.formDetails} />
                <SubmitButtons>
                  <CTA action="submit">Add to Amazon</CTA>
                </SubmitButtons>
              </>
            )}
          {step === "retrieving-details" && <ProgressBar seconds={5} />}
          {step === "submitting" && <ProgressBar seconds={15} />}
        </Form>
      </Layout>
    );
  }
}
