import { React, styled } from "~lib";

import { TextPlaceholder } from "~lib/ContentPlaceholders";

const Ul = styled("ul")({
  fontSize: 12,
  lineHeight: "12px",
  paddingLeft: 20
});

const Li = styled("li")({
  listStyleType: "none",
  marginBottom: 25,
  "&::before": {
    color: "#C4C4C4",
    content: "' '",
    float: "left",
    display: "list-item",
    listStyleType: "disc",
    listStylePosition: "inside",
    width: 20,
    marginLeft: -20
  }
});

export default class Bullets extends React.Component {
  render() {
    return (
      <Ul>
        <Li>
          <TextPlaceholder />
          <TextPlaceholder width="87%" />
        </Li>
        <Li>
          <TextPlaceholder />
          <TextPlaceholder width="51%" />
        </Li>
        <Li>
          <TextPlaceholder />
          <TextPlaceholder width="87%" />
        </Li>
      </Ul>
    );
  }
}
