import { React, styled } from "~lib";
import Card from "~lib/Cards";
import { CTA } from "~lib/Clickable";
import deadBrowser from "~lib/Images/dead-browser-gray.svg";

const Whoops = styled("div")({
  color: "#182433",
  fontSize: 32,
  marginTop: 50
});

const Message = styled("div")({
  color: "rgba(51, 62, 75, 0.54)",
  fontSize: 16,
  marginBottom: 40,
  marginTop: 6
});

type Props = {
  onSearchAgain: () => void;
};

export default function Failed(props: Props) {
  const { onSearchAgain } = props;
  return (
    <Card padding="100px 0">
      <div style={{ textAlign: "center" }}>
        <img src={deadBrowser} />
        <Whoops>Whoops!</Whoops>
        <Message>Failed to load search results</Message>
        <CTA action={onSearchAgain} width={200}>
          Try again
        </CTA>
      </div>
    </Card>
  );
}
