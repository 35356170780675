import { React, styled } from "~lib";
import { Rectangle as ClickableRectangle } from "~lib/Clickable";

import inlineCheckmark from "./Images/inline-checkmark.svg";
import { cardOverlay, compactView } from "./styles";

type Props = {
  onImportClick: () => void;
  onManualMatchClick: () => void;
  userApprovedPairing?: boolean;
};

export default function ManualMatch(props: Props) {
  return (
    <Container userApprovedPairing={props.userApprovedPairing}>
      <Title>Already on Volusion</Title>
      <Subtitle>Find matching product</Subtitle>
      <ButtonContainer>
        <ClickableRectangle
          action={props.onManualMatchClick}
          small
          width={120}
          theme="gray-on-white"
        >
          Find match
        </ClickableRectangle>
      </ButtonContainer>
      <HStack>
        <HalfHr />
        <Or>OR</Or>
        <HalfHr />
      </HStack>
      <Title>Product not on Volusion</Title>
      <Subtitle>Import Amazon product</Subtitle>
      <ButtonContainer>
        <ClickableRectangle
          action={props.onImportClick}
          small
          width={120}
          theme={props.userApprovedPairing ? "positive" : "gray-on-white"}
        >
          {props.userApprovedPairing && (
            <React.Fragment>
              <img src={inlineCheckmark} alt="Import confirmation mark" />{" "}
            </React.Fragment>
          )}
          Import
        </ClickableRectangle>
      </ButtonContainer>
    </Container>
  );
}

const ButtonContainer = styled("div")({
  fontSize: 11,
  marginTop: 10
});

type ContainerProps = {
  userApprovedPairing?: boolean;
};

const Container = styled("div")(
  {
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 2,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: 255,
    justifyContent: "center",
    marginLeft: -cardOverlay,
    marginTop: 30,
    padding: "15px 11px",
    textAlign: "center",
    width: 244,
    [compactView]: {
      height: 269,
      minWidth: 170,
      width: "23vw"
    }
  },
  (props: ContainerProps) => ({
    border: props.userApprovedPairing
      ? "4px dashed #9E00FF"
      : "4px dashed #DCDCDD"
  })
);

const HalfHr = styled("div")({
  border: 0,
  borderBottom: "2px solid #DCDCDD",
  height: 2,
  width: 40
});

const HStack = styled("div")({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  margin: "18px 0px 14px 0px",
  width: 120
});

const Title = styled("div")({
  fontWeight: 400,
  hyphens: "auto",
  lineHeight: 1.4,
  wordBreak: "break-word"
});

const Or = styled("span")({
  color: "#757575",
  fontSize: 14,
  margin: "0px 10px"
});

const Subtitle = styled("div")({
  color: "#757575",
  fontSize: 12,
  fontWeight: 300,
  hyphens: "auto",
  wordBreak: "break-word"
});
