import { styled } from "~lib";

const Line = styled("div")({
  backgroundColor: "#E7E7E7",
  height: 1,
  marginTop: 10,
  marginBottom: 20
});

export default Line;
