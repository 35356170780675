import { React, styled } from "~lib";
import DB, { ProductMatch } from "~lib/DB";
import { cardGridGap } from "~lib/Templates/styles";

import mergeIcon from "./Images/merge-icon.svg";
import LeftSide from "./LeftSide";
import RightSide from "./RightSide";
import { compactMargin, compactView } from "./styles";

type Props = {
  match: ProductMatch;
  onImportClick: (match: ProductMatch) => void;
  onManualMatchClick: (match: ProductMatch) => void;
  onRemoveMatchClick: (match: ProductMatch) => void;
  db: DB;
};

export default class ProductMatcher extends React.Component<Props> {
  private handleImportClick = () => {
    this.props.onImportClick(this.props.match);
  };

  private handleManualMatchClick = () => {
    this.props.onManualMatchClick(this.props.match);
  };

  private handleRemoveMatchClick = () => {
    this.props.onRemoveMatchClick(this.props.match);
  };

  render() {
    const { match } = this.props;
    return (
      <Container>
        <LeftSide amazonListing={match.amazonListing} />
        <MergeIconContainer>
          <MergeIcon src={mergeIcon} />
        </MergeIconContainer>
        <RightSide
          onImportClick={this.handleImportClick}
          onManualMatchClick={this.handleManualMatchClick}
          onRemoveMatchClick={this.handleRemoveMatchClick}
          volusionListing={match.volusionListing}
          userApprovedPairing={match.userApprovedPairing}
        />
      </Container>
    );
  }
}

const Container = styled("div")({
  display: "flex",
  marginBottom: cardGridGap,
  [compactView]: { marginLeft: compactMargin }
});

const MergeIconContainer = styled("div")({
  display: "flex",
  justifyContents: "center",
  marginLeft: -45,
  marginRight: -45,
  pointerEvents: "none",
  zIndex: 2,
  [compactView]: { display: "none" }
});

const MergeIcon = styled("img")({});
