import { keyframes } from "emotion";
import { styles } from "~lib";

const stripeSize = 32;

const moveBackgroundRight = keyframes`
  from {
    background-position-x: 0
  }

  to {
    background-position-x: ${stripeSize * 8}px
  }
`;

export default function diagonalStripeStyles(color1: string, color2: string) {
  return styles({
    animation: `${moveBackgroundRight} 5s linear infinite`,
    background: `linear-gradient(315deg, ${color1} 25%, ${color2} 25%, ${color2} 50%, ${color1} 50%, ${color1} 75%, ${color2} 75%, ${color2})`,
    backgroundSize: `${stripeSize}px ${stripeSize}px`
  });
}
