import { React } from "~lib";

import { css, injectGlobal } from "emotion";

type Props = {
  children: React.ReactNode;
};

const backgroundColor = "#f2f2f2";
const fontFamily = "Lato, sans-serif";

injectGlobal({
  "html, body": {
    backgroundColor,
    margin: 0,
    padding: 0
  },
  h1: {
    fontSize: 32,
    fontWeight: "inherit",
    lineHeight: 1.3
  },
  h2: {
    fontSize: 28,
    fontWeight: "inherit"
  },
  h3: {
    fontSize: 24,
    fontWeight: "inherit"
  },
  h4: {
    fontSize: 18,
    fontWeight: "inherit"
  },
  img: {
    maxWidth: "100%"
  },
  p: {
    margin: 0,
    maxWidth: "45em"
  }
});

export const aestheticStyles = css({
  backgroundColor,
  fontFamily,
  fontSize: 16,
  fontWeight: 300,
  lineHeight: 1.5
});

export const layoutStyles = css({
  boxSizing: "border-box",
  minHeight: "100vh",
  // Prevent childrens’ bottom margins from collapsing
  // into our margin and extending us beyond 100vh
  // when there is little content on the screen.
  paddingBottom: 0.1
});

export default function AppStyles(props: Props) {
  const { children } = props;
  return <div className={aestheticStyles + " " + layoutStyles}>{children}</div>;
}
