import { React, styled } from "~lib";
import Badge from "~lib/BrandBadge";
import { ProductCard } from "~lib/Cards";
import { Rectangle as ClickableRectangle } from "~lib/Clickable";
import { Product } from "~lib/DB";

import ManualMatch from "./ManualMatch";
import { badgeTop, cardOverlay, compactView, regularView } from "./styles";

const CompactCard = styled("div")({
  [regularView]: { display: "none" }
});

const RegularCard = styled("div")({
  [compactView]: { display: "none" }
});

type Props = {
  onImportClick: () => void;
  onManualMatchClick: () => void;
  onRemoveMatchClick: () => void;
  volusionListing?: Product;
  userApprovedPairing?: boolean;
};

export default function RightSide(props: Props) {
  const product = props.volusionListing;

  if (!product)
    return (
      <ManualMatch
        onImportClick={props.onImportClick}
        onManualMatchClick={props.onManualMatchClick}
        userApprovedPairing={props.userApprovedPairing}
      />
    );

  const productProps = {
    customFooter: (
      <StaticButtonContainer>
        <ClickableRectangle
          action={props.onRemoveMatchClick}
          small
          theme="gray-on-white"
        >
          Remove match
        </ClickableRectangle>
      </StaticButtonContainer>
    ),
    product
  };

  return (
    <Container>
      <Badge
        centered
        compactMediaQuery={compactView}
        brand="volusion"
        nudgeY={badgeTop}
      />
      <HoverButtonContainer data-remove-match-hover-button>
        <div style={{ marginLeft: cardOverlay }}>
          <ClickableRectangle
            action={props.onRemoveMatchClick}
            theme="destructive"
          >
            Remove match
          </ClickableRectangle>
        </div>
      </HoverButtonContainer>
      <RegularCard>
        <ProductCard height={255} width={244} {...productProps} />
      </RegularCard>
      <CompactCard>
        <ProductCard
          height={269}
          minWidth={170}
          width={"23vw"}
          {...productProps}
        />
      </CompactCard>
    </Container>
  );
}

const Container = styled("div")({
  alignItems: "center",
  border: 0,
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginLeft: -cardOverlay,
  [`&:hover [data-remove-match-hover-button]`]: {
    visibility: "visible"
  }
});

const HoverButtonContainer = styled("div")({
  alignItems: "center",
  backgroundColor: "rgba(0, 0, 0, 0.4)",
  border: 0,
  borderRadius: 2,
  boxSizing: "border-box",
  display: "none",
  height: 255,
  justifyContent: "center",
  marginTop: badgeTop,
  position: "absolute",
  visibility: "hidden",
  width: 244,
  zIndex: 1,
  [compactView]: {
    height: 269,
    minWidth: 170,
    width: "23vw"
  },
  "@media (any-hover: hover)": {
    display: "flex"
  }
});

const StaticButtonContainer = styled("div")({
  margin: "10px auto -6px auto",
  "@media (any-hover: hover)": {
    display: "none"
  }
});
