import { React, styled } from "~lib";
import Clickable from "~lib/Clickable";
import xIcon from "~lib/Images/X.svg";

export const width = 325;
export const zIndex = 20;

const Container = styled("div")({
  backgroundColor: "white",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.18)",
  boxSizing: "border-box",
  color: "#757575",
  fontSize: 14,
  lineHeight: "18px",
  maxWidth: "90vw",
  minHeight: 20,
  padding: "16px 20px 20px 25px",
  textAlign: "left",
  width,
  zIndex
});

const Title = styled("div")({
  color: "black",
  fontSize: 18,
  lineHeight: "32px",
  marginBottom: 9
});

export type UserProps = {
  children: React.ReactNode;
  onClose?: () => void;
  title?: string;
};

type Props = UserProps & {
  innerRef: any;
  style: React.CSSProperties;
};

export default function Box(props: Props) {
  const { children, innerRef, onClose, style, title } = props;
  return (
    <Container innerRef={innerRef} style={style}>
      {onClose && (
        <Clickable
          action={onClose}
          style={{
            color: "black",
            position: "absolute",
            top: 14,
            right: 14
          }}
        >
          <img src={xIcon} alt="X" height={14} width={14} />
        </Clickable>
      )}
      {title && <Title>{title}</Title>}
      {children}
    </Container>
  );
}
