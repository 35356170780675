import { React, styled } from "~lib";

import { Rectangle } from "~lib/Clickable";

const Description = styled("p")({
  color: "#6e6e6e",
  marginBottom: 25
});

const ImportWrapper = styled("div")({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  textAlign: "center"
});

const Title = styled("div")({
  fontSize: 28,
  marginBottom: 10
});

type Props = {
  dataReady: boolean;
};

export default function Import(props: Props) {
  const { dataReady } = props;
  return (
    <ImportWrapper>
      <Title>Already sell products on Amazon?</Title>
      {dataReady ? (
        <React.Fragment>
          <Description>
            Import select products from your Seller Central account to your
            Volusion store.
          </Description>
          <Rectangle action="/import" theme="gray-on-gray">
            Import products
          </Rectangle>
        </React.Fragment>
      ) : (
        <Description>
          We’re loading your data from Amazon. Check back in a few minutes and
          you’ll be able to import products from your Seller Central account to
          your Volusion store.
        </Description>
      )}
    </ImportWrapper>
  );
}
