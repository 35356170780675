import { React, styled } from "~lib";

import diagonalStripeStyles from "./diagonalStripeStyles";

const ProgressText = styled("div")({
  fontSize: 14,
  fontWeight: 400,
  marginBottom: 20
});

const Container = styled("div")({
  backgroundColor: "white",
  borderRadius: 20,
  height: 16,
  overflow: "hidden"
});

const Bar = styled("div")({
  backgroundColor: "#FF9900",
  fontSize: "10",
  height: "100%"
});

const indeterminateStyles = diagonalStripeStyles("#ff9900", "#ffd699");

export type PublicProps = {
  showProgressText?: boolean;
};

export type Props = PublicProps & {
  value: "indeterminate" | number;
};

export default function RealProgressBar(props: Props) {
  const { showProgressText, value } = props;
  return (
    <>
      {showProgressText && (
        <ProgressText>
          {typeof value === "number"
            ? `${Math.floor(value)}% complete`
            : "Working..."}
        </ProgressText>
      )}
      <Container
        role="progressbar"
        aria-valuenow={typeof value === "number" ? value : undefined}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        <Bar
          className={
            value === "indeterminate" ? indeterminateStyles : undefined
          }
          style={{
            width: typeof value === "number" ? `${value}%` : undefined
          }}
        />
      </Container>
    </>
  );
}
