import { React, styles } from "~lib";
import { diagonalStripeStyles } from "~lib/ProgressBar";

import BaseClickable, { Action } from "./BaseClickable";

const themes = {
  cta: styles({
    backgroundColor: "#FF9900",
    borderColor: "#FF9900",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.26)",
    color: "white"
  }),
  "cta-disabled": styles({
    backgroundColor: "#9B9B9B",
    borderColor: "#9B9B9B"
  }),
  positive: styles({
    backgroundColor: "#9E00FF",
    borderColor: "#9E00FF",
    borderRadius: 2,
    color: "white"
  }),
  "gray-on-gray": styles({
    backgroundColor: "#F2F2F2",
    borderColor: "rgba(0, 0, 0, 0.2)",
    color: "rgba(0, 0, 0, 0.54)"
  }),
  "gray-on-white": styles({
    backgroundColor: "white",
    borderColor: "hsl(0, 0%, 80%)",
    color: "hsl(0, 0%, 46%)"
  }),
  destructive: styles({
    backgroundColor: "#FF3E80",
    borderColor: "#FF3E80",
    borderRadius: 2,
    color: "white"
  }),
  inherit: styles({
    borderColor: "currentColor"
  })
};

const themePretendWorkStyles: { [theme: string]: string | undefined } = {
  cta: styles(diagonalStripeStyles("#ff9900", "#ffd699"), {
    borderColor: "transparent"
  })
};

export type Props = {
  action: Action;
  children: React.ReactNode;
  disabled?: boolean;
  width?: number;
  pretendToWork?: number;
  small?: boolean;
  theme?: keyof typeof themes;
};

const className = styles({
  borderRadius: 4,
  borderStyle: "solid",
  borderWidth: 2,
  boxSizing: "border-box",
  display: "inline-block",
  fontSize: 12,
  fontWeight: 400,
  letterSpacing: "0.085em",
  maxWidth: "100%",
  padding: "10px 30px",
  textAlign: "center",
  textTransform: "uppercase",
  userSelect: "none"
});

const activeClassName = styles({
  // :active { transform: translateY(1px) } disables buttons on iOS Safari
  // See #531.
});

type State = {
  pretendingToWork: boolean;
};

export default class ClickableRectangle extends React.Component<Props, State> {
  state: State = {
    pretendingToWork: false
  };

  componentWillUnmount() {
    if (this.pretendingToWorkFrame)
      window.cancelAnimationFrame(this.pretendingToWorkFrame);
    if (this.pretendingToWorkTimeout)
      window.clearTimeout(this.pretendingToWorkTimeout);
  }

  private click = () => {
    const { pretendToWork } = this.props;
    const { pretendingToWork } = this.state;

    // https://github.com/facebook/react/issues/9046
    this.pretendingToWorkFrame = window.requestAnimationFrame(() => {
      if (pretendToWork && !pretendingToWork) {
        this.setState({ pretendingToWork: true });
        this.pretendingToWorkTimeout = window.setTimeout(() => {
          this.setState({ pretendingToWork: false });
        }, pretendToWork);
      }
    });
  };

  private pretendingToWorkFrame?: number;
  private pretendingToWorkTimeout?: number;

  render() {
    const {
      action,
      children,
      disabled,
      small,
      theme,
      width,
      pretendToWork
    } = this.props;
    const { pretendingToWork } = this.state;
    const pretendingToWorkStyles = themePretendWorkStyles[theme || "default"];
    if (pretendToWork && !pretendingToWorkStyles)
      throw new Error(`pretendToWork styles not done for theme ${theme}`);

    const css = styles(
      className,
      themes[theme || "inherit"],
      small
        ? {
            fontSize: 11,
            padding: "6px 16px"
          }
        : {},
      {
        width: width
      },
      pretendingToWork ? pretendingToWorkStyles : {}
    );

    if (typeof disabled !== "undefined" && !(`${theme}-disabled` in themes))
      throw new Error(`Disabled Rectangle is not supported for theme ${theme}`);
    if (disabled)
      return (
        <div className={styles(css, (themes as any)[`${theme}-disabled`])}>
          {children}
        </div>
      );

    return pretendingToWork ? (
      <div className={css}>{children}</div>
    ) : (
      <div onClick={this.click} style={{ display: "inline-block" }}>
        <BaseClickable action={action} className={styles(css, activeClassName)}>
          {children}
        </BaseClickable>
      </div>
    );
  }
}
