import { React, styled } from "~lib";
import { Rectangle } from "~lib/Clickable";

const Description = styled("p")({
  color: "#6e6e6e",
  marginBottom: 25
});

const Wrapper = styled("div")({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  marginTop: 80,
  textAlign: "center"
});

const Title = styled("div")({
  fontSize: 28,
  marginBottom: 10
});

export default function Footer() {
  return (
    <Wrapper>
      <Title>Sell your Volusion products on Amazon</Title>
      <Description>
        Quickly and easily list your Volusion products on Amazon Marketplace.
      </Description>
      <Rectangle action="/" theme="gray-on-gray">
        Sell products on Amazon
      </Rectangle>
    </Wrapper>
  );
}
