import DB from "~lib/DB";

export default function clearMyDatabase(db: DB) {
  const proceed = window.confirm(
    "You have activated a development feature. Do you want to clear your database?"
  );
  if (!proceed) return;
  window.alert(
    "Please close this popup and then do not touch anything on the screen until the application reloads."
  );
  db.clearMyDatabase().then(() => {
    window.location.reload();
  });
}
