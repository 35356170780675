import { React } from "~lib";

import { Context, ContextObject } from "./context";
import GuideUI, { baseOuterSize, UIProps } from "./GuideUI";
import { GuideEntry } from "./types";

export type Props = UIProps & {
  autoshowKey?: string;
  positioning?: "absolute" | "inline-block";
};

export default class Guide extends React.Component<Props, {}, Context> {
  static contextType = ContextObject;

  // Hack to type the context field, which is any in
  // current React typings
  context: Context = {} as any;

  private el: HTMLElement | null = null;

  private withGuideEntry = (f: (g: GuideEntry) => void) => {
    const { autoshowKey } = this.props;
    if (this.el)
      f({
        autoshowKey,
        dom: this.el
      });
  };

  private open = () => {
    const { open } = this.context;
    this.withGuideEntry(open);
  };

  private ref = (el: HTMLElement | null) => {
    const { register, unregister } = this.context;

    if (el) {
      this.el = el;
      this.withGuideEntry(register);
      return;
    }

    if (this.el) {
      this.withGuideEntry(unregister);
      this.el = null;
      return;
    }
  };

  private showNext = () => {
    const { showNext } = this.context;
    this.withGuideEntry(showNext);
  };

  render() {
    const { current } = this.context;
    const { positioning } = this.props;

    // This ref is needed in GuideProvider to choose a next tip
    // based on the DOM position
    return (
      <div
        ref={this.ref}
        style={
          positioning === "absolute"
            ? { position: "absolute" }
            : {
                display: "inline-block",
                verticalAlign: "middle",
                // Let the guide base wrap to the next line
                width: baseOuterSize
              }
        }
      >
        <GuideUI
          {...this.props}
          {...this.context}
          isOpen={Boolean(current && current.dom === this.el)}
          open={this.open}
          showNext={this.showNext}
        />
      </div>
    );
  }
}
