import { React, styled } from "~lib";

import Card from "~lib/Cards";
import { ImagePlaceholder, TextPlaceholder } from "~lib/ContentPlaceholders";

const PlaceholderWrapper = styled("div")({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  padding: "22px 0 10px 0",
  width: "100%"
});

type Props = {
  height: string | number;
  width: string | number;
};

export default function VertcalPlaceholder(props: Props) {
  const { height, width } = props;
  return (
    <Card height={height} width={width}>
      <PlaceholderWrapper>
        <ImagePlaceholder height={195} width={195} />
        <div style={{ alignSelf: "stretch" }}>
          <TextPlaceholder />
          <TextPlaceholder width={59} />
        </div>
      </PlaceholderWrapper>
    </Card>
  );
}
