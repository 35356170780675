import ReactImageFallback from "react-image-fallback";

import { React, styles, StylesProp } from "~lib";

const imgStyles = styles({
  maxHeight: "100%",
  maxWidth: "100%"
});

export type Props = {
  css?: StylesProp;
  fallbackImage?: string;
  image?: string;
};

export default class ProductImage extends React.Component<Props> {
  render() {
    const { css, fallbackImage, image } = this.props;
    const className = styles(css);

    const images = [image, fallbackImage].filter(i => i) as string[];
    const first = images[0];
    const fallbacks = (images.slice(1) as React.ReactNode[]).concat([
      <span className={className} />
    ]);

    return (
      <React.Fragment>
        {first ? (
          <ReactImageFallback
            alt=""
            className={imgStyles + " " + className}
            fallbackImage={fallbacks}
            src={first}
          />
        ) : null /* Can not shortcut: if first is undefined React complains */}
      </React.Fragment>
    );
  }
}
