import { React, styled } from "~lib";

const sidePadding = 12;

const cellPadding = {
  "&:first-child": {
    paddingLeft: sidePadding
  },
  "&:last-child": {
    paddingRight: sidePadding
  }
};

const Table = styled("table")({
  backgroundColor: "white",
  borderCollapse: "collapse",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.18)",
  width: "100%"
});

const HeadingRow = styled("tr")({
  backgroundColor: "#F2F2F2"
});

const Th = styled("th")({
  color: "#6F6F6F",
  fontSize: 11,
  fontWeight: "inherit",
  letterSpacing: 1.5,
  padding: "12px 0",
  textAlign: "left",
  textTransform: "uppercase",
  ...cellPadding
});

const Row = styled("tr")({
  "&:nth-child(even)": {
    backgroundColor: "#F7F7F7"
  }
});

const Td = styled("td")({
  fontSize: 14,
  padding: "12px 0",
  ...cellPadding
});

type Props<Columns extends string> = {
  headers: Columns[];
  rows: { [c in Columns]: React.ReactNode }[];
  widths?: number[];
};

export default class SimpleTable<
  Columns extends string
> extends React.Component<Props<Columns>> {
  render() {
    const { headers, rows, widths } = this.props;
    return (
      <Table>
        <thead>
          <HeadingRow>
            {headers.map((h, i) => (
              <Th
                key={h}
                style={{
                  width: widths ? `${widths[i]}%` : undefined
                }}
              >
                {h}
              </Th>
            ))}
          </HeadingRow>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <Row key={i}>
              {headers.map(h => (
                <Td key={h}>{row[h]}</Td>
              ))}
            </Row>
          ))}
        </tbody>
      </Table>
    );
  }
}
