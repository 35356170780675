import { React, styled } from "~lib";
import { get } from "~lib/ajax";
import backendUrl from "~lib/backendUrl";
import { ClickableText, CTA, Rectangle } from "~lib/Clickable";
import DB, { addPretendAmazon, isPretendAmazon } from "~lib/DB";
import Environment from "~lib/Environment";
import amazonLogo from "~lib/Images/amazon-logo.svg";
import Modal from "~lib/Modal";
import Tutorial from "~lib/Tutorial";
import { AmazonRegistrationUrlResponse } from "~root-lib/AjaxTypes";

const compactView = "@media screen and (max-width: 400px)";

const Container = styled("div")({
  padding: "45px 40px 50px",
  textAlign: "center",
  [compactView]: {
    padding: "25px 20px 28px"
  }
});

const Heading = styled("h1")({
  color: "black",
  fontSize: 28,
  marginBottom: 48,
  [compactView]: {
    marginBottom: 28
  }
});

const Logo = styled("div")({
  margin: "auto",
  marginBottom: 37,
  width: 122,
  [compactView]: {
    marginBottom: 20,
    width: 76
  }
});

const Spacer = styled("div")({
  height: 20
});

type Props = {
  db: DB;
  needReal?: boolean;
  // The popup UI clearly signifies the user can dismiss it.
  // Please implement a meaningful dismissal.
  onCancel: () => void;
  onSuccess?: string | (() => void);
};

type State = {
  registrationUrl: string | null;
};

export default class AmazonConnectPopup extends React.Component<Props, State> {
  state: State = {
    registrationUrl: null
  };

  componentDidMount = () => {
    this.getUrl()
      .then(url => this.setState({ registrationUrl: url }))
      .catch(err =>
        this.setState(() => {
          throw err;
        })
      );
  };

  private pretend = async (data: "with-data" | "no-data") => {
    const { db, onSuccess } = this.props;
    await addPretendAmazon(db, data);

    if (typeof onSuccess === "function") onSuccess();
    // Hack, but pretending is a dev tool, so if it fails, no big deal
    if (typeof onSuccess === "string") window.location.hash = onSuccess;
  };

  private getUrl = async () => {
    const db = this.props.db;
    const volusionAuth = db.volusionAuth();
    if (volusionAuth === "unconnected") return null;
    const query = { tenant: volusionAuth.tenant, token: volusionAuth.token };
    const body = await get<AmazonRegistrationUrlResponse>(
      backendUrl("/amazon/registration", query)
    );
    return body.registrationUrl;
  };

  render() {
    const { db, needReal, onCancel } = this.props;
    const { registrationUrl } = this.state;

    if (
      !(db.amazonAuth() === "unconnected" || (needReal && !isPretendAmazon(db)))
    )
      return null;

    return (
      <Modal
        border={true}
        maxWidth={830}
        isOpen
        onRequestClose={onCancel || (() => {})}
      >
        <Container>
          <Logo>
            <img src={amazonLogo} alt="Amazon logo" />
          </Logo>
          <Heading>Start selling on Amazon instantly!</Heading>
          <Tutorial />
          <CTA
            action={{
              newWindow: true,
              url: registrationUrl || ""
            }}
            disabled={!registrationUrl}
            width={300}
          >
            Connect my account
          </CTA>
          <Spacer />
          <Rectangle
            action={{
              newWindow: true,
              url: "https://services.amazon.com/content/sell-on-amazon.html"
            }}
            width={300}
            theme="gray-on-white"
          >
            Create Amazon Seller account
          </Rectangle>
          {!needReal && (
            <Environment hide="production">
              <p
                style={{
                  display: "flex",
                  marginTop: 20
                }}
              >
                <ClickableText action={() => this.pretend("no-data")}>
                  👷 Pretend I just did
                </ClickableText>
                {" • "}
                <ClickableText action={() => this.pretend("with-data")}>
                  👷 Pretend I did before and my data is loaded
                </ClickableText>
              </p>
            </Environment>
          )}
        </Container>
      </Modal>
    );
  }
}
