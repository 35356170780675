import { React, styled } from "~lib";
import { ProductCard } from "~lib/Cards";
import { ClickableText, CTA, Rectangle } from "~lib/Clickable";
import FlexLastRowAlignHack from "~lib/FlexLastRowAlignHack";
import Guide from "~lib/Guide";
import { cardGridGap } from "~lib/Templates/styles";
import { url } from "~lib/URL";
import { fromCents } from "~root-lib/Money";

import { Result } from "../search";

import Failed from "./Failed";
import { compactView, regularView } from "./styles";

const Products = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  marginBottom: -cardGridGap,
  marginLeft: -cardGridGap,
  textAlign: "initial"
});

const NoProductFound = styled("div")({
  textAlign: "center"
});
const NoProductFoundTitle = styled("div")({
  fontSize: 28,
  marginBottom: 10,
  marginTop: 65
});
const NoProductFoundSubtitle = styled("div")({
  color: "gray",
  marginBottom: 35
});

const CardContainer = styled("div")({
  marginBottom: cardGridGap,
  marginLeft: cardGridGap,
  [compactView]: { width: "100%" }
});

const CompactCard = styled("div")({
  [regularView]: { display: "none" }
});

const RegularCard = styled("div")({
  [compactView]: { display: "none" }
});

const cardWidth = 311;

const AddNewProductGuide = () => (
  <Guide title="Adding A New Product" nudgeX={15}>
    If you can’t find your product, it’s possible that no one sells it yet on
    Amazon. Click “add a new product to Amazon” and we’ll help you create a new
    listing from scratch using all your Volusion product info.
  </Guide>
);

type Props = {
  onSearchAgain: () => void;
  productId: string;
  results: "empty" | "loading" | "failed" | Result;
  searchFieldValue: string;
};

export default function SearchResults(props: Props) {
  const { onSearchAgain, productId, results, searchFieldValue } = props;
  if (results === "empty") return null;
  if (results === "loading")
    return (
      <div>
        Searching for {searchFieldValue}
        ...
      </div>
    );
  if (results === "failed") return <Failed onSearchAgain={onSearchAgain} />;
  return (
    <div>
      <div style={{ marginBottom: 20 }}>Search results:</div>
      <Products>
        {(results.moreResultsUrl
          ? results.items.slice(0, 9)
          : results.items
        ).map(result => {
          const cardProps = {
            action: url(["sell", productId, result.asin], {
              cameFromSearchFor: searchFieldValue
            }),
            customFooter: (
              <ClickableText action={{ newWindow: true, url: result.url }}>
                View on Amazon
              </ClickableText>
            ),
            product: {
              fallbackImage: result.images.official.url,
              image: result.images.unofficial_mightBeBroken.url,
              price:
                result.priceInCents === "no-offers"
                  ? undefined
                  : fromCents(result.priceInCents),
              title: result.title
            }
          };
          return (
            <CardContainer key={result.asin}>
              <RegularCard>
                <ProductCard {...cardProps} height={379} width={cardWidth} />
              </RegularCard>
              <CompactCard>
                <ProductCard
                  {...cardProps}
                  height={146}
                  horizontal
                  width="100%"
                />
              </CompactCard>
            </CardContainer>
          );
        })}
        <FlexLastRowAlignHack
          itemCss={{ marginLeft: cardGridGap }}
          itemWidth={cardWidth}
        />
      </Products>
      {results.moreResultsUrl ? (
        <NoProductFound>
          <NoProductFoundTitle>
            Don’t see the product you’re looking for?
          </NoProductFoundTitle>
          <NoProductFoundSubtitle>
            Try searching on Amazon.com, then enter the URL or ASIN in the
            search above.
          </NoProductFoundSubtitle>
          <div>
            <CTA
              action={{
                newWindow: true,
                url: results.moreResultsUrl
              }}
            >
              Take me to Amazon search
            </CTA>
          </div>
          <div style={{ marginTop: 23 }}>
            Or you can{" "}
            <ClickableText
              action={{
                newWindow: true,
                url: "https://sellercentral.amazon.com/hz/productclassify"
              }}
            >
              add a new product to Amazon
            </ClickableText>
            .<AddNewProductGuide />
          </div>
        </NoProductFound>
      ) : (
        <NoProductFound>
          <NoProductFoundTitle>
            Don’t see the product you’re looking for?
          </NoProductFoundTitle>
          <NoProductFoundSubtitle>
            Try refining your search or add a new product to Amazon.
            <AddNewProductGuide />
          </NoProductFoundSubtitle>
          <div>
            <Rectangle
              action={{
                newWindow: true,
                url: "https://sellercentral.amazon.com/hz/productclassify"
              }}
              theme="gray-on-gray"
            >
              Add a new product to Amazon
            </Rectangle>
          </div>
        </NoProductFound>
      )}
    </div>
  );
}
