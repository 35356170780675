import { React, styled } from "~lib";
import BrandedSearch, { createProductFilter } from "~lib/BrandedSearch";
import { ProductCard } from "~lib/Cards";
import DB, { Product } from "~lib/DB";
import FlexLastRowAlignHack from "~lib/FlexLastRowAlignHack";
import Pagination from "~lib/Pagination";
import Dialog from "~lib/Templates/Dialog";
import DialogHeader from "~lib/Templates/Dialog/Header";
import { cardGridGap } from "~lib/Templates/styles";

type Props = {
  db: DB;
  onMatchSelected: (product: Product) => void;
  onRequestClose: () => void;
  orphanVolusionListings: Product[];
};

type State = {
  searchText: string;
  selectedProduct?: Product;
};

const initialState = {
  searchText: "",
  selectedProduct: undefined
};

export default class SelectExistingVolusionListing extends React.PureComponent<
  Props,
  State
> {
  state: State = initialState;

  private handleConfirmSelection = () => {
    if (this.state.selectedProduct)
      this.props.onMatchSelected(this.state.selectedProduct);
  };

  private createSelectProductHandler = (product: Product) => {
    return () => {
      this.setState({ selectedProduct: product });
    };
  };

  private handleDialogClose = () => {
    this.setState(initialState, () => {
      this.props.onRequestClose();
    });
  };

  render() {
    const { searchText } = this.state;
    const products = this.props.orphanVolusionListings.filter(
      createProductFilter(searchText)
    );
    return (
      <Dialog isOpen onRequestClose={this.handleDialogClose}>
        <DialogHeader
          onBackClick={this.handleDialogClose}
          title="Add Match"
          onCTAClick={this.handleConfirmSelection}
          CTAText="Use"
          CTADisabled={this.state.selectedProduct === undefined}
        />
        <BrandedSearch
          onChange={val => this.setState({ searchText: val })}
          value={searchText}
        />
        <VolusionProductList>
          <Pagination
            items={products}
            pageSizes="divisibleBy3"
            renderItem={product => (
              <div key={product.id} style={{ marginBottom: cardGridGap }}>
                <ProductCard
                  action={this.createSelectProductHandler(product)}
                  height={341}
                  activateHover
                  product={product}
                  selected={this.state.selectedProduct === product}
                  width={280}
                />
              </div>
            )}
          />
          <FlexLastRowAlignHack
            itemCss={{ marginLeft: cardGridGap }}
            itemWidth={280}
          />
        </VolusionProductList>
      </Dialog>
    );
  }
}

const VolusionProductList = styled("div")({
  display: "flex",
  flexGrow: 1,
  flexWrap: "wrap",
  justifyContent: "center",
  margin: "0 -19px",
  overflowY: "auto",
  padding: "0 19px",
  textAlign: "initial",
  // The following arbitrary width is roughly when
  // the screen fits 3 columns of products
  ["@media screen and (min-width: 919px)"]: {
    justifyContent: "space-between"
  }
});
