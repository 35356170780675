import { get } from "~lib/ajax";
import { isPretendAmazon, Product } from "~lib/DB";
import backendUrl from "~lib/backendUrl";
import amazonLogo from "~lib/Images/amazon-logo.svg";
import { AmazonGetProductResponse } from "~root-lib/AjaxTypes";
import { AmazonAuth } from "~root-lib/DB";
import { fromCents, fromDollarsAndCents } from "~root-lib/Money";

import computeVariants from "./computeVariants";
import createMockVariants from "./createMockVariants";
import { FormDetails } from "./types";

type Params = {
  asin: string;
  auth: Exclude<AmazonAuth, "unconnected">;
  volusionProduct: Product;
};

export default async function fetchDetails(
  params: Params
): Promise<FormDetails> {
  const { asin, auth, volusionProduct } = params;

  if (isPretendAmazon(auth)) {
    return {
      competingOffers: [],
      chosenCategories: ["vehicles"],
      images: {
        official: {
          height: 61,
          url: amazonLogo,
          width: 61
        }
      },
      title: "Bicycle",
      variants: {
        amazon: createMockVariants({
          color: ["blue", "red"],
          size: ["big", "medium", "small"],
          shape: ["triangle", "circle", "rectangle"]
        }),
        volusion: createMockVariants({
          color: ["Fuchsia", "Sunlight"],
          size: ["XL", "M", "S"]
        })
      },
      volusionInventory: 25,
      volusionPrice: fromDollarsAndCents([200, 3])
    };
  }

  const p = await get<AmazonGetProductResponse>(
    backendUrl("/amazon/product", {
      asin,
      sellerAuthToken: auth.mwsToken,
      sellerId: auth.sellerId
    })
  );

  return {
    competingOffers: p.competitivePrices.map((i: any) => ({
      condition: i.condition,
      price: fromCents(i.priceInCents)
    })),
    chosenCategories: p.categories.map((cat: any) => cat.name),
    images: p.images,
    volusionInventory: volusionProduct.quantity,
    volusionPrice:
      volusionProduct.price === "only-variants"
        ? undefined
        : fromDollarsAndCents(volusionProduct.price),
    title: p.title,
    variants: computeVariants(p, volusionProduct)
  };
}
