import { React, styled } from "~lib";

import Bullets from "./Bullets";
import Category from "./Category";
import ConditionAndPrice, { ValidatePrice } from "./ConditionAndPrice";
import Images from "./Images";

import Header from "./Header";
import Inventory from "./Inventory";
import Line from "./Line";
import { wide } from "./styles";
import { sidePadding } from "./styles";
import { ProductInEdit } from "./types";
import Variants from "./Variants";

export { height as headerHeight } from "./Header";
export { parseFields } from "./fieldNames";

const ContentContainer = styled("div")({
  backgroundColor: "white",
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.18)",
  fontSize: 14,
  padding: `20px ${sidePadding}px`
});

const ConditionAndPriceColumnWrapper = styled("div")({
  [wide]: {
    alignItems: "start",
    display: "flex"
  }
});
const ImageColumnWrapper = styled("div")({
  [wide]: {
    display: "flex",
    "& > :first-child": { flex: 1 },
    "& > :last-child": { flex: 3 }
  }
});
const InformationWrapper = styled("div")({
  [wide]: {
    marginLeft: 50,
    maxWidth: 600
  }
});

const TitleAndBullets = styled("div")({
  [wide]: {
    flex: 1,
    marginRight: 50
  }
});

const Title = styled("h2")({
  fontSize: 18
});

type Props = {
  product: ProductInEdit;
  validatePrice?: ValidatePrice;
};

export default class AmazonProductPageLookalike extends React.Component<Props> {
  render() {
    const { product, validatePrice } = this.props;

    return (
      <>
        <Header />
        <ContentContainer>
          <Category chosenCategories={product.chosenCategories} />
          <ImageColumnWrapper>
            <Images
              image={
                product.images &&
                product.images.official &&
                product.images.official.url
              }
              fallbackImage={
                product.images &&
                product.images.unofficial_mightBeBroken &&
                product.images.unofficial_mightBeBroken.url
              }
            />
            <InformationWrapper>
              <ConditionAndPriceColumnWrapper>
                <TitleAndBullets>
                  <Title>{product.title}</Title>
                  <Line />
                  <Bullets />
                </TitleAndBullets>
                <ConditionAndPrice
                  product={product}
                  showPrice={!product.variants}
                  validatePrice={validatePrice}
                />
              </ConditionAndPriceColumnWrapper>
              <Variants variants={product.variants} />
              {!product.variants && (
                <Inventory defaultValue={product.volusionInventory} />
              )}
            </InformationWrapper>
          </ImageColumnWrapper>
        </ContentContainer>
      </>
    );
  }
}
