import { React, styles, StylesProp } from "~lib";

import Clickable, { Action } from "~lib/Clickable";

type Props = {
  action?: Action;
  children?: React.ReactNode;
  css?: StylesProp;
  height?: number | string;
  horizontal?: boolean;
  padding?: number | string;
  raisedShadow?: boolean;
  width?: number | string;
};

const baseStyles = styles({
  background: "white",
  boxSizing: "border-box",
  borderRadius: 2,
  display: "flex",
  flexDirection: "column",
  padding: 25
});

const horizontalStyles = styles({
  alignItems: "center",
  flexDirection: "row"
});

export default function Card(props: Props) {
  const {
    action,
    children,
    css,
    height,
    horizontal,
    padding,
    raisedShadow,
    width
  } = props;
  const className = styles(
    baseStyles,
    horizontal ? horizontalStyles : undefined,
    css,
    {
      boxShadow: raisedShadow
        ? "0px 5px 19px rgba(0, 0, 0, 0.2)"
        : "0px 2px 5px rgba(0, 0, 0, 0.18)",
      height,
      padding,
      width
    }
  );
  if (action)
    return (
      <Clickable action={action} className={className}>
        {children}
      </Clickable>
    );
  return <div className={className}>{children}</div>;
}
