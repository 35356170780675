import { hot } from "react-hot-loader";
import { React } from "~lib";
import AppStyles from "~lib/AppStyles";
import DB, { createDb } from "~lib/DB";
import reportError from "~lib/reportError";
import { GuideProviderWithDb } from "~lib/Guide";
import { InternalError } from "~lib/Templates";
import {
  Stack as ToastStack,
  addCustomToast,
  SmallDarkToast
} from "~lib/Toasts";

import Home from "./Home";
import Import from "./Import";
import Routes from "./Routes";
import SellExisting from "./SellExisting";
import SellSearch from "./SellSearch";

function networkToasts(db: DB) {
  let active = null as null | { dismiss: () => void };
  db.on("network-problems", () => {
    if (active) return;
    active = {
      dismiss: addCustomToast(
        <SmallDarkToast>
          <strong>Not connected.</strong> Reconnecting...
        </SmallDarkToast>
      )
    };
  });
  db.on("network-happy", () => {
    if (active) active.dismiss();
  });
}

class App extends React.Component {
  state = {
    db: null as null | DB,
    error: null as null | Error
  };

  componentDidCatch(error: Error, info?: unknown) {
    this.setState({ error });
    reportError(error, info);
  }

  componentDidMount() {
    createDb()
      .then(db => {
        networkToasts(db);
        this.setState({ db });
        db.on("error", err => this.componentDidCatch(err));
      })
      .catch(err => this.componentDidCatch(err));

    window.addEventListener("beforeunload", this.handleBeforeUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  }

  private handleBeforeUnload = (e: BeforeUnloadEvent) => {
    const { db } = this.state;
    if (db && db.updatesPendingReplicationExist()) {
      e.preventDefault();
      e.returnValue = "Some change have not yet been saved";
    }
  };

  render() {
    const { db, error } = this.state;

    if (error)
      return (
        <AppStyles>
          <InternalError error={error} />
        </AppStyles>
      );
    if (!db) return null;

    return (
      <AppStyles>
        <ToastStack />
        <GuideProviderWithDb db={db}>
          <Routes
            db={db}
            routes={route => [
              route("/import", Import),
              route("/", Home, {
                params: { add: "search", amazonProducts: "search" }
              }),
              route("/sell/:productId", SellSearch, {
                params: { productId: "path", startSearchWith: "search" }
              }),
              route("/sell/:productId/:asin", SellExisting, {
                params: {
                  asin: "path",
                  cameFromSearchFor: "search",
                  productId: "path"
                }
              })
            ]}
          />
        </GuideProviderWithDb>
      </AppStyles>
    );
  }
}

export default hot(module)(App);
