import { AmazonGetProductResponse } from "~root-lib/AjaxTypes";
import { Product, Variants } from "~lib/DB";

import { FormDetails } from "./types";

function soloVariant<T extends object>(obj: T): Variants<T> {
  return { groups: {}, rows: [{ ...(obj as any), groups: {} }] };
}

export default function computeVariants(
  amazonProduct: AmazonGetProductResponse,
  volusionProduct: Product
): FormDetails["variants"] {
  // No variants on either side, adding one product to one product
  if (!amazonProduct.variants && !volusionProduct.variants) return undefined;

  // If one of the side has variants and the other does not,
  // we show a column with one pseudo-variant, the parent,
  // for the user to be able to select which variant on the other
  // side matches this parent.

  const amazon = amazonProduct.variants || soloVariant(amazonProduct);
  const volusion =
    volusionProduct.variants ||
    soloVariant({
      id: volusionProduct.id,
      price:
        volusionProduct.price === "only-variants"
          ? (() => {
              throw new Error("price is only-variants, but no variants?");
            })()
          : volusionProduct.price,
      quantity: volusionProduct.quantity || 0,
      sku: volusionProduct.sku
    });

  return { amazon, volusion };
}
