import { VolusionGetProductsResponse } from "~root-lib/AjaxTypes";
import { Product, VolusionAuth } from "~lib/DB";

import backendUrl from "~lib/backendUrl";

function floatToDollarsAndCents(dollars: number): [number, number] {
  const usd = Math.floor(dollars);
  const cents = Math.round((dollars - usd) * 100);
  return [usd, cents];
}

export default async function fetchInitialVolusionData(
  volusionAuth: Exclude<VolusionAuth, "unconnected">
): Promise<Product[]> {
  // We want to minimize the amount of manual data fetching in the app.
  // The way we implement this might change much in the future.
  // We might decide to perform all data fetching server-side
  // and sync through the PouchDB-CouchDB link.
  // For now, though, simple AJAX.
  const res = await fetch(backendUrl("/volusion/products", volusionAuth));
  if (res.status === 401 || res.status === 403) {
    const err = new Error("Invalid Volusion credentials");
    (err as any).badCredentials = true;
    throw err;
  }
  if (res.status < 200 || 300 <= res.status)
    throw new Error("Volusion responded " + res.status);
  const body: VolusionGetProductsResponse = await res.json();
  return body.items.map(
    (item): Product => {
      const variants = item.productVariants;
      const base = {
        id: item.id,
        image: item.images.length
          ? item.images[0].imageLink.fullUri
          : undefined,
        title: item.name,
        sku: item.sku
      };
      if (variants.length === 1) {
        const firstVariant = variants[0];
        return {
          ...base,
          price: floatToDollarsAndCents(item.pricing.listPrice),
          quantity:
            firstVariant &&
            (firstVariant.isInventoryTracked
              ? firstVariant.quantity
              : undefined)
        };
      }
      const groups: { [k: string]: string[] } = {};
      item.variantOptions.forEach(({ name, options }) => {
        groups[name] = options;
      });
      return {
        ...base,
        price: "only-variants",
        variants: {
          groups,
          rows: item.productVariants.map(v => {
            const variantGroups: {
              [k: string]: string;
            } = {};
            v.variants.forEach((option, i) => {
              const group = item.variantOptions[i];
              variantGroups[group.name] = option;
            });
            return {
              groups: variantGroups,
              id: v.id,
              price: floatToDollarsAndCents(v.price),
              quantity: v.quantity,
              sku: v.sku
            };
          })
        }
      };
    }
  );
}
