import { React } from "~lib";

import compute from "./compute";
import { PossibleEnvironment } from "./types";

type EnvironmentProps =
  | { show: PossibleEnvironment | PossibleEnvironment[] }
  | { hide: PossibleEnvironment | PossibleEnvironment[] };

type Props = EnvironmentProps & {
  children: React.ReactNode;
};

type State = {
  currentEnvironment: PossibleEnvironment | "unknown";
};

export default class Environment extends React.Component<Props, State> {
  state: State = {
    currentEnvironment: "unknown"
  };

  componentDidMount() {
    this.setState({ currentEnvironment: compute(window) });
  }

  render() {
    const { children } = this.props;
    const { currentEnvironment } = this.state;
    const hide =
      "hide" in this.props &&
      this.props.hide &&
      (typeof this.props.hide === "string"
        ? [this.props.hide]
        : this.props.hide);
    const show =
      "show" in this.props &&
      this.props.show &&
      (typeof this.props.show === "string"
        ? [this.props.show]
        : this.props.show);
    if (currentEnvironment === "unknown") return null;
    if (show && show.indexOf(currentEnvironment) === -1) return null;
    if (hide && hide.indexOf(currentEnvironment) > -1) return null;
    return children;
  }
}
