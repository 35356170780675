/**
 * To use the Cross, please set position: relative on the field,
 * or otherwise give it a new absolute positioning context.
 *
 * Also use the fieldPaddingRight function in this file to set
 * the right padding on the field.
 */

import { React } from "~lib";
import Clickable, { Action } from "~lib/Clickable";
import xIcon from "~lib/Images/X.svg";

const paddingLeft = 15;
const width = 14;

function paddingRight(height: number) {
  return height > 80 ? 35 : 20;
}

export function fieldPaddingRight(height: number) {
  return paddingLeft + width + paddingRight(height);
}

type Props = {
  action: Action;
  fieldHeight: number;
};

export default function Cross(props: Props) {
  const { action, fieldHeight } = props;

  return (
    <Clickable
      action={action}
      style={{
        alignItems: "center",
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        paddingLeft: paddingLeft,
        paddingRight: paddingRight(fieldHeight),
        position: "absolute",
        right: 0,
        top: 0,
        width: width
      }}
    >
      <img src={xIcon} alt="X" height={14} width={14} />
    </Clickable>
  );
}
