import { React, styles } from "~lib";

import BaseClickable, { Action } from "./BaseClickable";

type Props = {
  action: Action;
  children: string;
};

export const className = styles({
  color: "hsl(36, 100%, 50%)"
});

const stylesIfCurrent = styles({
  color: "inherit"
});

export default function ClickableText(props: Props) {
  const { action, children } = props;
  return (
    <BaseClickable
      action={action}
      className={className}
      classNameIfCurrent={stylesIfCurrent}
    >
      {children}
    </BaseClickable>
  );
}
