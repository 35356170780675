export type PouchDBType = {
  get: Function;
  post: Function;
  put: Function;
  query?: Function;
  remove: Function;
  sync: Function;
  upsert?: Function;
};

export type ProductMatch = {
  amazonListing: AmazonListing;
  volusionListing?: Product;
  userApprovedPairing?: boolean;
};

export type AmazonAuth = "unconnected" | { mwsToken: string; sellerId: string };

export type AmazonListing = {
  asin: string;
  condition?: string;
  images: {
    official?: {
      height: number;
      width: number;
      url: string;
    };
    unofficial_mightBeBroken?: {
      height: number;
      width: number;
      url: string;
    };
  };
  // undefined means "unknown", beware of boolean cast
  isActive?: "only-variants" | boolean;
  price?: "only-variants" | [number, number];
  quantity: number;
  sku?: string;
  submissionStatus?: "submitting" | "submit-failed" | "submit-succeeded";
  title: string;
  variants?: AmazonListingVariants;
  volusionProductId?: string;
};

export type AmazonListingVariants = Variants<{
  asin: string;
  images?: {
    official?: {
      height: number;
      width: number;
      url: string;
    };
    unofficial_mightBeBroken?: {
      height: number;
      width: number;
      url: string;
    };
  };
  price?: [number, number];
  sku?: string;
  volusionVariantId?: string;
  quantity?: number;
  title?: string;
  submissionStatus?: "submitting" | "submit-failed" | "submit-succeeded";
  // undefined means "unknown", beware of boolean cast
  isActive?: boolean;
}>;

export type Product = {
  id: string;
  image?: string;
  price: "only-variants" | [number, number];
  // Undefined quantity means quantity is not tracked, which is different from 0
  quantity?: number;
  title: string;
  sku?: string;
  variants?: ProductVariants;
};

export type ProductVariants = Variants<{
  id: string;
  price: [number, number];
  quantity?: number;
  sku?: string;
}>;

export type Variants<ExtraFields = {}> = {
  // Describe the possible categories of variants
  // { color: ['red', 'blue', 'green'], size: ['big', 'small'] }
  groups: { [name: string]: string[] };
  // All individual variants, so roughly the cartesian product of
  // all arrays in the categories description above
  rows: (ExtraFields & {
    // Describe the specific characteristics of this variant
    // { color: 'red', size: 'big' }
    // It is possible that a key or a value is missing in the categories description above
    groups: { [name: string]: string };
  })[];
};

export type VolusionAuth = "unconnected" | { tenant: string; token: string };

export const getInitialData = () => ({
  amazonAuth: "unconnected" as AmazonAuth,
  amazonListings: "unsynced" as "unsynced" | AmazonListing[],
  guideStorage: [] as string[],
  products: "unsynced" as "unsynced" | Product[],
  volusionAuth: "unconnected" as VolusionAuth
});

export const ourKeys = Object.keys(getInitialData()) as ValueKey[];

export type Values = ReturnType<typeof getInitialData>;

export type ValueKey = keyof Values;

export type Doc<Key extends ValueKey> = {
  _id: Key;
  _rev: string;
  value: Values[Key];
};
