import DB, { ProductMatch } from "~lib/DB";

export default function getProductMatches(db: DB): "unsynced" | ProductMatch[] {
  const amazonListings = db.amazonListings();
  const products = db.products();
  if (amazonListings === "unsynced" || products === "unsynced")
    return "unsynced";

  const unmatchedAmazonParents = amazonListings
    .filter(l => !l.volusionProductId)
    // We can not import standalone products to Volusion if they don’t have a price,
    // so for now we’ll hide them off the screen
    .filter(l => l.price !== undefined);

  return unmatchedAmazonParents.map(function(amazonListing) {
    // Automated matching disabled, so we match every product with nothing
    return {
      amazonListing: amazonListing,
      volusionListing: undefined
    };
  });
}
