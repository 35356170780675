import { render } from "react-dom";
import { React } from "~lib";
import { initModal } from "~lib/Modal";
import removePointerOutlines from "~lib/RemovePointerOutlines";

import App from "./App";

document.addEventListener("DOMContentLoaded", () => {
  const root = document.createElement("div");
  document.body.appendChild(root);
  initModal(root);
  render(<App />, root);
});

removePointerOutlines(document);
