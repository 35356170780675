import { React, styled } from "~lib";

import Card from "~lib/Cards";
import { ImagePlaceholder, TextPlaceholder } from "~lib/ContentPlaceholders";

const PlaceholderWrapper = styled("div")({
  alignItems: "center",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "row",
  height: "100%",
  justifyContent: "center",
  width: "100%"
});

type Props = {
  height: string | number;
  width: string | number;
};

export default function HorizontalPlaceholder(props: Props) {
  const { height, width } = props;
  return (
    <Card height={height} width={width}>
      <PlaceholderWrapper>
        <ImagePlaceholder height={90} width={90} />
        <div style={{ marginLeft: "10%", width: "50%" }}>
          <TextPlaceholder />
          <TextPlaceholder width={50} />
        </div>
      </PlaceholderWrapper>
    </Card>
  );
}
