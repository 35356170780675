import { React, styled } from "~lib";

const Rectangle = styled("div")({
  backgroundColor: "#EDEDED",
  borderRadius: 4
});

type Props = {
  height: string | number;
  margin?: string | number;
  width: string | number;
};

export default function ImagePlaceholder(props: Props) {
  const { height, margin, width } = props;
  return (
    <Rectangle
      style={{
        height,
        margin,
        width
      }}
    />
  );
}
