import { Variants } from "~lib/DB";
import { ProductInEdit } from "~lib/AmazonProductPageLookalike/types";

// This is a dev mock not to be used in production
// https://stackoverflow.com/a/43053803
const f = (a: any, b: any) =>
  [].concat(...a.map((a: any) => b.map((b: any) => [].concat(a, b))));
const cartesian = (a?: any, b?: any, ...c: any[]): any =>
  b ? cartesian(f(a, b), ...c) : a;

// This is a dev mock not to be used in production
// https://stackoverflow.com/a/19303725
function poorRandom(seed: number, max: number) {
  const a = Math.sin(seed) * 10000;
  return Math.floor(max * (a - Math.floor(a)));
}

export default function createMockVariants(
  groups: Variants["groups"]
): NonNullable<ProductInEdit["variants"]>["amazon"] &
  NonNullable<ProductInEdit["variants"]>["volusion"] {
  const rowValues: { k: string; v: string }[][] = cartesian(
    ...Object.keys(groups).map(k => groups[k].map(v => ({ k, v })))
  );
  const seed = poorRandom(rowValues.length, 1000);
  return {
    groups,
    rows: rowValues.map((gs, i) => {
      const res = {
        asin: "a" + (seed + i),
        groups: {} as any,
        id: "a" + (seed + i),
        images: {},
        price: [poorRandom(i + seed + 93, 10000), 0] as [number, number],
        priceInCents: poorRandom(i + seed + 93, 10000) * 100,
        sku: "a" + (seed + i),
        title: "a" + (seed + i),
        quantity:
          poorRandom(i + seed, 13) === 0
            ? undefined
            : poorRandom(i + seed + 37, 100)
      };
      gs.forEach(({ k, v }: any) => {
        res.groups[k] = v;
      });
      return res;
    })
  };
}
