import { React, styled, styles, StylesProp } from "~lib";

type Props = {
  children: React.ReactNode;
  css?: StylesProp;
  width?: string | number;
};

const Outer = styled("div")({
  alignItems: "center",
  background: "#232F3E",
  color: "white",
  display: "flex",
  justifyContent: "center",
  minHeight: "100vh"
});

const Inner = styled("div")({
  maxWidth: 400
});

export default function CenterMessage(props: Props) {
  const { children, css, width } = props;
  return (
    <Outer>
      <Inner
        className={styles(
          {
            maxWidth: width
          },
          css
        )}
      >
        {children}
      </Inner>
    </Outer>
  );
}
