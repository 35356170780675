import { React, styles, StylesProp } from "~lib";

type Props = {
  itemCss?: StylesProp;
  itemWidth: number;
};

export default function FlexLastRowAlignHack(props: Props) {
  const { itemCss, itemWidth } = props;
  const className = styles(
    {
      // On small screens the hack is unnecessary,
      // and it causes iOS Safari to add horizontal scroll
      [`@media (max-width: ${2 * itemWidth - 1}px)`]: {
        display: "none"
      }
    },
    itemCss
  );
  return (
    <React.Fragment>
      <div className={className} style={{ height: 0, width: itemWidth }} />
      <div className={className} style={{ height: 0, width: itemWidth }} />
      <div className={className} style={{ height: 0, width: itemWidth }} />
      <div className={className} style={{ height: 0, width: itemWidth }} />
      <div className={className} style={{ height: 0, width: itemWidth }} />
      <div className={className} style={{ height: 0, width: itemWidth }} />
      <div className={className} style={{ height: 0, width: itemWidth }} />
      <div className={className} style={{ height: 0, width: itemWidth }} />
      <div className={className} style={{ height: 0, width: itemWidth }} />
      <div className={className} style={{ height: 0, width: itemWidth }} />
    </React.Fragment>
  );
}
