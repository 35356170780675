export function elementOrAncestorsAre(
  el: Element,
  predicate: (el: Element) => boolean
): boolean {
  if (predicate(el)) return true;
  const parent = el.parentElement;
  if (!parent) return false;
  return elementOrAncestorsAre(parent, predicate);
}

export function isElementInteractive(el: Element): boolean {
  return (
    "a,audio,button,input,label,menu,menuitem,option,select,summary,textarea,video"
      .split(",")
      .indexOf(el.tagName.toLowerCase()) > -1
  );
}
