import PouchDBAdapterHttp from "pouchdb-adapter-http";
import PouchDBAdapterMemory from "pouchdb-adapter-memory";
import PouchDB from "pouchdb-core";
import PouchDBReplication from "pouchdb-replication";
import PouchDBUpsert from "pouchdb-upsert";

PouchDB.plugin(PouchDBUpsert);
PouchDB.plugin(PouchDBAdapterHttp);
PouchDB.plugin(PouchDBAdapterMemory);
PouchDB.plugin(PouchDBReplication);

export default PouchDB;

export type PouchDBType = PouchDB.Database;
