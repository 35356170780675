import { React, styled } from "~lib";
import Clickable from "~lib/Clickable";
import Guide, { Props as GuideProps } from "~lib/Guide";

import amazonLogo from "./amazon-logo.png";
import backIcon from "./back.svg";

const nonWide = "@media (max-width: 699)";
const wide = "@media (min-width: 700px)";

export type Props = {
  back?: string;
  guide?: GuideProps;
  subtitle?: React.ReactNode;
  hideLogo?: boolean;
  title: string;
};

const Container = styled("div")({
  label: "header",
  paddingBottom: 40,
  paddingTop: 60,
  [wide]: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between"
  }
});

const Logo = styled("div")({
  [nonWide]: {
    marginBottom: 20
  },
  [wide]: {
    order: 2
  }
});

const Text = styled("div")({});

const BackImg = styled("img")({
  marginRight: 16,
  verticalAlign: 3,
  width: 17
});

const H1 = styled("h1")({
  marginBottom: 15,
  marginTop: 0
});
const Subtitle = styled("div")({
  color: "hsla(0, 0%, 100%, 0.68)",
  p: {
    marginTop: 5
  }
});

export default function Header(props: Props) {
  const { back, guide, hideLogo, subtitle, title } = props;
  return (
    <Container>
      {!hideLogo && (
        <Logo>
          <img src={amazonLogo} />
        </Logo>
      )}
      <Text>
        <H1>
          {back && (
            <Clickable action={back}>
              <BackImg src={backIcon} alt="←" />
            </Clickable>
          )}
          {title}
          {guide && <Guide {...guide} />}
        </H1>
        <Subtitle>{subtitle}</Subtitle>
      </Text>
    </Container>
  );
}
