/**
 * This is our framework to resolve conflicts.
 * Right now the strategy is simply last write wins,
 * but everything is in place to add a smarter strategy in the future.
 *
 * See root-lib/DB conflictless function to prepare docs for
 * this resolution.
 *
 * Some dev aids to work on this:
 *
 * window.db = db;
 *
 * db.bulkDocs({
 *   docs: [
 *     { _id: "sample", _rev: "1-one", time: 1, val: "one" },
 *     { _id: "sample", _rev: "1-three", time: 3, val: "three" },
 *     { _id: "sample", _rev: "1-two", time: 2, val: "two" }
 *   ],
 *   new_edits: false
 * } as any);
 *
 * db.get("sample", { conflicts: true });
 */

import { PouchDBType } from "./pouchdb";

type DocWithTime = {
  _id: string;
  _rev: string;
  time?: number;
};

async function handleConflict(
  pouch: PouchDBType,
  doc: DocWithTime,
  conflictingRevs: string[]
) {
  const { _id } = doc;
  const docs: DocWithTime[] = [doc].concat(
    await Promise.all(conflictingRevs.map(rev => pouch.get(_id, { rev })))
  );
  const docsWithoutTime = docs.filter(doc => !doc.time);
  if (docsWithoutTime.length) {
    console.warn(
      "Found conflicting database documents without timestamp. They will lose any conflict resolution and conflict resolution between timeless documents will be arbitrary. Please use root-lib/DB conflictless function."
    );
    console.warn(docsWithoutTime);
  }
  const lastDoc = docs.reduce(
    (prev, curr) => ((prev.time || 0) < (curr.time || 0) ? curr : prev)
  );
  const losingDocs = docs.filter(d => d._rev !== lastDoc._rev);
  await Promise.all(losingDocs.map(doc => pouch.remove(doc)));
}

export default function handleConflicts(
  pouch: PouchDBType,
  onError: (err: Error) => void
) {
  pouch
    .changes({
      conflicts: true,
      include_docs: true,
      live: true
    })
    .on("change", function(change) {
      if (!change.doc || !change.doc._conflicts) return;
      handleConflict(pouch, change.doc, change.doc._conflicts).catch(onError);
    })
    .on("error", onError);
}
