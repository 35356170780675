/**
 * react-router does not handle the scroll
 * See their docs for “scroll restoration”
 */

import { React } from "~lib";

export default function scrollTop<P>(
  Component: React.ComponentType<P>
): React.ComponentType<P> {
  return class ScrollToTop extends React.Component<P> {
    componentDidMount() {
      window.scrollTo(0, 0);
    }
    render() {
      return <Component {...this.props} />;
    }
  };
}
