import { React, styled } from "~lib";

const Line = styled("div")({
  backgroundColor: "#EDEDED",
  height: "0.9em",
  marginBottom: "0.7em",
  width: "100%"
});

type Props = {
  height?: string | number;
  margin?: string | number;
  width?: string | number;
};

export default function TextPlaceholder(props: Props) {
  const { height, margin, width } = props;
  return (
    <Line
      style={{
        height,
        margin,
        width
      }}
    />
  );
}
