export function nextElement<El extends HTMLElement>(
  current: El,
  elements: El[]
): El | null {
  let result: El | null = null;
  elements.forEach(el => {
    if (!ordered(current, el)) return;
    if (!result || ordered(el, result)) result = el;
  });
  return result;
}

export function ordered(a: HTMLElement, b: HTMLElement): boolean {
  return Boolean(
    a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_FOLLOWING
  );
}

export function scrollIntoView(element: HTMLElement): void {
  const el = element as HTMLElement & {
    scrollIntoViewIfNeeded?: (this: HTMLElement) => void;
  };

  if (el.scrollIntoViewIfNeeded) el.scrollIntoViewIfNeeded();
  else el.scrollIntoView();
}
