import { Product } from "~root-lib/DB";

import DB from "../../types";

import dress from "./product-images/belamor-womens-dress.png";
import multivitamin from "./product-images/mens-daily-multivitamin.png";
import washer from "./product-images/onewash-pressure-washer.png";
import key from "./product-images/safeman-key-home-kit.png";
import earbuds from "./product-images/silipower-bluetooth-earbuds.png";
import shoes from "./product-images/tiosebon-womens-athletic-shoes.png";

const uniqueProducts: Product[] = [
  {
    id: "sample1",
    image: washer,
    price: [294, 60],
    title: "OneWash Pressure Washer",
    sku: "s1"
  },
  {
    id: "sample2",
    image: key,
    price: [219, 0],
    title: "SafeMan Key Home Kit",
    sku: "s2"
  },
  {
    id: "sample3",
    image: shoes,
    price: [19, 0],
    title: "TIOSEBON Women's Athletic Shoes",
    sku: "s3"
  },
  {
    id: "sample4",
    image: dress,
    price: [15, 95],
    title:
      "BELAMOR Women's Short & Long Sleeve Casual Boho Loose T-Shirt Dress",
    sku: "s4"
  },
  {
    id: "sample5",
    image: earbuds,
    price: [28, 99],
    title: "Silipower Bluetooth Earbuds",
    sku: "s5"
  },
  {
    id: "sample6",
    image: multivitamin,
    price: [18, 97],
    title: "Men's Daily Multivitamin",
    sku: "s6"
  }
];

export const pretendVolusionProducts: Product[] = uniqueProducts.concat(
  uniqueProducts.map(p => ({
    ...p,
    id: p.id + "-2",
    sku: p.sku + "-2",
    title: p.title + "-2"
  }))
);

export async function addPretendVolusion(db: DB) {
  await db.update("products", () => pretendVolusionProducts);
}
