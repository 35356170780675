import { React, styled } from "~lib";

import amazonTick from "~lib/Images/amazon-tick.svg";

const Container = styled("div")({
  marginBottom: 100,
  marginTop: 56,
  textAlign: "center"
});
const Img = styled("img")({
  display: "block",
  margin: "auto",
  marginBottom: 30
});
const Subtitle = styled("div")({
  color: "#707070",
  fontSize: 14,
  lineHeight: "25px",
  margin: "auto",
  marginBottom: 33,
  maxWidth: 250
});
const Title = styled("div")({
  fontSize: 34,
  fontWeight: 400,
  lineHeight: "49px",
  marginBottom: 13
});

export default class AllAdded extends React.Component {
  render() {
    return (
      <Container>
        <Img src={amazonTick} alt="Amazon logo with a check mark" width={127} />
        <Title>Congrats! All products added.</Title>
        <Subtitle>
          You have successfully added all your Volusion products to Amazon.
        </Subtitle>
      </Container>
    );
  }
}
