import { React } from "~lib";

import Box, { width, UserProps } from "./Box";

export { zIndex } from "./Box";

function constrain(n: number, min: number, max: number) {
  return Math.min(max, Math.max(min, n));
}

function positioningStylesForBase(el: HTMLElement): React.CSSProperties {
  const { innerWidth } = window;
  const base = el.getBoundingClientRect();
  const baseX = constrain(base.left + base.width / 2, 0, innerWidth);

  return {
    marginLeft: -constrain(width - innerWidth + baseX, 0, width),
    position: "absolute",
    zIndex: 100
  };
}

type Props = UserProps & {
  base: (ref: React.Ref<any>) => React.ReactNode;
  isOpen: boolean;
};

export default class Tooltip extends React.Component<Props> {
  private baseRef: HTMLElement | null = null;
  private setBaseRef = (el: HTMLElement | null) => {
    this.baseRef = el;
  };

  render() {
    const { base, isOpen } = this.props;
    return (
      <React.Fragment>
        {base(this.setBaseRef)}
        {isOpen &&
          this.baseRef && (
            <Box
              {...this.props}
              innerRef={() => {}}
              style={positioningStylesForBase(this.baseRef)}
            />
          )}
      </React.Fragment>
    );
  }
}
