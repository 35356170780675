import { React } from "~lib";

import { elementOrAncestorsAre, isElementInteractive } from "./dom";

export default function createNestedActionHandler(
  nestedActionSelector: string
) {
  return (event: React.MouseEvent<HTMLElement>) => {
    const clickedElement = event.target;
    if (!(clickedElement instanceof Element)) return;

    const nestedElement = event.currentTarget.querySelector(
      nestedActionSelector
    );
    if (!nestedElement || !(nestedElement instanceof HTMLElement)) {
      console.log(
        "querySelector on nestedActionSelector returned",
        nestedElement
      );
      throw new Error(
        "Clickable with nestedActionSelector clicked, but no nested element matching this selector has been found"
      );
    }

    // Clicks on other interactive elements should not be forwarded
    if (elementOrAncestorsAre(clickedElement, isElementInteractive)) return;

    // Clicks on our nestedElement should not be duplicated
    if (elementOrAncestorsAre(clickedElement, t => t === nestedElement)) return;

    nestedElement.click();
  };
}
