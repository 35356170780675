import { React, styled } from "~lib";

const Center = styled("div")({
  display: "flex",
  justifyContent: "center"
});

const Container = styled("div")({
  alignItems: "stretch",
  backgroundColor: "#313131",
  boxShadow: "0px 4px 7px rgba(0, 0, 0, 0.14)",
  borderRadius: 2,
  display: "block",
  margin: "5px auto",
  minHeight: 40
});

const Text = styled("div")({
  color: "white",
  flex: 1,
  fontSize: 12,
  margin: "13px 23px",
  lineHeight: "13px"
});

type Props = {
  children: React.ReactNode;
};

export default class SmallDarkToast extends React.Component<Props> {
  render() {
    const { children } = this.props;
    return (
      <Center>
        <Container>
          <Text>{children}</Text>
        </Container>
      </Center>
    );
  }
}
