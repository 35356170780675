import DB from "./types";

export {
  addPretendAmazon,
  addPretendVolusion,
  isPretendAmazon
} from "./pretend";
export * from "./types";
export default DB;
export { default as createDb } from "./create";
export { default as connect } from "./connect";
