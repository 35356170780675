import { keyframes } from "emotion";
import { React, styles } from "~lib";

import BaseClickable, { Action } from "./BaseClickable";

export const outerSize = 34;
const innerSize = 12;

const containerClassName = styles({
  height: outerSize,
  position: "absolute",
  width: outerSize,
  ":focus": { outline: 0 }
});

const pulse = keyframes`
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1.6);
    opacity: 0;
  }
`;

const outerStyles = styles({
  animation: `${pulse} 3s cubic-bezier(0.19, 1, 0.22, 1) infinite`,
  borderRadius: "50%",
  height: "100%",
  width: "100%",
  [`.${containerClassName}:focus &`]: {
    animation: "none"
  }
});

const outer = {
  green: styles(outerStyles, {
    backgroundColor: "rgba(59, 210, 162, 0.16)"
  }),
  red: styles(outerStyles, {
    backgroundColor: "rgba(226, 68, 47, 0.16)"
  }),
  yellow: styles(outerStyles, {
    backgroundColor: "rgba(255, 153, 0, 0.16)"
  })
};

const innerStyles = styles({
  borderRadius: "50%",
  height: innerSize,
  left: (outerSize - innerSize) / 2,
  position: "absolute",
  top: (outerSize - innerSize) / 2,
  width: innerSize
});

const inner = {
  green: styles(innerStyles, {
    backgroundColor: "#3BD2A2"
  }),
  red: styles(innerStyles, {
    backgroundColor: "#E2442F"
  }),
  yellow: styles(innerStyles, {
    backgroundColor: "#FF9900"
  })
};

type Props = {
  action: Action;
  color: keyof typeof outer;
  nudgeX?: number | string;
  nudgeY?: number | string;
};

function px(n: number | string): string {
  if (typeof n === "string") return n;
  return String(n) + "px";
}

export default function ColorDot(props: Props) {
  const { action, color, nudgeX, nudgeY } = props;
  const baseNudge = px(-(outerSize / 2));
  return (
    <BaseClickable
      action={action}
      className={containerClassName}
      style={{
        marginLeft: `calc(${baseNudge} + (${px(nudgeX || 0)}))`,
        marginTop: `calc(${baseNudge} + ${px(nudgeY || 0)})`
      }}
    >
      <div className={outer[color]} />
      <div className={inner[color]} />
    </BaseClickable>
  );
}
