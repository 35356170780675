import { React, styled } from "~lib";
import Badge from "~lib/BrandBadge";
import { ProductCard } from "~lib/Cards";
import { AmazonListing } from "~lib/DB";
import { fromDollarsAndCents } from "~root-lib/Money";

import { badgeTop, compactView, regularView } from "./styles";

type Props = {
  amazonListing: AmazonListing;
};

const Container = styled("div")({
  zIndex: 2
});

const CompactCard = styled("div")({
  [regularView]: { display: "none" }
});

const RegularCard = styled("div")({
  [compactView]: { display: "none" }
});

function selectImages(listing: AmazonListing): AmazonListing["images"] {
  const candidates: { images: AmazonListing["images"] }[] = [listing].concat(
    listing.variants ? (listing.variants.rows as any[]) : []
  );
  const source =
    candidates.find(c => Boolean(c.images && c.images.official)) || listing;
  return source.images;
}

export default function LeftSide(props: Props) {
  const product = props.amazonListing;
  const images = selectImages(product);
  const productProps = {
    product: {
      fallbackImage: images.official && images.official.url,
      image:
        images.unofficial_mightBeBroken && images.unofficial_mightBeBroken.url,
      price:
        !product.price || product.price === "only-variants"
          ? undefined
          : fromDollarsAndCents(product.price),
      title: product.title
    },
    raisedShadow: true
  };

  return (
    <Container>
      <Badge
        centered
        compactMediaQuery={compactView}
        brand="amazon"
        nudgeY={badgeTop}
      />
      <RegularCard>
        <ProductCard height={271} width={240} {...productProps} />
      </RegularCard>
      <CompactCard>
        <ProductCard
          height={289}
          minWidth={166}
          width={"23vw"}
          {...productProps}
        />
      </CompactCard>
    </Container>
  );
}
