import NanoEvents from "nanoevents";

type CallbackReturn =
  | "finish-immediately"
  | {
      revToWaitFor: string;
    };

type State =
  | {
      step: "collecting-revs";
      revs: string[];
    }
  | {
      step: "waiting-for-rev";
      rev: string;
    };

export default async function ensureRevPropagated(
  events: NanoEvents,
  key: string,
  performAndReturnRev: () => Promise<CallbackReturn>
): Promise<void> {
  return new Promise<void>((resolveUpdate, rejectUpdate) => {
    let state: State = {
      step: "collecting-revs",
      revs: []
    };

    const done = () => {
      resolveUpdate();
      endListening();
    };

    const endListening = events.on("doc-propagated-to-sync-storage", doc => {
      if (doc._id !== key) return;
      if (state.step === "collecting-revs") {
        state.revs.push(doc._rev);
        return;
      }
      if (state.step === "waiting-for-rev" && state.rev === doc._rev)
        return done();
    });

    performAndReturnRev()
      .then(res => {
        if (res === "finish-immediately") return done();
        if (
          state.step === "collecting-revs" &&
          state.revs.includes(res.revToWaitFor)
        )
          return done();
        state = {
          step: "waiting-for-rev",
          rev: res.revToWaitFor
        };
      })
      .catch(rejectUpdate);
  });
}
