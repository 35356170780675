import { React } from "~lib";

import { GuideEntry } from "./types";

export type Context = {
  close: () => void;
  current: GuideEntry | null;
  nextIsAvailable: boolean;
  open: (el: GuideEntry) => void;
  register: (el: GuideEntry) => void;
  showNext: (el: GuideEntry) => void;
  unregister: (el: GuideEntry) => void;
};

const nope = () => {
  throw new Error(
    "To use a <Guide>, you need to wrap the app in a <GuideProvider>"
  );
};

export const ContextObject = React.createContext<Context>({
  close: nope,
  nextIsAvailable: false,
  open: nope,
  current: null,
  register: nope,
  showNext: nope,
  unregister: nope
});

export const { Consumer, Provider } = ContextObject;
