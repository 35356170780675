import { catchToNull } from "./optional";

type Money = {
  cents: () => number;
  display: () => string;
  dollars: () => number;
  equalTo: (other: Money) => boolean;
  lessThan: (other: Money) => boolean;
};

const format = function(amount: number): string {
  if (typeof Intl === "undefined" || !Intl || !Intl.NumberFormat)
    return "$" + amount.toFixed(2);
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: "USD"
  });
};

export function fromCents(totalCents: number): Money {
  if (
    // Money is critical, so runtime check against silenced typing errors
    typeof totalCents !== "number" ||
    totalCents < 0 ||
    Math.floor(totalCents) !== totalCents
  )
    throw new Error(`Invalid number of cents: ${totalCents}`);

  const amount = totalCents / 100;
  const dollars = Math.floor(amount);
  return {
    cents: () => totalCents - dollars * 100,
    dollars: () => dollars,
    display: () => format(amount),
    equalTo: other => totalCents === other.dollars() * 100 + other.cents(),
    lessThan: other => totalCents < other.dollars() * 100 + other.cents()
  };
}

export function fromDollarsAndCents(dollars: number, cents: number): Money;
export function fromDollarsAndCents(dollarsAndCents: [number, number]): Money;
export function fromDollarsAndCents(
  dollars: number | [number, number],
  cents?: number
): Money {
  if (Array.isArray(dollars)) {
    // Money is critical, so runtime check against silenced typing errors
    if (dollars.length !== 2)
      throw new Error("Invalid array given to fromDollarsAndCents");
    return fromDollarsAndCents(dollars[0], dollars[1]);
  }
  return fromCents(dollars * 100 + (cents || 0));
}

export function fromPointString(string: string): Money {
  const m = string.match(/^\$?\s*((?:\d(?:,\d)?)+)(\.(\d{0,2}))?\s*$/);
  if (!m) throw new Error("Unrecognized amount " + string);
  const usd = Number(m[1].replace(/,/g, ""));
  const cents = m[3] ? Number((m[3][0] || "0") + (m[3][1] || "0")) : 0;
  return fromDollarsAndCents(usd, cents);
}

export function tryFromPointString(string: string): Money | null {
  return catchToNull(() => fromPointString(string));
}

export default Money;
