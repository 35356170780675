import NanoEvents from "nanoevents";

import { exposeDebug } from "~lib/Debug";
import { getInitialData } from "~root-lib/DB";

import clearMyDatabase from "./clearMyDatabase";
import createPendingChecker from "./createPendingChecker";
import createUpdater from "./createUpdater";
import dataGetters from "./dataGetters";
import handleConflicts from "./handleConflicts";
import observeChangesInPouch from "./observeChangesInPouch";
import PouchDB from "./pouchdb";
import { addPretendDataIfNeeded } from "./pretend";
import sync from "./sync";
import DB from "./types";

export default async function createDb(): Promise<DB> {
  const events = new NanoEvents();
  const pouch = new PouchDB("amazon-app-local", { adapter: "memory" });
  const onError = (err: unknown) =>
    events.emit("error", err instanceof Error ? err : new Error(String(err)));
  // In-memory copy for React rendering
  const storage = getInitialData();
  const updatesPendingReplicationExist = createPendingChecker(events);

  handleConflicts(pouch, onError);
  observeChangesInPouch(pouch, storage, events, onError);

  const update = createUpdater(pouch, events);
  const db: DB = {
    clearMyDatabase: () =>
      clearMyDatabase(update, updatesPendingReplicationExist),
    initSync: connectionInfo =>
      sync({
        pouch,
        connectionInfo,
        events,
        onError,
        updatesPendingReplicationExist
      }),
    on: events.on.bind(events),
    update,
    updatesPendingReplicationExist,
    ...dataGetters(storage)
  };

  exposeDebug("db", db);
  exposeDebug("pouch", pouch);
  exposeDebug("storage", storage);

  await addPretendDataIfNeeded(db);

  return db;
}
