import { React, styled } from "~lib";
import Svg from "~lib/Svg";

import handShakingIcon from "./images/hand-shaking.svg?inline";
import writingIcon from "./images/pencil-paper-writing.svg?inline";
import priceTagIcon from "./images/price-tag.svg?inline";

const compactView = "@media screen and (max-width: 400px)";

const Icon = styled("div")({
  height: 55,
  marginBottom: 15,
  [compactView]: {
    marginBottom: 10
  }
});

const Step = styled("div")({
  alignItems: "center",
  display: "flex",
  flexBasis: "32%",
  flexDirection: "column",
  marginBottom: 20
});

const Wrapper = styled("div")({
  display: "flex",
  fontSize: 14,
  justifyContent: "space-between",
  marginBottom: 40,
  [compactView]: {
    flexDirection: "column",
    marginBottom: 20
  },
  "& svg path": {
    stroke: "currentColor"
  }
});

export default class Tutorial extends React.Component {
  render() {
    return (
      <Wrapper>
        <Step>
          <Icon>
            <Svg dangerousSvgContent={priceTagIcon} width={54} />
          </Icon>
          See if your product is already being sold on Amazon by others. If so,
          just add your offer and start capturing sales.
        </Step>
        <Step>
          <Icon>
            <Svg dangerousSvgContent={writingIcon} width={62} />
          </Icon>
          If your product isn’t already sold on Amazon by others simply create a
          new listing.
        </Step>
        <Step>
          <Icon>
            <Svg dangerousSvgContent={handShakingIcon} width={72} />
          </Icon>
          To get started, all you need is a Professional account with Amazon.
        </Step>
      </Wrapper>
    );
  }
}
