import { React, styled, styles } from "~lib";

function stringify(value: unknown | undefined): string | undefined {
  return typeof value === "undefined" ? undefined : String(value);
}

const Field = styled("input")({
  background: "none",
  border: 0,
  color: "inherit",
  font: "inherit",
  padding: 0,
  textOverflow: "ellipsis",
  width: "100%"
});

export type NumberProps = { min?: number; max?: number; step?: number };

export type TypeProps =
  | { type?: "password" | "search" | "text" } & ValueProps<string>
  | { type: "number" } & ValueProps<number> & NumberProps;

export type UniversalInputProps = {
  autoFocus?: boolean;
  id?: string;
  name?: string;
  required?: boolean;
};

export type ExtraOnChangeParams = {
  setCustomValidity: (error: "" | string) => void;
};

export type ValueProps<T> = {
  defaultValue?: T;
  onChange?: (newValue: T, e: ExtraOnChangeParams) => void;
  value?: T;
};

export type Props = UniversalInputProps &
  TypeProps & {
    alignRight?: boolean;
    height?: number | string;
    padding?: string;
    placeholder?: string;
    width?: number | string;
  };

export default class Input extends React.Component<Props> {
  private change = (e: any) => {
    const extras: ExtraOnChangeParams = {
      setCustomValidity: msg => e.target.setCustomValidity(msg)
    };
    if (this.props.onChange) {
      if (this.props.type === "number")
        this.props.onChange(Number(e.target.value), extras);
      else this.props.onChange(e.target.value, extras);
    }
  };

  render() {
    const {
      alignRight,
      autoFocus,
      defaultValue,
      height,
      id,
      name,
      padding,
      placeholder,
      required,
      type,
      value,
      width
    } = this.props;
    const numProps = (type === "number" ? this.props : {}) as NumberProps;
    return (
      <Field
        autoFocus={autoFocus}
        className={styles({
          height,
          padding,
          textAlign: alignRight ? "right" : undefined,
          width,
          "&::-webkit-inner-spin-button": {
            display: alignRight ? "none" : undefined
          }
        })}
        defaultValue={stringify(defaultValue)}
        id={id}
        max={numProps.max}
        min={numProps.min}
        name={name}
        type={type}
        onChange={this.change}
        placeholder={placeholder}
        required={required}
        step={numProps.step}
        value={stringify(value)}
      />
    );
  }
}
