import { stringify } from "querystringify";

import { get } from "~lib/ajax";
import { AmazonSearchResponse } from "~root-lib/AjaxTypes";

import backendUrl from "~lib/backendUrl";

import { cached } from "./cache";
import localSearchResults from "./local-search-results";

function wait(ms: number): Promise<void> {
  return new Promise(resolve => {
    setTimeout(() => resolve(), ms);
  });
}

export type Result = AmazonSearchResponse;

type Auth = {
  mwsToken: string;
  sellerId: string;
};

export default async function search(
  auth: Auth,
  text: string
): Promise<AmazonSearchResponse> {
  return cached(text, async function() {
    const inLocalResults: Result["items"] | undefined =
      localSearchResults[text];
    if (inLocalResults) {
      await wait(700);
      return {
        items: inLocalResults,
        moreResultsUrl:
          inLocalResults.length === 10
            ? "https://www.amazon.com/s/?" +
              stringify({
                "field-keywords": text
              })
            : undefined
      };
    }

    return await get<AmazonSearchResponse>(
      backendUrl("/amazon/search", {
        q: text,
        sellerId: auth.sellerId,
        sellerAuthToken: auth.mwsToken
      })
    );
  });
}
