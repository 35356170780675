import { React } from "~lib";

import { Provider } from "./context";
import { nextElement } from "./dom";
import { zIndex as GuideZIndex } from "./GuideUI";
import { GuideEntry } from "./types";

type Props = {
  children: React.ReactNode;
  haveSeenAutoShowKey: (key: string) => "yes" | "no" | "i-don't-know";
  storeSeenAutoShowKey: (key: string) => void;
};

type State = {
  current: GuideEntry | null;
  guides: GuideEntry[];
};

export default class GuideProvider extends React.Component<Props, State> {
  state: State = {
    current: null,
    guides: []
  };

  private close = () => {
    this.setState({ current: null });
  };

  private open = (guide: GuideEntry) => {
    this.setState({ current: guide });
    if (
      guide.autoshowKey &&
      this.props.haveSeenAutoShowKey(guide.autoshowKey) === "no"
    )
      this.props.storeSeenAutoShowKey(guide.autoshowKey);
  };

  private register = (guide: GuideEntry) => {
    const { haveSeenAutoShowKey } = this.props;
    const { current } = this.state;
    const { autoshowKey } = guide;

    this.setState(prev => ({
      guides: prev.guides.concat([guide])
    }));

    if (current || !autoshowKey) return;
    const seen = haveSeenAutoShowKey(autoshowKey);
    if (seen === "yes" || seen === "i-don't-know") return;
    this.open(guide);
  };

  private showNext = (guide: GuideEntry) => {
    const { guides } = this.state;
    this.close();
    const nextDom = nextElement(guide.dom, guides.map(({ dom }) => dom));
    const next = guides.find(({ dom }) => dom === nextDom);
    if (next) this.open(next);
  };

  private unregister = (guide: GuideEntry) => {
    const { current } = this.state;
    this.setState(prev => ({
      guides: prev.guides.filter(({ dom }) => dom !== guide.dom)
    }));
    if (current && current.dom === guide.dom) this.setState({ current: null });
  };

  render() {
    const { children } = this.props;
    const { current, guides } = this.state;

    return (
      <Provider
        value={{
          close: this.close,
          current,
          nextIsAvailable: Boolean(
            current && nextElement(current.dom, guides.map(({ dom }) => dom))
          ),
          open: this.open,
          register: this.register,
          showNext: this.showNext,
          unregister: this.unregister
        }}
      >
        {children}
        {current && (
          <div
            style={{
              position: "fixed",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              zIndex: GuideZIndex - 1,
              // hsla here does not work on iOS Safari 11
              backgroundColor: "rgba(0, 0, 0, 0.5)"
            }}
          />
        )}
      </Provider>
    );
  }
}
