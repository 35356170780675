import React from "react";

import createNestedActionHandler from "./createNestedActionHandler";
import Link, { LinkAction, Routing } from "./Link";
import { base, clickable, placeholder } from "./styles";

export type Action =
  | "PLACEHOLDER"
  | "nested"
  | "submit"
  | string
  | LinkAction
  | { nestedActionSelector: string }
  | (() => void);

export type Props = {
  action: Action;
  children: React.ReactNode;
  childrenIfCurrent?: React.ReactNode;
  className?: string;
  classNameIfCurrent?: string;
  routing?: Routing;
  style?: React.CSSProperties;
};

export default class BaseClickable extends React.Component<Props> {
  render() {
    const {
      action,
      children,
      childrenIfCurrent,
      className,
      classNameIfCurrent,
      routing,
      style
    } = this.props;

    if (typeof action === "object" && "nestedActionSelector" in action) {
      return (
        <div
          className={[clickable, className].join(" ")}
          onClick={createNestedActionHandler(action.nestedActionSelector)}
          style={style}
        >
          {children}
        </div>
      );
    }

    if (typeof action === "function")
      return (
        <button
          className={[clickable, className].join(" ")}
          onClick={action}
          type="button"
          style={style}
        >
          {children}
        </button>
      );

    if (action === "PLACEHOLDER")
      return (
        <div className={[base, placeholder, className].join(" ")} style={style}>
          {children}
        </div>
      );

    if (action === "nested")
      // <button> nested inside a label or another button does not work
      return (
        <div className={[clickable, className].join(" ")} style={style}>
          {children}
        </div>
      );

    if (action === "submit") {
      return (
        <button
          className={[clickable, className].join(" ")}
          type="submit"
          style={style}
        >
          {children}
        </button>
      );
    }

    return (
      <Link
        action={typeof action === "string" ? { url: action } : action}
        childrenIfCurrent={childrenIfCurrent || children}
        classNameIfCurrent={[base, className, classNameIfCurrent].join(" ")}
        classNameIfNonCurrent={[clickable, className].join(" ")}
        routing={routing}
        style={style}
      >
        {children}
      </Link>
    );
  }
}
