import { React, styles } from "~lib";

export type Props = {
  dangerousSvgContent: string;
  fillColor?: string;
  opacity?: number;
  strokeColor?: string;
  width: number;
};

export default function Svg(props: Props) {
  const { dangerousSvgContent, fillColor, opacity, strokeColor, width } = props;
  return (
    <span
      className={styles({
        display: "inline-block",
        fill: fillColor,
        fillOpacity: opacity,
        stroke: strokeColor,
        width,
        "& > svg": {
          display: "block"
        }
      })}
      dangerouslySetInnerHTML={{ __html: dangerousSvgContent }}
    />
  );
}
