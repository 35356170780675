import { React, styled } from "~lib";
import Clickable from "~lib/Clickable";

const borderRadius = 2;

const ColorTag = styled("div")({
  borderTopLeftRadius: borderRadius,
  borderBottomLeftRadius: borderRadius,
  width: 4
});

const Container = styled("div")({
  alignItems: "stretch",
  backgroundColor: "white",
  borderRadius,
  display: "flex",
  marginBottom: 5,
  marginTop: 5,
  minHeight: 40,
  textTransform: "uppercase"
});

const Text = styled("div")({
  color: "#6F6F6F",
  flex: 1,
  fontSize: 11,
  letterSpacing: "0.14em",
  marginBottom: 13,
  marginLeft: 23,
  marginTop: 13,
  lineHeight: "13px"
});

type Props = {
  color: string;
  onDismiss: () => void;
  text: string;
};

export default class Toast extends React.Component<Props> {
  render() {
    const { color, onDismiss, text } = this.props;
    return (
      <Container>
        <ColorTag
          style={{
            backgroundColor: color
          }}
        />
        <Text>{text}</Text>
        <Clickable
          action={onDismiss}
          style={{
            alignItems: "center",
            color: "#757575",
            display: "flex",
            fontSize: 12,
            fontWeight: 400,
            letterSpacing: "0.25em",
            paddingLeft: 25,
            paddingRight: 25,
            textTransform: "uppercase"
          }}
        >
          <span>Dismiss</span>
        </Clickable>
      </Container>
    );
  }
}
