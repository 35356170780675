import { React, styled } from "~lib";
import Modal from "~lib/Modal";

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  onRequestClose: () => void;
};

export default function Dialog(props: Props) {
  return (
    <Modal
      isOpen={props.isOpen}
      maxWidth={964}
      onRequestClose={props.onRequestClose}
    >
      <Container>
        <Content>{props.children}</Content>
      </Container>
    </Modal>
  );
}

const Container = styled("div")({
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  minHeight: "80vh",
  maxHeight: "80vh",
  padding: "14px 32px",
  position: "relative",
  "&::before": {
    backgroundColor: "#4B5766",
    content: "' '",
    display: "block",
    height: 197,
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 0
  }
});

const Content = styled("div")({
  display: "flex",
  flexGrow: 1,
  flexDirection: "column",
  maxHeight: "80vh",
  zIndex: 1
});
