import { React, styled } from "~lib";
import PillBadge from "~lib/PillBadge";

import amazonLogo from "~lib/Images/white-amazon-logo.svg";
import volusionLogo from "~lib/Images/white-volusion-logo.svg";

type Props = {
  centered?: boolean;
  compactMediaQuery?: string | "always";
  brand: "amazon" | "volusion";
  nudgeY?: number;
};

const Icon = styled("img")({
  marginBottom: 3,
  marginLeft: -10,
  marginRight: 10,
  marginTop: 3
});

export default function Badge(props: Props) {
  const { centered, compactMediaQuery, brand, nudgeY } = props;

  const Container = styled("div")({
    ...(centered
      ? {
          display: "flex",
          justifyContent: "center"
        }
      : {}),
    ...(nudgeY && {
      position: "relative",
      top: nudgeY,
      zIndex: 2
    })
  });

  const FullName = styled("span")(
    compactMediaQuery === "always"
      ? { display: "none" }
      : compactMediaQuery
        ? { [compactMediaQuery]: { display: "none" } }
        : {}
  );
  return (
    <Container>
      <PillBadge color={brand === "amazon" ? "#FF9900" : "#9E00FF"}>
        <Icon src={brand === "amazon" ? amazonLogo : volusionLogo} />
        <span style={{ fontSize: 11 }}>
          {brand === "amazon" ? "Amazon" : "Volusion"}
          <FullName> Product</FullName>
        </span>
      </PillBadge>
    </Container>
  );
}
