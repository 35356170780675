import React from "react";

import DB from "./types";

export default function connect(
  db: DB,
  render: (db: DB, props: {}) => React.ReactNode
): React.ComponentType {
  return class DataConnector extends React.Component {
    private stopObserving?: () => void;

    componentDidMount() {
      this.stopObserving = db.on("change", () => {
        // Trigger re-render on the component
        // Could use forceUpdate, but a setState with an empty
        // object respects shouldComponentUpdate
        this.setState({});
      });
    }

    componentWillUnmount() {
      if (this.stopObserving) this.stopObserving();
    }

    render() {
      return render(db, this.props);
    }
  };
}
