import { React, styled } from "~lib";

const Container = styled("div")({
  color: "#666666"
});

export type Props = {
  chosenCategories: string[];
};

export default class Category extends React.Component<Props> {
  render() {
    const { chosenCategories } = this.props;
    return <Container>{chosenCategories.join(" › ")}</Container>;
  }
}
