import { React, styled } from "~lib";

import Card from "~lib/Cards";
import { Product } from "~lib/DB";
import { Search } from "~lib/Form";
import amazonLogo from "~lib/Images/amazon-logo.svg";
import volusionLogo from "~lib/Images/volusion-logo.svg";

export const searchHeight = 60;
const compact = "@media not screen, screen and (max-width: 499px)";

const logos = {
  amazon: <img src={amazonLogo} alt="Amazon logo" width={28} />,
  volusion: <img src={volusionLogo} alt="Volusion logo" width={17} />
};

const ContainerBackground = styled("div")({
  backgroundColor: "white"
});

const Logo = styled("div")({
  alignItems: "center",
  display: "flex",
  [compact]: {
    display: "none"
  }
});

const Pipe = styled("div")({
  borderLeft: "1px solid #DCDCDD",
  height: 20,
  marginLeft: 20,
  width: 0,
  [compact]: {
    display: "none"
  }
});

type Props = {
  brand?: keyof typeof logos;
  disabled?: boolean;
  noMargin?: boolean;
  onChange?: (newValue: string) => void;
  placeholder?: string;
  value?: string;
};

export const createProductFilter = (text: string) => {
  return (product: Product) => {
    const normalize = (s: string) => s.replace(/\s/g, "").toLowerCase();
    return normalize(product.title).includes(normalize(text));
  };
};

export default class BrandedSearch extends React.Component<Props> {
  render() {
    const {
      brand,
      noMargin,
      onChange,
      placeholder,
      value,
      disabled
    } = this.props;
    return (
      <ContainerBackground
        style={{
          margin: noMargin ? 0 : "20px 0 30px 0"
        }}
      >
        <div
          style={{
            opacity: disabled ? 0.5 : undefined,
            pointerEvents: disabled ? "none" : undefined
          }}
        >
          <Card
            css={{
              alignItems: "center",
              flexDirection: "row",
              flexShrink: 0,
              height: searchHeight,
              lineHeight: "40px",
              padding: "10px 0 10px 20px",
              [compact]: {
                padding: "10px 0"
              }
            }}
          >
            <Logo>{logos[brand || "volusion"]}</Logo>
            <Pipe />
            <Search
              height={searchHeight}
              onChange={onChange || (() => {})}
              placeholder={placeholder}
              value={value || ""}
            />
          </Card>
        </div>
      </ContainerBackground>
    );
  }
}
