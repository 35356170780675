import { React } from "~lib";
import DB from "~lib/DB";

import GuideProvider from "./GuideProvider";

type Props = {
  children: React.ReactNode;
  db: DB;
};

const query = window.location.search + window.location.hash;
const hideGuide = query.includes("hideGuide");

export default class GuideProviderWithDb extends React.Component<Props> {
  render() {
    const { children, db } = this.props;
    return (
      <GuideProvider
        haveSeenAutoShowKey={key => {
          if (hideGuide) return "yes";
          if (!db) return "i-don't-know";
          return db.guideStorage().includes(key) ? "yes" : "no";
        }}
        storeSeenAutoShowKey={key => {
          if (!db) return;
          db.update("guideStorage", prev => prev.concat([key]));
        }}
      >
        {children}
      </GuideProvider>
    );
  }
}
