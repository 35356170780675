import NanoEvents from "nanoevents";

/**
 * There is no default functionality for this in PouchDB.
 * In particular, see event ambiguity issue:
 * https://github.com/pouchdb/pouchdb/issues/6395
 *
 * Besides, PouchDB could never tell us that we have events
 * that are pending upsertion into the local PouchDB.
 */

export default function createPendingChecker(events: NanoEvents) {
  let pendingLocal = 0;
  events.on("update-saving-into-pouch", () => (pendingLocal += 1));
  events.on("update-saved-into-pouch", () => (pendingLocal -= 1));

  let syncingWithRemote = false;
  events.on("init-sync", () => (syncingWithRemote = true));

  let pendingRevs: string[] = [];
  events.on("rev-created-by-update", rev => pendingRevs.push(rev));
  events.on("rev-pushed-to-remote", function(rev) {
    pendingRevs = pendingRevs.filter(r => r !== rev);
  });

  return () =>
    pendingLocal > 0 && (syncingWithRemote && pendingRevs.length > 0);
}
