import css from "dragula/dist/dragula.min.css";

const link = document.createElement("link");
link.rel = "stylesheet";
link.href = css;
if (document.head) document.head.appendChild(link);

export const duringDragSelector = ".gu-unselectable &";

import dragula from "dragula";
export default dragula;
