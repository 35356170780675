import Autoform from "react-auto-form";

import { React } from "~lib";

type Extras = {
  form: HTMLFormElement;
};

type Props = {
  children: React.ReactNode;
  onChange?: (name: string, value: any) => void;
  onSubmit?: (data: { [fieldName: string]: any }, extras: Extras) => void;
};

export default class Form extends React.Component<Props> {
  private onChange = (_: any, name: string, data: any) => {
    if (!this.props.onChange) return;
    this.props.onChange(name, data);
  };

  private onSubmit = (e: any, data: { [name: string]: any }) => {
    e.preventDefault();
    if (!this.props.onSubmit) return;
    if (Object.keys(data).length === 0) {
      console.warn(
        "If you are suprised that your onSubmit received an empty object, remember to give names to your inputs."
      );
    }
    this.props.onSubmit(data, {
      form: e.target
    });
  };

  render() {
    const { children } = this.props;
    return (
      <Autoform onChange={this.onChange} onSubmit={this.onSubmit} trimOnSubmit>
        {children}
      </Autoform>
    );
  }
}
