import { React } from "~lib";

import RealProgressBar, { PublicProps } from "./RealProgressBar";

function shouldCrash(expected: number, passed: number): boolean {
  if (expected < 10) return 20 < passed;
  if (expected < 30) return 60 < passed;
  return expected * 2 < passed;
}

type Props = PublicProps & {
  seconds: number;
};

type State = {
  started: "never" | number;
};

export default class PretendProgressBar extends React.Component<Props, State> {
  state: State = {
    started: "never"
  };

  componentDidMount() {
    this.setState(
      {
        started: Date.now()
      },
      this.queueUpdate
    );
  }

  componentWillUnmount() {
    this.stopUpdating();
  }

  private stopUpdating = () => {
    if (this.queuedUpdate) cancelAnimationFrame(this.queuedUpdate);
  };
  private queueUpdate = () => {
    this.queuedUpdate = requestAnimationFrame(() => {
      this.queueUpdate();
      this.forceUpdate();
    });
  };
  private queuedUpdate?: number;

  render() {
    const { seconds } = this.props;
    const { started } = this.state;
    const passed = started === "never" ? 0 : (Date.now() - started) / 1000;
    if (shouldCrash(seconds, passed))
      throw new Error(
        `PretendProgressBar timed out with ${passed} over ${seconds}`
      );
    const value = (passed * 100) / seconds;
    return (
      <RealProgressBar
        value={value > 100 ? "indeterminate" : value}
        {...this.props}
      />
    );
  }
}
