import { React } from "~lib";

import { MainLayout } from "~lib/Templates";

type Props = {
  children: React.ReactNode;
};

export default function Layout(props: Props) {
  const { children } = props;
  return (
    <MainLayout
      back="/"
      hideLogo
      headerOverlap={70}
      subtitle={
        <p>
          Let’s find matches between your Amazon and Volusion products to keep
          things organized.
        </p>
      }
      title="Looks like you have a number of products on Amazon already"
    >
      {children}
    </MainLayout>
  );
}
