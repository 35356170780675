import { parseMoney } from "~lib/Form";

import { collectFields } from "./Variants";

const prefix = "amazonproductpage-";

export function nameFor(name: string): string {
  return `${prefix}${name}`;
}

export function parseFields(
  form: HTMLFormElement,
  values: { [name: string]: unknown }
) {
  const priceValue = values[nameFor("price")];
  const quantityValue = values[nameFor("quantity")];
  const conditionValue = String(values[nameFor("condition")]);
  return {
    price: priceValue ? parseMoney(String(priceValue)) || undefined : undefined,
    condition: conditionValue,
    variants: collectFields(form),
    quantity: quantityValue ? parseInt(String(quantityValue)) : 0
  };
}
