import { React, styled } from "~lib";
import Card from "~lib/Cards";
import { AmazonListing } from "~lib/DB";
import ProductImage from "~lib/ProductImage";
import { SimpleTable } from "~lib/Table";
import Tabs from "~lib/Tabs";
import { fromDollarsAndCents } from "~root-lib/Money";

import amazonTick from "~lib/Images/amazon-tick.svg";

type Row = Pick<
  AmazonListing,
  "asin" | "isActive" | "price" | "submissionStatus" | "variants"
> & {
  images?: AmazonListing["images"];
  title?: string;
};

const EmptyTableMessage = styled("div")({
  fontSize: 18,
  marginTop: 8
});
const ImageContainer = styled("div")({
  alignItems: "center",
  backgroundColor: "white",
  border: "1px solid #C4C4C4",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  height: 40,
  overflow: "hidden",
  width: 40
});
const Price = styled("div")({
  fontSize: 24
});
const ProductTitle = styled("div")({
  fontSize: 16
});
const StatusGood = styled("div")({
  fontSize: 16
});
const StatusProblem = styled("div")({
  color: "#E2442F",
  fontSize: 16
});

function rowStatus(row: Row) {
  if (row.submissionStatus === "submitting") return "processing";
  if (row.submissionStatus === "submit-failed" || row.isActive === false)
    return "problem";
  if (row.isActive === undefined) return "processing";
  return "good";
}

type Props = {
  listings: AmazonListing[];
  tab: "attention" | "other";
};

export default class Listings extends React.Component<Props> {
  render() {
    const { listings, tab } = this.props;
    if (listings.length === 0) return null;

    const standaloneParents = listings.filter(l => !l.variants);
    const parentsWithVariantsButAlsoWithTheirOwnProblem = listings.filter(
      l => l.variants && rowStatus(l) === "problem"
    );
    const allVariants = listings
      .map(l => (l.variants ? l.variants.rows : []))
      .reduce((prev, curr) => prev.concat(curr));

    // The table should show parents and variants as stand-alone rows
    // This merges it all together to be processed uniformly
    const rows = (standaloneParents as Row[])
      .concat(parentsWithVariantsButAlsoWithTheirOwnProblem)
      .concat(allVariants);

    const problemRows = rows.filter(r => rowStatus(r) === "problem");
    const goodRows = rows.filter(r => rowStatus(r) !== "problem");

    return (
      <>
        <Tabs
          tabs={[
            {
              action: "amazonProducts=",
              counter: problemRows.length,
              text: "Needs attention"
            },
            {
              action: "amazonProducts=other",
              counter: goodRows.length,
              text: "Your Amazon products"
            }
          ]}
        />
        {tab === "attention" && problemRows.length === 0 ? (
          <Card
            height={178}
            css={{
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <img src={amazonTick} alt="" />
            <EmptyTableMessage>Everything looks great!</EmptyTableMessage>
          </Card>
        ) : (
          <SimpleTable
            headers={["", "Product", "Price", "Status"]}
            rows={listingsToRows(tab === "attention" ? problemRows : goodRows)}
            widths={[10, 50, 20, 20]}
          />
        )}
        <div style={{ marginBottom: 40 }} />
      </>
    );
  }
}

function selectImages(row: Row): AmazonListing["images"] {
  const candidates: { images?: Row["images"] }[] = [row].concat(
    row.variants ? (row.variants.rows as any[]) : []
  );
  const source = candidates.find(c => Boolean(c.images && c.images.official));
  return source && source.images
    ? source.images
    : {
        official: undefined,
        unofficial_mightBeBroken: undefined
      };
}

function listingsToRows(rows: Row[]) {
  return rows.map(row => {
    const images = selectImages(row);
    return {
      "": (
        <ImageContainer>
          <ProductImage
            css={{
              height: 28
            }}
            fallbackImage={
              images.unofficial_mightBeBroken &&
              images.unofficial_mightBeBroken.url
            }
            image={images.official && images.official.url}
          />
        </ImageContainer>
      ),
      Price: (
        <Price>
          {!row.price || row.price === "only-variants"
            ? null
            : fromDollarsAndCents(row.price).display()}
        </Price>
      ),
      Product: <ProductTitle>{row.title}</ProductTitle>,
      Status: {
        good: <StatusGood>Active</StatusGood>,
        problem: <StatusProblem>Problem</StatusProblem>,
        processing: <StatusGood>Processing</StatusGood>
      }[rowStatus(row)]
    };
  });
}
