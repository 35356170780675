import { getInitialData } from "~root-lib/DB";
import DB from "./types";

export default async function clearMyDatabase(
  update: DB["update"],
  updatesPendingReplicationExist: () => boolean
): Promise<void> {
  const initialData = getInitialData();
  await Promise.all(
    (Object.keys(initialData) as (keyof typeof initialData)[]).map(key =>
      update(key, () => initialData[key])
    )
  );

  return new Promise(function(resolve) {
    setTimeout(function() {
      if (!updatesPendingReplicationExist()) resolve();
    }, 100);
  });
}
