/**
 * This file is full of technical debt.
 * It uses react-text-mask that we may want to drop;
 * that only supports uncontrolled inputs, it seems;
 * it copies the styles from the main Input;
 * it performs Number parsing that we may want to extract
 * for other inputs as well.
 */

import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { React, styles } from "~lib";
import Money, { tryFromPointString } from "~root-lib/Money";

import { ExtraOnChangeParams } from "./Input";

export { tryFromPointString as parse } from "~root-lib/Money";

const baseClassName = styles({
  background: "none",
  border: 0,
  color: "black",
  font: "inherit",
  fontSize: 16,
  padding: 0,
  width: "6em"
});

const mask = createNumberMask({
  prefix: "$",
  allowDecimal: true,
  integerLimit: 9
});

type Props = {
  className?: string;
  defaultValue?: Money;
  name?: string;
  onChange?: (money: Money, extras: ExtraOnChangeParams) => void;
  required?: boolean;
};

function padTwoDigits(num: number): string {
  if (num < 10) return "0" + num;
  return String(num);
}

function stringify(value: Money): string {
  return "$" + value.dollars() + "." + padTwoDigits(value.cents());
}

export default class MoneyInput extends React.Component<Props> {
  private change = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsed = tryFromPointString(e.target.value);
    e.target.setCustomValidity(
      parsed
        ? ""
        : "Sorry, we can't understand this amount. Try something like “14.99”."
    );
    if (parsed && this.props.onChange)
      this.props.onChange(parsed, {
        setCustomValidity: msg => e.target.setCustomValidity(msg)
      });
  };

  render() {
    const { className, defaultValue, name, required } = this.props;
    return (
      <MaskedInput
        className={baseClassName + " " + (className || "")}
        defaultValue={defaultValue ? stringify(defaultValue) : undefined}
        mask={mask}
        name={name}
        onChange={this.change}
        placeholder="$12.34"
        required={required}
      />
    );
  }
}
