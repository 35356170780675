import { React } from "~lib";

import PretendProgressBar from "./PretendProgressBar";
import RealProgressBar, { PublicProps } from "./RealProgressBar";

export { default as diagonalStripeStyles } from "./diagonalStripeStyles";

type ValueProps =
  | { indeterminate: true }
  | { seconds: number }
  | { value: number };

type Props = PublicProps & ValueProps;

export default function ProgressBar(props: Props) {
  if ("value" in props) return <RealProgressBar {...props} />;
  if ("indeterminate" in props)
    return <RealProgressBar value="indeterminate" {...props} />;
  return <PretendProgressBar {...props} />;
}
