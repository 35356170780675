import { React, styled } from "~lib";

import { scrollbarWidth } from "../styles";

import Header, { Props as HeaderProps } from "./Header";

type Props = HeaderProps & {
  children: React.ReactNode;
  headerOverlap?: number;
};

const HeaderBackground = styled("div")({
  backgroundColor: "#182433",
  color: "white"
});

export const CenteredColumn = styled("div")({
  margin: "0 auto",
  maxWidth: 1000,
  padding: "0 10px 0 10px",
  "@media screen and (min-width: 1010px)": {
    paddingRight: `calc(10px - ${scrollbarWidth})`
  }
});

export default function MainLayout(props: Props) {
  const { children, headerOverlap } = props;
  return (
    <div>
      <HeaderBackground
        style={{
          paddingBottom: headerOverlap
        }}
      >
        <CenteredColumn>
          <Header {...props} />
        </CenteredColumn>
      </HeaderBackground>
      <div
        style={{
          marginBottom: 100,
          marginTop: -(headerOverlap || 0)
        }}
      >
        <CenteredColumn>{children}</CenteredColumn>
      </div>
    </div>
  );
}
