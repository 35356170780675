import DB from "../types";

import { addPretendAmazon } from "./pretendAmazon";
import { addPretendVolusion } from "./pretendVolusion";

export { addPretendVolusion } from "./pretendVolusion";
export { addPretendAmazon, isPretendAmazon } from "./pretendAmazon";

export async function addPretendDataIfNeeded(db: DB) {
  const query = window.location.search + window.location.hash;
  const startWithPretend = query.match(/\bpretend\b/);
  if (startWithPretend || query.match(/\bpretendAmazon(Listings|\b)/)) {
    await addPretendAmazon(db, "with-data");
  }
  if (startWithPretend || query.match(/\bpretendVolusion\b/)) {
    await addPretendVolusion(db);
  }
}
