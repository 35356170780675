import { Link, RouteProps, withRouter } from "react-router-dom";
import { React } from "~lib";

import addGlobalUrlState from "../../Routes/addGlobalUrlState";

import ActionArea, { Action, Props as ActionAreaProps } from "./ActionArea";

// Workaround until a proper parseAction/needsRouter is available
function needsRouter(action: Action) {
  return typeof action !== "function" && action !== "PLACEHOLDER";
}

export { Action } from "./ActionArea";

type Props = ActionAreaProps & RouteProps;

function BaseClickable(props: Props) {
  const location = props.location || {
    pathname: "/",
    search: ""
  };
  return (
    <ActionArea
      {...props}
      routing={{
        location,
        routerLink: props => (
          <Link
            {...props}
            to={
              props.to[0] === "/"
                ? addGlobalUrlState({
                    current: location,
                    target: props.to
                  })
                : props.to
            }
          />
        )
      }}
    />
  );
}

const BaseClickableWithRouter = withRouter(BaseClickable as any);

export default function(props: ActionAreaProps) {
  if (!needsRouter(props.action)) return <ActionArea {...props} />;
  return <BaseClickableWithRouter {...props} />;
}
