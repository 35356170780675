import { React } from "~lib";

import DB from "~lib/DB";
import Tabs from "~lib/Tabs";

import BulkAdd from "./BulkAdd";
import SingleAdd from "./SingleAdd";

type Props = {
  db: DB;
  tab: "single" | "bulk";
  shownAtTheTop?: boolean;
};

export default function Add(props: Props) {
  const { db, shownAtTheTop, tab } = props;
  return (
    <>
      <Tabs
        tabs={[
          { action: "add=", text: "Single add" },
          { action: "add=bulk", text: "Bulk add" }
        ]}
        theme={shownAtTheTop ? "on-dark-bg" : "on-white-bg"}
      />
      {tab === "bulk" ? <BulkAdd /> : <SingleAdd db={db} />}
    </>
  );
}
