import { React, styled } from "~lib";
import Money, { fromDollarsAndCents } from "~root-lib/Money";

export type Props = {
  price?: Money | [number, number] | "only-variants";
  variantPrices?: [number, number][];
};

function getMoney(props: Props): Money | [Money, Money] | undefined {
  if (!props.price) return undefined;

  if (props.price !== "only-variants")
    return Array.isArray(props.price)
      ? fromDollarsAndCents(props.price)
      : props.price;

  if (!props.variantPrices || props.variantPrices.length === 0)
    return undefined;

  const prices = props.variantPrices.map(fromDollarsAndCents);

  const min = prices.reduce(
    (prev, curr) => (prev.lessThan(curr) ? prev : curr)
  );
  const max = prices.reduce(
    (prev, curr) => (prev.lessThan(curr) ? curr : prev)
  );
  return [min, max];
}

const Price = styled("div")({
  color: "gray",
  marginTop: 3
});
const NoPrice = styled("div")({
  visibility: "hidden"
});

export default function PriceDisplay(props: Props) {
  const money = getMoney(props);
  if (!money) return <NoPrice>-</NoPrice>;
  if (!Array.isArray(money)) return <Price>{money.display()}</Price>;
  if (money[0].equalTo(money[1])) return <Price>{money[0].display()}</Price>;
  return (
    <Price>
      {money[0].display()} – {money[1].display()}
    </Price>
  );
}
