import { React } from "~lib";

import Rectangle, { Props as RectangleProps } from "./Rectangle";

type Props = Pick<
  RectangleProps,
  "action" | "disabled" | "pretendToWork" | "width"
> & {
  children: string;
};

export default function CTA(props: Props) {
  const { action, children, disabled, width, pretendToWork } = props;
  return (
    <Rectangle
      action={action}
      disabled={disabled}
      width={width}
      pretendToWork={pretendToWork}
      theme={"cta"}
    >
      {children}
    </Rectangle>
  );
}
