import { React } from "~lib";

import dragHandle from "./drag-handle.svg";

type Props = {
  size?: number | string;
};

export default function DragHandle(props: Props) {
  const { size } = props;
  return (
    <span style={{ display: "inline-block" }}>
      <img src={dragHandle} width={size} />
    </span>
  );
}
