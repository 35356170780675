import { styled } from "~lib";

import headerLeft from "./header-left.png";
import headerMid from "./header-mid.png";
import headerRight from "./header-right.png";

export const height = 125;

const Header = styled("div")({
  "@media screen and (min-width: 600px)": {
    backgroundColor: "#232f3e",
    backgroundImage: `url(${headerRight}), url(${headerLeft}), url(${headerMid})`,
    backgroundRepeat: "no-repeat, no-repeat, repeat-x",
    backgroundPosition: "right 20px, left 20px, 526px 20px",
    height
  }
});

export default Header;
