async function request(
  url: string,
  method: string,
  body?: object
): Promise<any> {
  function throwResponse(msg: string, responseBody: string): never {
    console.error("Called", method, url);
    console.error("Server response:", responseBody);
    throw new Error(`${msg} when calling ${method} ${url}`);
  }

  const response = await fetch(url, {
    body: typeof body === "undefined" ? undefined : JSON.stringify(body),
    headers: [
      typeof body === "undefined" ? [] : ["Content-Type", "application/json"]
    ].filter(h => h.length),
    method
  });
  const responseBody = await response.text();
  if (response.status < 200 || 300 <= response.status)
    throwResponse("HTTP response code " + response.status, responseBody);
  try {
    return JSON.parse(responseBody);
  } catch (err) {
    throwResponse("Invalid JSON response", responseBody);
  }
}

export async function get<Response>(url: string): Promise<Response> {
  return request(url, "GET");
}

export async function post<Request extends object, Response = void>(
  url: string,
  body?: Request
): Promise<Response> {
  return request(url, "POST", body);
}
