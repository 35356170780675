import { React, styled } from "~lib";
import { headerHeight } from "~lib/AmazonProductPageLookalike";
import { MainLayout } from "~lib/Templates";

const ColoredList = styled("ul")({
  listStyle: "none",
  marginBottom: "1.8em",
  paddingLeft: 25,
  "& > li": {
    margin: "0.5em 0"
  },
  "& > li::before": {
    color: "#FF9900",
    content: "'•'",
    display: "inline-block",
    marginLeft: -18,
    width: 18
  }
});

type Props = {
  children: React.ReactNode;
  searchUrl: string;
  showVariantGuide: boolean;
};

export default function Layout(props: Props) {
  const { children, searchUrl, showVariantGuide } = props;
  return (
    <MainLayout
      back={searchUrl}
      headerOverlap={headerHeight}
      guide={{
        autoshowKey: "SellExisting",
        children: (
          <React.Fragment>
            <p style={{ marginBottom: "1em" }}>
              This is a snippet of the shared listing controlled by Amazon (
              <span style={{ fontStyle: "italic" }}>
                not all content is shown
              </span>
              ).
            </p>
            <p>All you need to confirm is:</p>
            <ColoredList>
              <li>Price you want to sell your product for</li>
              <li>Condition of your product</li>
              <li>How many you have available to sell</li>
              {showVariantGuide && <li>Matches for product variants</li>}
            </ColoredList>
          </React.Fragment>
        ),
        nudgeX: 15,
        title: "Helpful Tip"
      }}
      title="Add your offer to the listing"
      subtitle="Review the product you will be selling on Amazon by setting its condition and price."
    >
      {children}
    </MainLayout>
  );
}
