import React from "react";

import DB, { connect } from "~lib/DB";

import scrollTop from "./scrollTop";
import transformProps from "./transformProps";
import { _Route, _RouterProps } from "./types";

function addData(db: DB, Component: React.ComponentType<any>) {
  return connect(
    db,
    (db, props) => <Component {...props} db={db} />
  );
}

function addRoutingProps(route: _Route, Component: React.ComponentType) {
  return (props: _RouterProps) => {
    const innerProps = transformProps(route, props);
    return <Component {...innerProps} />;
  };
}

export default function componentFor(
  db: DB,
  route: _Route
): React.ComponentType<_RouterProps> {
  let component = scrollTop(route.component);

  // The earlier this is, the deeper nested the data source component is,
  // the less to re-render when db changes all the time.
  component = addData(db, component);

  component = addRoutingProps(route, component);

  return component;
}
