import { React, styled, styles } from "~lib";
import BaseClickable, { Action } from "~lib/Clickable";
import PillBadge from "~lib/PillBadge";

const compactView = "@media screen and (max-width: 599px)";

const ActiveTabName = styled("span")({
  [compactView]: {
    borderBottom: "2px solid #FF9900",
    paddingBottom: 3
  }
});

const Container = styled("div")({
  label: "tabs",
  textAlign: "center",
  [compactView]: {
    display: "flex",
    flexDirection: "column"
  }
});

const CounterContainer = styled("div")({
  display: "inline-flex",
  fontSize: 11,
  marginLeft: 10,
  verticalAlign: "bottom"
});

type CounterProps = {
  color: string;
  counter: number;
};

function Counter(props: CounterProps) {
  const { color, counter } = props;
  return (
    <CounterContainer>
      <PillBadge color={color} size="small">
        {counter}
      </PillBadge>
    </CounterContainer>
  );
}

const tabStyles = styles({
  display: "inline-block",
  fontSize: 12,
  fontWeight: 400,
  letterSpacing: "0.085em",
  margin: "0 20px",
  paddingBottom: 10,
  paddingLeft: 12,
  paddingRight: 12,
  textTransform: "uppercase"
});

const activeTabStyles = styles({
  borderBottom: "2px solid #FF9900",
  [compactView]: {
    borderBottom: "none"
  }
});

const themes = {
  "on-dark-bg": {
    main: styles(tabStyles, {
      color: "#a3a7ad"
    }),
    active: styles(activeTabStyles, {
      color: "white"
    })
  },
  "on-white-bg": {
    main: styles(tabStyles, {
      color: "#616161"
    }),
    active: styles(activeTabStyles, {
      color: "black"
    })
  }
};

type TabInfo = {
  action: Action;
  counter?: number;
  text: string;
};

type Props = {
  tabs: TabInfo[];
  theme?: keyof typeof themes;
};

export default function Tabs(props: Props) {
  const { tabs } = props;
  const theme = props.theme || "on-dark-bg";
  return (
    <Container>
      {tabs.map((t, i) => (
        <BaseClickable
          action={t.action}
          childrenIfCurrent={
            <React.Fragment>
              <ActiveTabName>{t.text}</ActiveTabName>
              {typeof t.counter === "number" && (
                <Counter counter={t.counter} color="#ff9900" />
              )}
            </React.Fragment>
          }
          className={themes[theme].main}
          classNameIfCurrent={themes[theme].active}
          key={i}
        >
          {t.text}
          {typeof t.counter === "number" && (
            <Counter counter={t.counter} color="#232f3e" />
          )}
        </BaseClickable>
      ))}
    </Container>
  );
}
