import { React, styled } from "~lib";
import { CenteredColumn } from "~lib/Templates/MainLayout";

import { dismiss, get, onChange } from "./globalState";
import Toast from "./Regular";

const Container = styled("div")({
  left: 0,
  right: 0,
  position: "absolute"
});

export default class Stack extends React.Component {
  componentDidMount() {
    this.cancelListening = onChange(() => {
      this.setState({});
    });
  }

  componentWillUnmount() {
    if (this.cancelListening) this.cancelListening();
  }

  private cancelListening: null | Function = null;

  render() {
    const toasts = get();
    return (
      <Container>
        <CenteredColumn>
          {toasts.map(toast => (
            <React.Fragment key={toast.id}>
              {toast.type === "regular" ? (
                <Toast
                  color="#FFC300"
                  onDismiss={() => {
                    dismiss(toast.id);
                  }}
                  text={toast.text}
                />
              ) : (
                toast.node
              )}
            </React.Fragment>
          ))}
        </CenteredColumn>
      </Container>
    );
  }
}
