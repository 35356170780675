import { css as emotion } from "emotion";
import React from "react";

type ClassName = string;
export type StyleObject = React.CSSProperties & {
  [k: string]: string | undefined | {};
};
type OneOrMany<X> = X[] | X;

/**
 * In your component, expose a css property with this type
 * and get full CSS customization for your clients.
 */
export type StylesProp = OneOrMany<ClassName | StyleObject | undefined>;

/**
 * Collect any number of css objects and styles into a single
 * HTML class name to be used in an HTML element, or passed
 * into deeper StylesProps of other components.
 */
export default function styles(...props: StylesProp[]): ClassName {
  return emotion(props);
}
