import { React, styled } from "~lib";

import Line from "./Line";

const SectionContainer = styled("section")({
  marginBottom: 30
});
export const SectionTitle = styled("h3")({
  fontSize: 18,
  margin: "0 0 10px"
});

type Props = {
  children: React.ReactNode;
};

export default function Section(props: Props) {
  const { children } = props;
  return (
    <>
      <Line />
      <SectionContainer>{children}</SectionContainer>
    </>
  );
}
