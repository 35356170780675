import { React } from "~lib";

import { MainLayout } from "~lib/Templates";

type Props = {
  children: React.ReactNode;
};

export default function Layout(props: Props) {
  const { children } = props;
  return (
    <MainLayout
      headerOverlap={70}
      title="Sell Your Volusion Products on Amazon"
      subtitle={
        <p>Select one of your Volusion store products to sell on Amazon.</p>
      }
    >
      {children}
    </MainLayout>
  );
}
