import { React, styled } from "~lib";
import { ColorDot } from "~lib/Clickable";
import { Input } from "~lib/Form";

import { nameFor } from "./fieldNames";
import Section, { SectionTitle } from "./Section";
import { wide } from "./styles";

const Label = styled("div")({
  fontSize: 12,
  color: "#666666"
});

const Quantity = styled("div")({
  backgroundColor: "#F5F5F5",
  borderBottom: "1px solid #FF9900",
  boxSizing: "border-box",
  height: 56,
  marginTop: 5,
  padding: 10,
  "& input": {
    textAlign: "left !important" as any
  },
  [wide]: {
    background: "none",
    border: "none",
    marginTop: 0,
    textAlign: "right",
    width: 105,
    "& input": {
      textAlign: "right !important" as any
    },
    "&:hover": {
      backgroundColor: "#F5F5F5",
      borderBottom: "1px solid #FF9900"
    }
  }
});

const Wrapper = styled("div")({
  [wide]: {
    display: "flex",
    justifyContent: "space-between"
  }
});

type Props = {
  defaultValue?: number;
};

export default function Inventory(props: Props) {
  const { defaultValue } = props;
  return (
    <Section>
      <Wrapper>
        <div>
          <label htmlFor="AmazonProductPageLookalikeInventory">
            <ColorDot action="nested" color="green" nudgeX={-10} nudgeY="1em" />
            <SectionTitle>Inventory Level</SectionTitle>
          </label>
          <p>How many products should be available to sell on Amazon?</p>
        </div>
        <Quantity>
          <Label>Quantity</Label>
          <Input
            alignRight
            defaultValue={defaultValue}
            id="AmazonProductPageLookalikeInventory"
            min={0}
            name={nameFor("quantity")}
            placeholder="0"
            required
            type="number"
            width={60}
          />
        </Quantity>
      </Wrapper>
    </Section>
  );
}
