import { React, styled } from "~lib";
import BrandedSearch, { createProductFilter } from "~lib/BrandedSearch";
import { ProductCard } from "~lib/Cards";
import { ClickableText } from "~lib/Clickable";
import DB, { addPretendVolusion } from "~lib/DB";
import Environment from "~lib/Environment";
import FlexLastRowAlignHack from "~lib/FlexLastRowAlignHack";
import Pagination from "~lib/Pagination";
import { cardGridGap } from "~lib/Templates/styles";
import { url } from "~lib/URL";

import AllAdded from "./AllAdded";
import clearMyDatabase from "./clearMyDatabase";
import Import from "./Import";
import { HorizontalPlaceholder, VerticalPlaceholder } from "./Placeholder";
import { compactView, regularView } from "./styles";

const productCardHeight = 379;
const productCardWidth = 311;

const compactProductCardHeight = 146;
const compactProductCardWidth = "100%";

const CardWrapper = styled("div")({
  marginBottom: cardGridGap,
  marginLeft: cardGridGap,
  [compactView]: {
    width: "100%"
  }
});

const CompactCard = styled("div")({
  [regularView]: { display: "none" }
});

const RegularCard = styled("div")({
  [compactView]: { display: "none" }
});

const ProductWrapper = styled("div")({
  marginBottom: 60,
  marginLeft: -cardGridGap,
  textAlign: "center"
});

const Products = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  marginBottom: 30,
  textAlign: "initial"
});

export default class SingleAdd extends React.Component<{ db: DB }> {
  state = {
    searchText: ""
  };

  private onChangeSearch = (searchText: string) => {
    this.setState({ searchText });
    if (searchText.toLowerCase().replace(/[^a-z]/g, "") === "clearmydatabase")
      clearMyDatabase(this.props.db);
  };

  render() {
    const { db } = this.props;
    const { searchText } = this.state;
    const amazonListings = db.amazonListings();
    const productsOnAmazon =
      amazonListings === "unsynced"
        ? []
        : amazonListings.map(l => l.volusionProductId).filter(id => id);
    const productsInDb = db.products();
    const productsToAdd =
      productsInDb === "unsynced"
        ? "unsynced"
        : productsInDb.filter(p => productsOnAmazon.indexOf(p.id) === -1);

    return (
      <React.Fragment>
        <BrandedSearch
          onChange={this.onChangeSearch}
          placeholder="Search your Volusion products"
          value={searchText}
          disabled={productsToAdd === "unsynced" || productsToAdd.length === 0}
        />
        {productsToAdd === "unsynced" ? (
          <ProductWrapper>
            <Environment hide="production">
              <ClickableText
                action={() => {
                  addPretendVolusion(db);
                }}
              >
                👷 Pretend the products loaded
              </ClickableText>
            </Environment>
            <Products>
              {Array(6)
                .fill(0)
                .map((_, i) => (
                  <CardWrapper key={i}>
                    <RegularCard>
                      <VerticalPlaceholder
                        height={productCardHeight}
                        width={productCardWidth}
                      />
                    </RegularCard>
                    <CompactCard>
                      <HorizontalPlaceholder
                        height={compactProductCardHeight}
                        width={compactProductCardWidth}
                      />
                    </CompactCard>
                  </CardWrapper>
                ))}
            </Products>
          </ProductWrapper>
        ) : productsToAdd.length === 0 ? (
          <AllAdded />
        ) : (
          <ProductWrapper>
            <Products>
              <Pagination
                items={productsToAdd.filter(createProductFilter(searchText))}
                pageSizes="divisibleBy3"
                renderItem={product => {
                  const cardProps = {
                    action: url(["sell", product.id]),
                    product
                  };

                  return (
                    <CardWrapper key={product.title}>
                      <RegularCard>
                        <ProductCard
                          {...cardProps}
                          height={productCardHeight}
                          width={productCardWidth}
                        />
                      </RegularCard>
                      <CompactCard>
                        <ProductCard
                          {...cardProps}
                          height={compactProductCardHeight}
                          horizontal
                          width={compactProductCardWidth}
                        />
                      </CompactCard>
                    </CardWrapper>
                  );
                }}
              />
              <FlexLastRowAlignHack
                itemCss={{ marginLeft: cardGridGap }}
                itemWidth={productCardWidth}
              />
            </Products>
          </ProductWrapper>
        )}
        {productsToAdd !== "unsynced" && (
          <Import dataReady={amazonListings !== "unsynced"} />
        )}
      </React.Fragment>
    );
  }
}
