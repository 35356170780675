import { React, styled } from "~lib";
import Clickable, { Action, CTA } from "~lib/Clickable";

import backIcon from "../MainLayout/back.svg";

type CTAProps = {
  CTADisabled?: boolean;
  CTAText: string;
  onCTAClick: Action;
};

type Props = {
  onBackClick?: Action;
  title: string;
} & (void | CTAProps);

export default function Header(props: Props) {
  const ctaProps = props as CTAProps;
  const { onCTAClick, CTAText, CTADisabled } = ctaProps;
  const { title, onBackClick } = props;
  return (
    <Container>
      {onBackClick && (
        <Clickable style={{ lineHeight: 0 }} action={onBackClick}>
          <BackImg src={backIcon} alt="←" />
        </Clickable>
      )}
      <H1>{title}</H1>
      {onCTAClick && (
        <ButtonContainer>
          <CTA disabled={CTADisabled} action={onCTAClick}>
            {CTAText}
          </CTA>
        </ButtonContainer>
      )}
    </Container>
  );
}

const Container = styled("div")({
  alignItems: "center",
  display: "flex",
  flexShrink: 0,
  justifyContent: "flex-start"
});

const ButtonContainer = styled("div")({
  marginLeft: "auto"
});

const BackImg = styled("img")({
  marginRight: 16,
  verticalAlign: 3,
  width: 16
});

const H1 = styled("h1")({
  textAlign: "left",
  color: "white",
  fontSize: 16,
  marginBottom: 0,
  marginTop: 0,
  textTransform: "uppercase"
});
