import { React, styled } from "~lib";
import Card from "~lib/Cards";
import { CTA } from "~lib/Clickable";
import DB, { Product, ProductMatch } from "~lib/DB";
import amazonIllustration from "~lib/Images/amazon-multiple-ticks.png";
import Pagination from "~lib/Pagination";
import { addToast } from "~lib/Toasts";

import createVolusionProducts from "./createVolusionProducts";
import { getProductMatches } from "./matches";
import Footer from "./Footer";
import Layout from "./Layout";
import ProductMatcher from "./ProductMatcher";
import SelectExistingVolusionListing from "./ProductMatcher/SelectExistingVolusionListing";
import { compactMargin, compactView } from "./ProductMatcher/styles";

type Props = {
  db: DB;
  navigateTo: (path: string) => void;
};

type State = {
  step: { type: "show-matches" } | { type: "select-match"; for: ProductMatch };
  matches: "unsynced" | ProductMatch[];
};

function isMatchEquals(left: ProductMatch, right: ProductMatch) {
  return left.amazonListing.asin === right.amazonListing.asin;
}

export default class Import extends React.Component<Props, State> {
  state: State = {
    step: { type: "show-matches" },
    matches: "unsynced"
  };

  private orphanVolusionListings = (): Product[] => {
    const { db } = this.props;
    const { matches } = this.state;
    const amazonListings = db.amazonListings();
    const volusionProducts = db.products();
    if (amazonListings === "unsynced" || volusionProducts === "unsynced")
      throw new Error("Should not arrive at Import page without data");
    const volusionProductsLinkedToAmazonInDatabase = amazonListings.map(
      l => l.volusionProductId
    );
    const volusionProductsLinkedOnScreen =
      matches === "unsynced"
        ? []
        : matches.map(m => m.volusionListing && m.volusionListing.id);
    const linkedVolusionProducts = volusionProductsLinkedToAmazonInDatabase
      .concat(volusionProductsLinkedOnScreen)
      .filter(l => l);
    return volusionProducts.filter(p => !linkedVolusionProducts.includes(p.id));
  };

  componentDidMount() {
    const { db } = this.props;
    const matches = getProductMatches(db);
    if (matches === "unsynced") return;
    this.setState({ matches });
  }

  private handleManualMatchClick = (match: ProductMatch) => {
    this.setState({ step: { type: "select-match", for: match } });
  };

  private handleCloseDialog = () => {
    this.setState({ step: { type: "show-matches" } });
  };

  private handleMatchSelected = (selectedProduct: Product) => {
    const { step } = this.state;
    if (step.type !== "select-match") return;
    const match = step.for;
    this.setState({
      step: { type: "show-matches" },
      matches: (this.state.matches as ProductMatch[]).map(
        (currentMatch: ProductMatch) =>
          isMatchEquals(currentMatch, match)
            ? {
                amazonListing: currentMatch.amazonListing,
                userApprovedPairing: false,
                volusionListing: selectedProduct
              }
            : currentMatch
      )
    });
  };

  private handleImport = (match: ProductMatch) => {
    this.setState({
      matches: (this.state.matches as ProductMatch[]).map(
        (currentMatch: ProductMatch) =>
          isMatchEquals(currentMatch, match)
            ? {
                ...match,
                userApprovedPairing: !match.userApprovedPairing,
                volusionListing: undefined
              }
            : currentMatch
      )
    });
  };

  private handleRemoveMatchClick = (match: ProductMatch) => {
    if (!match.volusionListing) return;
    this.setState({
      matches: (this.state.matches as ProductMatch[]).map(
        (currentMatch: ProductMatch) =>
          isMatchEquals(currentMatch, match)
            ? {
                ...match,
                volusionListing: undefined
              }
            : currentMatch
      )
    });
  };

  private saveChanges = async () => {
    const { db, navigateTo } = this.props;
    const { matches } = this.state;
    const volusionAuth = db.volusionAuth();

    if (matches !== "unsynced") {
      // Import products to Volusion
      const toImport = matches.filter(
        m => !m.volusionListing && m.userApprovedPairing
      );
      if (volusionAuth !== "unconnected") {
        const createdIdMap = await createVolusionProducts(
          volusionAuth,
          toImport
        );
        await db.update("amazonListings", amazonListings => {
          if (amazonListings === "unsynced") return amazonListings;
          return amazonListings.map(listing => {
            if (!createdIdMap[listing.asin]) return listing;
            return {
              ...listing,
              volusionProductId: createdIdMap[listing.asin]
            };
          });
        });
      }

      // Link Amazon and Volusion products
      const matchesToSave = matches.filter(m => m.volusionListing);
      await db.update("amazonListings", amazonListings => {
        if (amazonListings === "unsynced") return amazonListings;
        return amazonListings.map(listing => {
          const match = matchesToSave.find(
            m => m.amazonListing.asin === listing.asin
          );
          if (!match || !match.volusionListing || !match.volusionListing.id)
            return listing;
          return {
            ...listing,
            volusionProductId: match.volusionListing.id
          };
        });
      });

      const processedCount = matchesToSave.length + toImport.length;
      const plural = processedCount > 1 || matchesToSave.length;
      const performedActions = [
        matchesToSave.length && "matched",
        toImport.length && "added to your Volusion store"
      ]
        .filter(t => t)
        .join(" & ");
      if (performedActions)
        addToast(
          `${plural ? "Products" : "Product"} successfully ${performedActions}`
        );
    }

    navigateTo("/");
  };

  private handleSaveClick = () => {
    this.saveChanges().catch(err => {
      this.setState(() => {
        throw err;
      });
    });
  };

  render() {
    const { db } = this.props;
    const { matches } = this.state;

    if (db.amazonAuth() === "unconnected")
      throw new Error("Expected to have Amazon auth in Import");

    if (matches === "unsynced")
      return (
        <Layout>
          <div style={{ color: "white" }}>
            <p>
              We are retrieving your data from Amazon. Please come back in 15
              minutes.
            </p>
          </div>
          <Footer />
        </Layout>
      );

    if (matches.length === 0)
      return (
        <Layout>
          <Card
            css={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              padding: "40px 10px 40px",
              textAlign: "center"
            }}
          >
            <img src={amazonIllustration} alt="" height={141} width={320} />
            <h1 style={{ fontSize: 28, margin: "35px 0 15px" }}>
              Congratulations!
            </h1>
            <p style={{ color: "#6F6F6F" }}>
              All of your Amazon products have been successfully matched with
              Volusion products.
            </p>
          </Card>
          <Footer />
        </Layout>
      );

    return (
      <Layout>
        <ProductMatchList>
          <Pagination
            items={matches}
            renderItem={pm => (
              <ProductMatcher
                db={db}
                key={pm.amazonListing.asin}
                match={pm}
                onImportClick={this.handleImport}
                onManualMatchClick={this.handleManualMatchClick}
                onRemoveMatchClick={this.handleRemoveMatchClick}
              />
            )}
          />
        </ProductMatchList>
        <CTAContainer>
          <CTA action={this.handleSaveClick} pretendToWork={10000}>
            Confirm matches
          </CTA>
        </CTAContainer>
        {this.state.step.type === "select-match" && (
          <SelectExistingVolusionListing
            onRequestClose={this.handleCloseDialog}
            db={this.props.db}
            onMatchSelected={this.handleMatchSelected}
            orphanVolusionListings={this.orphanVolusionListings()}
          />
        )}
        <Footer />
      </Layout>
    );
  }
}

const ProductMatchList = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "space-between",
  [compactView]: {
    justifyContent: "center",
    // Compensate for children margin-left
    marginLeft: -compactMargin
  }
});

const CTAContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: 25,
  width: "100%"
});
