import deepEqual from "fast-deep-equal";

import { fromCents, fromDollarsAndCents } from "~root-lib/Money";
import { React, styled } from "~lib";
import BrandBadge from "~lib/BrandBadge";
import dragula from "~lib/Dragula";
import mergeIcon from "~lib/Images/merge-icon.svg";

import Section, { SectionTitle } from "../Section";
import { sidePadding } from "../styles";
import { ProductInEdit } from "../types";

import AmazonVariant from "./AmazonVariant";
import { getPriceField, getQuantityField } from "./collectFields";
import ProductVariant from "./ProductVariant";
import { compactView } from "./styles";

export { default as collectFields } from "./collectFields";

function variantName(variant: {
  groups: { [name: string]: string };
  sku?: string;
  title?: string;
}) {
  return (
    variant.title ||
    Object.keys(variant.groups)
      .map(k => variant.groups[k])
      .concat(variant.sku ? [variant.sku] : [])
      .join(" ")
  );
}

const grayBorder = "1px solid #dddddd";

const AmazonSide = styled("div")({
  boxSizing: "border-box",
  flex: 1,
  padding: "18px 0px 25px 30px",
  [compactView]: {
    padding: "18px 0px 25px 20px",
    width: "50%"
  }
});

const AmazonVariantRow = styled("div")({
  display: "flex",
  "&>:nth-child(2)": {
    marginLeft: 10
  }
});

const BrandBadgeAmazon = styled("div")({
  marginLeft: 30,
  [compactView]: {
    marginLeft: -20,
    textAlign: "center"
  }
});

const BrandBadgeVolusion = styled("div")({
  marginLeft: 24,
  [compactView]: {
    marginLeft: -20,
    textAlign: "center"
  }
});

const Divider = styled("div")({
  borderRight: grayBorder,
  width: 0
});

const MergeIcon = styled("div")({
  alignItems: "center",
  display: "flex",
  backgroundColor: "white",
  border: grayBorder,
  borderRadius: "50%",
  height: 50,
  justifyContent: "center",
  marginLeft: -25,
  marginTop: -25,
  width: 50,
  [compactView]: {
    marginTop: -43
  }
});

const ProductVariantPlaceholder = styled("div")({
  borderRadius: 6,
  boxSizing: "border-box",
  height: 80,
  position: "relative",
  width: 170,
  "&:empty": {
    border: "2px dashed #e1e1e1"
  },
  [compactView]: {
    marginRight: "-100%",
    minHeight: 94,
    width: "100%"
  }
});

const VariantsList = styled("div")({
  marginTop: 20,
  maxHeight: 480,
  minHeight: "calc(100% - 40px)",
  overflowY: "scroll",
  "& > div": {
    marginBottom: 20,
    "&:last-child": {
      marginBottom: 0
    }
  },
  [compactView]: {
    paddingRight: 20
  }
});

const VariantsWrapper = styled("div")({
  border: grayBorder,
  borderRadius: 3,
  display: "flex",
  marginTop: 45,
  maxWidth: 600,
  width: "100%",
  [compactView]: {
    borderRadius: "initial",
    marginLeft: -sidePadding,
    marginRight: -sidePadding,
    maxWidth: "initial",
    paddingTop: 18,
    width: `calc(100% + 2 * ${sidePadding}px)`
  }
});

const VolusionSide = styled("div")({
  boxSizing: "border-box",
  flexBasis: "38%",
  flexShrink: 0,
  padding: "18px 0px 25px 27px",
  [compactView]: {
    flexBasis: "initial",
    padding: "18px 0px 25px 20px",
    width: "50%"
  }
});

type Props = {
  variants: ProductInEdit["variants"];
};

export default class Variants extends React.Component<Props> {
  componentDidMount() {
    const source = Array.from(
      document.querySelectorAll("[data-volusion-products]")
    );
    const targets = Array.from(
      document.querySelectorAll("[data-amazon-target]")
    );
    const drake = dragula(source.concat(targets), {
      accepts: function(_, target) {
        if (!target) return false;
        if (!("amazonTarget" in (target as any).dataset)) return true;
        return target.children.length < 1;
      },
      mirrorContainer: this.dragMirrorEl as any
    });
    drake.on("drop", function(el: HTMLElement, target: HTMLElement) {
      getPriceField(el).required = "amazonTarget" in target.dataset;
      getQuantityField(el).required = "amazonTarget" in target.dataset;
    });
  }

  shouldComponentUpdate(nextProps: Props) {
    if (deepEqual(nextProps, this.props)) return false;
    throw new Error(
      "Variants component can not be updated, since we keep the state by mutating the DOM. If you want to update me, either reimplement how we keep state or re-render with `key=`."
    );
  }

  // By default dragula spills out into body, losing inherited styles
  // on the card being dragged
  private dragMirrorEl: HTMLElement | null = null;
  private dragMirrorRef = (el: HTMLElement | null) => {
    this.dragMirrorEl = el;
  };

  render() {
    const { variants } = this.props;
    if (!variants) return null;
    return (
      <Section>
        <div ref={this.dragMirrorRef} />
        <SectionTitle>Product Variants</SectionTitle>
        <p>
          Add the Vousion product variants you wish to sell on Amazon. Drag and
          drop the Volusion variant with its match on Amazon. Then just confirm
          your variant price and quantity.
        </p>
        <VariantsWrapper>
          <VolusionSide>
            <BrandBadgeVolusion>
              <BrandBadge compactMediaQuery="always" brand="volusion" />
            </BrandBadgeVolusion>
            <VariantsList data-volusion-products>
              {variants.volusion.rows.map(variant => (
                <ProductVariant
                  key={variant.id}
                  id={variant.id}
                  name={variantName(variant)}
                  price={fromDollarsAndCents(variant.price)}
                  quantity={variant.quantity}
                  sku={variant.sku}
                />
              ))}
            </VariantsList>
          </VolusionSide>
          <Divider>
            <MergeIcon>
              <img src={mergeIcon} width={20} />
            </MergeIcon>
          </Divider>
          <AmazonSide>
            <BrandBadgeAmazon>
              <BrandBadge compactMediaQuery="always" brand="amazon" />
            </BrandBadgeAmazon>
            <VariantsList>
              {variants.amazon.rows.map(variant => (
                <AmazonVariantRow key={variant.asin}>
                  <ProductVariantPlaceholder
                    data-amazon-target={variant.asin}
                  />
                  <AmazonVariant
                    asin={variant.asin}
                    competingOffers={
                      variant.priceInCents === "no-offers"
                        ? undefined
                        : [{ price: fromCents(variant.priceInCents) }]
                    }
                    image={
                      variant.images.official && variant.images.official.url
                    }
                    name={variantName(variant)}
                  />
                </AmazonVariantRow>
              ))}
            </VariantsList>
          </AmazonSide>
        </VariantsWrapper>
      </Section>
    );
  }
}
