import NanoEvents from "nanoevents";
import {
  getInitialData,
  isOurDoc,
  isOurKey,
  retrieve,
  Values
} from "~root-lib/DB";

import { PouchDBType } from "./pouchdb";

export default function observeChangesInPouch(
  pouch: PouchDBType,
  storage: Values,
  events: NanoEvents,
  onError: (err: Error) => any
) {
  pouch
    .changes({ live: true, since: "now", include_docs: true })
    .on("change", function(change) {
      if (change.deleted) {
        if (!isOurKey(change.id)) return;
        // Need a clean clone because values in storage are mutable
        storage[change.id] = getInitialData()[change.id];
        events.emit("change", change.id);
        return;
      }

      if (change.doc) {
        if (!isOurDoc(change.doc)) return;
        storage[change.doc._id] = retrieve(change.doc);
        events.emit("change", change.doc._id);
        events.emit("doc-propagated-to-sync-storage", change.doc);
        return;
      }
    })
    .on("error", onError);
}
