import ReactModal from "react-modal";

import { React, styles } from "~lib";
import { aestheticStyles } from "~lib/AppStyles";

const overlayStyles = styles({
  alignItems: "center",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  bottom: 0,
  display: "flex",
  justifyContent: "center",
  left: 0,
  position: "fixed",
  right: 0,
  top: 0,
  zIndex: 100,
  overflowY: "auto"
});

const windowStyles = styles({
  backgroundColor: "white",
  borderRadius: 2,
  boxShadow: "0px 12px 20px rgba(0, 0, 0, 0.22)"
});

const bodyStyles = styles({
  overflow: "hidden"
});

export function initModal(appRootElement: HTMLElement): void {
  ReactModal.setAppElement(appRootElement);
}

type Props = {
  border?: boolean;
  children: React.ReactNode;
  isOpen: boolean;
  maxWidth: number | string;
  onRequestClose: () => void;
};

export default function Modal(props: Props) {
  const { border, children, isOpen, maxWidth, onRequestClose } = props;
  return (
    <ReactModal
      className={styles(aestheticStyles, windowStyles, {
        border: border ? "1px solid #ccc" : undefined,
        maxWidth: maxWidth
      })}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName={overlayStyles}
      bodyOpenClassName={bodyStyles}
    >
      {children}
    </ReactModal>
  );
}
