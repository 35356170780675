import { stringify } from "querystringify";

import { isDevelopment, isProduction, isStaging } from "~lib/Environment";

type Query = { [name: string]: string };

export default function backendUrl(path: string, query: Query = {}) {
  validateDevelopmentServer();

  if (path[0] !== "/") throw new Error("path should begin with a slash");

  return environmentUrl() + path + "?" + stringify(query);
}

let validated = false;
async function validateDevelopmentServer() {
  if (validated || !isDevelopment) return;
  validated = true;
  try {
    await fetch("http://localhost:8081/");
  } catch (e) {
    console.error(
      "Development backend-service is not started. Start with cd backend-service; yarn install; yarn start. Alternatively, in the code switch to using a production service."
    );
  }
}

function environmentUrl(): string {
  return "https://api.amazon.volusion.com" || "https://api.amazon.volusion.com"
}
