import NanoEvents from "nanoevents";

import { update as updateDb } from "~root-lib/DB";

import { PouchDBType } from "../pouchdb";
import { ValueKey, Values } from "../types";

import ensureRevPropagated from "./ensureRevPropagated";

export type Update = <Key extends ValueKey>(
  key: Key,
  transform: (previousValue: Values[Key]) => Values[Key]
) => Promise<void>;

export default function createUpdater(
  pouch: PouchDBType,
  events: NanoEvents
): Update {
  return async function update(key, transform) {
    events.emit("update-saving-into-pouch");
    return ensureRevPropagated(events, key, async () => {
      const upsertStatus = await updateDb(pouch, key, prevValue =>
        transform(prevValue)
      );
      events.emit("update-saved-into-pouch");
      if (!upsertStatus.updated) return "finish-immediately";
      events.emit("rev-created-by-update", upsertStatus.rev);
      return { revToWaitFor: upsertStatus.rev };
    });
  };
}
