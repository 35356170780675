import { React, styled } from "~lib";

const Wrapper = styled("div")({
  boxSizing: "border-box",
  flexShrink: 0,
  fontWeight: 400,
  lineHeight: "17px",
  marginBottom: 10,
  maxHeight: 35,
  overflowY: "hidden"
});

type Props = {
  name: string;
};

export default function ProductName(props: Props) {
  const { name } = props;

  return <Wrapper>{name}</Wrapper>;
}
