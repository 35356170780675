import ReactImageFallback from "react-image-fallback";
import { React } from "~lib";

export type Props = {
  fallbackImage?: string;
  image?: string;
};

export default class Images extends React.Component<Props> {
  render() {
    const { fallbackImage, image } = this.props;
    if (!image) return null;
    return (
      <div>
        <ReactImageFallback
          alt=""
          fallbackImage={fallbackImage || <div />}
          src={image}
        />
      </div>
    );
  }
}
