import { React, styled } from "~lib";
import BrandedSearch, { searchHeight } from "~lib/BrandedSearch";
import { Product } from "~lib/DB";
import Guide from "~lib/Guide";
import PillBadge from "~lib/PillBadge";

const headerHeight = 32;
const headerMargin = 10;
export const overlap = headerHeight + headerMargin + 0.5 * searchHeight;
const compactHeaderHeight = 56;
const compactHeaderMargin = 20;
export const compactOverlap =
  compactHeaderHeight + compactHeaderMargin + 0.5 * searchHeight;

const compact = "@media not screen, screen and (max-width: 610px)";

const HeaderText = styled("span")({
  marginRight: 10
});
const SearchHeader = styled("div")({
  alignItems: "center",
  boxSizing: "border-box",
  color: "white",
  display: "flex",
  backgroundColor: "transparent",
  fontSize: 11,
  height: headerHeight,
  letterSpacing: "0.08em",
  lineHeight: "14px",
  marginBottom: headerMargin,
  textTransform: "uppercase",
  [compact]: {
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "space-between",
    height: compactHeaderHeight,
    marginBottom: compactHeaderMargin
  }
});

const Tip = styled("div")({
  color: "#737374",
  fontSize: 10,
  marginTop: 10,
  textTransform: "uppercase"
});

const VolusionProductCircle = styled("div")({
  alignItems: "center",
  backgroundColor: "white",
  border: "1px solid #E1E1E1",
  borderRadius: "50%",
  display: "flex",
  flexBasis: 20,
  flexShrink: 0,
  height: 20,
  justifyContent: "center",
  marginRight: 8,
  overflow: "hidden",
  width: 20
});

const VolutionProductImage = styled("img")({
  maxHeight: 13,
  maxWidth: 13
});

const VolusionTitle = styled("span")({
  marginRight: 3,
  maxWidth: 290,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  [compact]: {
    maxWidth: "70vw"
  }
});

type Props = {
  onChangeSearchField: (newValue: string) => void;
  value: string;
  volusionProduct: Product;
};

export default class DoubleSearch extends React.Component<Props> {
  render() {
    const { onChangeSearchField, value, volusionProduct } = this.props;
    return (
      <div style={{ marginBottom: 20 }}>
        <SearchHeader>
          <HeaderText>Search Amazon for your product:</HeaderText>
          <PillBadge color="#FF9900" size={{ height: 18, padding: 7 }}>
            <VolusionProductCircle>
              {volusionProduct.image && (
                <VolutionProductImage
                  src={volusionProduct.image}
                  alt={volusionProduct.title + " image"}
                />
              )}
            </VolusionProductCircle>
            <VolusionTitle>{volusionProduct.title}</VolusionTitle>
          </PillBadge>
        </SearchHeader>
        <Guide
          positioning="absolute"
          nudgeY={searchHeight / 2}
          title="To Get Started"
        >
          To get started, search for your product below just like you’d search
          for any Amazon product. You can also search on Amazon.com then paste
          the product URL in the search bar below.
        </Guide>
        <BrandedSearch
          brand="amazon"
          noMargin
          onChange={onChangeSearchField}
          placeholder="Search Amazon for your Volusion product"
          value={value}
        />
        <Tip>Search by keyword(s), amazon.com URL, or ASIN</Tip>
      </div>
    );
  }
}
