import { React } from "~lib";

import PaginationCard from "./PaginationCard";

const pageSizesOptions = {
  divisibleBy3: [9, 24, 45],
  regular: [10, 25, 50]
};

const countPages = (itemCount: number, pageSize: number) =>
  Math.max(1, Math.ceil(itemCount / pageSize));

type Props<T> = {
  items: T[];
  pageSizes?: keyof typeof pageSizesOptions;
  renderItem: (item: T) => React.ReactNode;
};

type State = {
  observedItemsLength: number;
  page: number;
  pageSize: number;
};

export default class Pagination<T> extends React.Component<Props<T>, State> {
  constructor(props: Props<T>) {
    super(props);
    this.state = {
      observedItemsLength: props.items.length,
      page: 1,
      pageSize: this.pageSizes()[0]
    };
  }

  static getDerivedStateFromProps(props: Props<never>, state: State) {
    // When the user filters out the items, we may be on an invalid page
    if (props.items.length !== state.observedItemsLength) {
      return {
        observedItemsLength: props.items.length,
        page: props.items.length < state.observedItemsLength ? 1 : state.page
      };
    }
    return null;
  }

  private pageSizes = () => {
    return pageSizesOptions[this.props.pageSizes || "regular"];
  };

  render() {
    const { items, renderItem } = this.props;
    const { page, pageSize } = this.state;
    const pageCount = countPages(items.length, pageSize);

    if (page < 1) throw new Error(`Invalid page ${page}`);
    if (pageSize < 1) throw new Error(`Invalid pageSize ${pageSize}`);
    if (pageCount < 1) throw new Error(`Invalid pageCount ${pageCount}`);
    if (page > pageCount)
      throw new Error(
        "Pagination internal error: I am in the state where my current page is beyond the item count"
      );

    const begin = (page - 1) * pageSize;
    const end = Math.min(items.length, begin + pageSize);
    if (begin > end) throw new Error("Pagination internal error: begin > end");
    const fitsOnSmallestPage =
      pageSize === this.pageSizes()[0] && items.length <= pageSize;
    return (
      <React.Fragment>
        {items.slice(begin, end).map((item, i) => (
          <React.Fragment key={begin + i}>{renderItem(item)}</React.Fragment>
        ))}
        {!fitsOnSmallestPage && (
          <PaginationCard
            begin={begin}
            currentPage={page}
            end={end}
            onChange={({ page, pageSize }) => {
              this.setState({ page, pageSize });
            }}
            pageCount={pageCount}
            pageSize={pageSize}
            pageSizes={this.pageSizes().slice(
              0,
              this.pageSizes().findIndex(ps => ps > items.length) + 1
            )}
            totalCount={items.length}
          />
        )}
      </React.Fragment>
    );
  }
}
