import { React } from "~lib";

import AmazonConnectPopup from "~lib/AmazonConnectPopup";
import DB from "~lib/DB";

import Add from "./Add";
import Layout from "./Layout";
import Listings from "./Listings";

type Props = {
  add: string;
  amazonProducts: string;
  db: DB;
  myUrl: string;
};

export default class Home extends React.Component<Props> {
  render() {
    const { add, amazonProducts, db, myUrl } = this.props;
    const listings = db.amazonListings();
    const showListings = listings !== "unsynced";
    return (
      <Layout>
        <AmazonConnectPopup
          db={db}
          onSuccess={myUrl}
          onCancel={
            // Do not allow to dismiss it, such is the design right now
            () => {}
          }
        />
        {listings !== "unsynced" && (
          <Listings
            listings={listings}
            tab={amazonProducts === "other" ? "other" : "attention"}
          />
        )}
        <Add
          db={db}
          tab={add === "bulk" ? "bulk" : "single"}
          shownAtTheTop={!showListings}
        />
      </Layout>
    );
  }
}
