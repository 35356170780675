import { Doc, ourKeys, ValueKey, Values } from "./types";

export function isOurDoc(doc: { [k: string]: unknown }): doc is Doc<ValueKey> {
  return (
    typeof doc._id === "string" &&
    isOurKey(doc._id) &&
    "value" in doc &&
    Boolean(doc.value)
  );
}

export function isOurKey(id: string): id is ValueKey {
  return ourKeys.indexOf(id as ValueKey) > -1;
}

export function retrieve<Key extends ValueKey>(doc: Doc<Key>): Values[Key] {
  return doc.value;
}
